import { UPLOAD_FILE_REQUEST, UPLOAD_FILE_SUCCESS, UPLOAD_FILE_FAILURE } from '../actions/uploaderActions';

const initialState = {
  uploading: false,
  error: null,
  success: null,
};

const uploaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_FILE_REQUEST:
      return {
        ...state,
        uploading: true,
        error: null,
        success: null,
      };
    case UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        uploading: false,
        success: action.payload,
        error: null,
      };
    case UPLOAD_FILE_FAILURE:
      return {
        ...state,
        uploading: false,
        error: action.payload,
        success: null,
      };
    default:
      return state;
  }
};

export default uploaderReducer;
