
export const PROCESS_SHIFT_REQUEST = 'PROCESS_SHIFT_REQUEST';
export const PROCESS_SHIFT_SUCCESS = 'PROCESS_SHIFT_SUCCESS';
export const PROCESS_SHIFT_FAILURE = 'PROCESS_SHIFT_FAILURE';

export const processShiftRequest = (data) => ({
  type: PROCESS_SHIFT_REQUEST,
  payload: data,
});

export const processShiftSuccess = (data) => ({
  type: PROCESS_SHIFT_SUCCESS,
  payload: data,
});

export const processShiftFailure = (error) => ({
  type: PROCESS_SHIFT_FAILURE,
  payload: error,
});

