import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button, TextField, Typography, Alert, Checkbox, FormControlLabel, Grid, FormHelperText, Divider, Tab, Tabs } from '@mui/material';
import StaffDetailEntryAccordion from './StaffDetailEntryAccordion';
import ContactDetailsView from '../contactDetail/ContactDetailView';
import ContactDetailsDialog from '../contactDetail/ContactDetailsDialog';
import CustomTabPanel from '../../../common/CustomTabPanel';
import UserPreference from './UserPreference';

const UserProfileEdit = ({ initialData, onSave }) => {
  const dispatch = useDispatch();
  const uploadedFiles = useSelector((state) => state.document.uploadedFiles);
  const [data, setData] = useState({ ...initialData });
  const [errors, setErrors] = useState({});
  const [passwordStrength, setPasswordStrength] = useState('weak');
  const [changePassword, setChangePassword] = useState(false);
  const { loading, error, message } = useSelector((state) => state.user);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [userPref, setUserPref] = useState({});
  const [docs, setDocs] = useState([]);
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    setData({ ...initialData });
    setErrors({});
    if (initialData && initialData.user_pref) {
      setUserPref({ ...initialData.user_pref });
    }
    if (initialData && initialData.documents) {
      setDocs([...initialData.documents]);
    } else {
      setDocs([])
    }
  }, [initialData]);

  useEffect(() => {
    if (uploadedFiles && uploadedFiles.length>0) {
      setDocs([...docs, ...uploadedFiles]);
    }
  }, [uploadedFiles]);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const checkPasswordStrength = (password) => {
    let strength = 'weak';
    if (password.length >= 8 && /[A-Z]/.test(password) && /[a-z]/.test(password) && /[0-9]/.test(password) && /[^A-Za-z0-9]/.test(password)) {
      strength = 'strong';
    } else if (password.length >= 8) {
      strength = 'medium';
    }
    return strength;
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setData({ ...data, password: newPassword });
    setPasswordStrength(checkPasswordStrength(newPassword));
  };

  const validateForm = () => {
    let formErrors = {};
    if (!data.first_name.trim()) formErrors.first_name = 'First Name is required';
    if (!data.last_name.trim()) formErrors.last_name = 'Last Name is required';
    if (!data.initials.trim()) formErrors.initials = 'Initials are required';
    if (!data.email.trim()) {
      formErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      formErrors.email = 'Email is invalid';
    }
    if (!data.role) formErrors.role = 'Role is required';
    if (changePassword && !data.password.trim()) {
      formErrors.password = 'Password is required';
    }
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleFileUpload = (formData) => {
    if (data.id) {
      formData.append('id', data.id);
    } else {
      formData.append('email', data.email);
    }
    dispatch({ type: 'UPLOAD_DOC_REQUEST', payload: formData });
  };

  const handleSave = (e) => {
    e.preventDefault();
    if (validateForm()) {
      let finalData = { ...data, user_pref: userPref , documents:docs};
      onSave(finalData);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
    if (errors[name]) {
      setErrors({ ...errors, [name]: '' });
    }
  };

  const handleChangePasswordToggle = (e) => {
    setChangePassword(e.target.checked);
    if (!e.target.checked) {
      setData({ ...data, password: '' });
      setPasswordStrength('weak');
    }
  };

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleSubmitContactDetails = (contactData) => {
    setData({ ...data, contact_detail: contactData });
    setErrors({ ...errors, contact_detail: '' });
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" sx={{mb:2}}>Edit Profile</Typography>
      <form onSubmit={handleSave}>
        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
        {message && <Alert severity="success" sx={{ mb: 2 }}>{message}</Alert>}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="First Name"
              name="first_name"
              value={data.first_name}
              onChange={handleInputChange}
              error={!!errors.first_name}
              helperText={errors.first_name}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Last Name"
              name="last_name"
              value={data.last_name}
              onChange={handleInputChange}
              error={!!errors.last_name}
              helperText={errors.last_name}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Initials"
              name="initials"
              value={data.initials}
              onChange={handleInputChange}
              error={!!errors.initials}
              helperText={errors.initials}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Email"
              name="email"
              type="email"
              value={data.email}
              onChange={handleInputChange}
              error={!!errors.email}
              helperText={errors.email}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={<Checkbox checked={changePassword} onChange={handleChangePasswordToggle} />}
              label="Change password"
            />
          </Grid>
          {changePassword && (
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="New Password"
                name="password"
                type="password"
                value={data.password}
                onChange={handlePasswordChange}
                error={!!errors.password}
                helperText={errors.password}
                required
              />
              <Typography sx={{ mt: 1 }}>Password strength: {passwordStrength}</Typography>
            </Grid>
          )}
          <Grid item xs={6}>
            <TextField
              select
              label="Role"
              name="role"
              fullWidth
              value={data.role}
              onChange={handleInputChange}
              error={!!errors.role}
              helperText={errors.role}
              SelectProps={{
                native: true,
              }}
              required
            >
              <option value="Manager">Manager</option>
              <option value="Team Lead">Team Lead</option>
              <option value="Support Worker">Support Worker</option>
              <option value="Practitioner">Practitioner</option>
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Tabs value={tabValue} onChange={handleChange}>
              <Tab label="Address" />
              <Tab label="Documents" />
              <Tab label="Preference" />
            </Tabs>
            <CustomTabPanel value={tabValue} index={0}>
              <Box sx={{ mt: 1, fontWeight: 'bold' }}>
                Address and contacts:
                <Button sx={{ ml: 2 }} variant="contained" color="primary" onClick={handleOpenDialog}>
                  Add
                </Button>
              </Box>
              <Box sx={{ alignContent: 'center', mt: 2 }}>
                {data.contact_detail && 
                  <ContactDetailsView data={data.contact_detail} />
                }
              </Box>
              {errors.contact_detail && <FormHelperText error>{errors.contact_detail}</FormHelperText>}
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={1}>
              <StaffDetailEntryAccordion uploadedFiles={docs} onUpload={handleFileUpload} />
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={2}>
              <UserPreference formData={userPref} setFormData={setUserPref} />
            </CustomTabPanel>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 3 }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading}
              >
                Save Changes
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
      <ContactDetailsDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        onSubmit={handleSubmitContactDetails}
        initialData={data.contact_detail || {}}
      />
    </Box>
  );
};

export default UserProfileEdit;