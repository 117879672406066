import React from "react";
import {
    Box,
    Button,
    Typography,
    Alert,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper
  } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { integrationActions } from "../../../../redux/actions/integrationActions";

const SyncDeputyEmployees = () => {

    const dispatch = useDispatch();

    const {employees, error, message} = useSelector(state => state.integration.deputy);
    const handleSync = () => {
        dispatch(integrationActions.deputyEmployeeSyncRequest());
    };

    return (
        <Box sx={{ p:2, m:2, borderColor:'lightgrey', borderBlockStyle:'dashed' }} component="fieldset">
            <legend><strong>Deputy Employees</strong></legend>
          <Typography variant="body" component="div">
            Sync Employee data from Deputy (match made with email or first name and last name in eazify).
          </Typography>
          {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
          {message && <Alert severity="success" sx={{ mt: 2 }}>{message}</Alert>}
          <Button
            variant="contained"
            color="primary"
            onClick={handleSync}
            sx={{mt:2}}
          >
            Sync
          </Button>
          <Box sx={{my:2}}>
            { employees && employees.length>0 && 
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead sx={{backgroundColor:'#4386f4'}}>
                    <TableRow >
                      <TableCell sx={{color:'white',fontWeight:'bold'}} >First Name</TableCell>
                      <TableCell sx={{color:'white',fontWeight:'bold'}} >Last Time</TableCell>
                      <TableCell sx={{color:'white',fontWeight:'bold'}} >Email</TableCell>
                      <TableCell sx={{color:'white',fontWeight:'bold'}} >DeputyId</TableCell>
                      <TableCell sx={{color:'white',fontWeight:'bold'}} >EazifyId</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    { employees.length !== 0 ?
                        employees.map((row, i) => (
                      <TableRow
                        key={i}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell >{row.first_name}</TableCell>
                        <TableCell >{row.last_name}</TableCell>
                        <TableCell >{row.email}</TableCell>
                        <TableCell >{row.id}</TableCell>
                        <TableCell >{row.staff_id}</TableCell>
                      </TableRow>
                    ))
                    :
                    'Sync returned empty response from Deputy'
                  }
                  </TableBody>
                </Table>
              </TableContainer>
            }
            </Box>
        </Box>
      );
}

export default SyncDeputyEmployees;