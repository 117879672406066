import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
  Button, 
  List, 
  ListItem, 
  ListItemText, 
  ListItemSecondaryAction,
  Typography,
  Box,
  CircularProgress,
  Snackbar,
  Divider
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

const Input = styled('input')({
  display: 'none',
});

const FileUploadDownload = ({uploadedFiles, fileCategory, onUpload, parentId, disabled}) => {
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState(null);
  const { loading, error } = useSelector(state => state.document);
  const handleFileSelect = (event) => {
    console.log("inside handleFileSelect", event.target.files[0]);
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = () => {
    if (selectedFile) {
      const formData = new FormData();
      selectedFile['filecategory'] = fileCategory;
      console.log("selectedFile",selectedFile);
      formData.append('file', selectedFile);
      formData.append('filecategory', fileCategory);
      formData.append('module', 'staff');
      onUpload(formData)

    }
  };

  const handleDownload = (uniqueId) => {
    dispatch({ type: 'DOWNLOAD_DOC_REQUEST', payload: uniqueId });
  };

  return (
    <Box sx={{ margin: 'auto' }}>
      <Box sx={{ minWidth:'300px', display: 'flex', flexFlow:'row wrap', mb: 2 }}>
        <label htmlFor={parentId+"fileSelectButtonId"}>
          <Input
            accept="*/*"
            id={parentId+"fileSelectButtonId"}
            name={parentId+"fileSelectButtonName"}
            type="file"
            onChange={handleFileSelect}
            disabled={disabled}
          />
          <Button
            id={parentId+"fileSelectIconId"}
            name={parentId+"fileSelectIconName"}
            variant="contained"
            component="span"
            disabled={disabled}
            sx={{mr:2, mt:1}}
            startIcon={<CloudUploadIcon />}
          >
            Select File
          </Button>
        </label>
        {selectedFile && (
          <Typography variant="body2" sx={{ mr: 2, mt:1 }}>
            {selectedFile.name}
          </Typography>
        )}
        <Button
          id={parentId+"fileUploadButtonId"}
          name={parentId+"fileUploadButtonName"}
          variant="contained"
          color="primary"
          sx={{mt:1}}
          onClick={handleUpload}
          disabled={!selectedFile || loading}

        >
          {loading ? <CircularProgress size={24} /> : 'Upload'}
        </Button>
      </Box>
      <hr/>
      <Typography variant="body" gutterBottom>
        List of files uploaded
      </Typography>
      <List sx={{minWidth:'250px'}} disableGutters>
        {uploadedFiles && uploadedFiles.length > 0 && uploadedFiles.map((file) => (
          <>
          <ListItem key={file.unique_id} >
            <ListItemText
              primary={file.filename}
              secondary={`Size: ${file.size} bytes`}
              
            />
            <ListItemSecondaryAction >
              <Button
                id={parentId+"fileDownloadButtonId"}
                name={parentId+"fileDownloadButtonName"}
                variant="contained"
                color="primary"
                onClick={() => handleDownload(file.unique_id)}
                startIcon={<CloudDownloadIcon />}
              >
                Download
              </Button>
            </ListItemSecondaryAction>
          </ListItem>
          <Divider variant="inset" component="li" />
          </>
        ))}
      </List>
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => dispatch({ type: 'CLEAR_ERROR' })}
        message={error}
      />
    </Box>
  );
};

export default FileUploadDownload;