import React, { useState } from 'react';
import {
  TextField,
  Grid,
  Typography,
  Box,
  Button,
  Divider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
} from '@mui/material';

const ContactDetailsForm = ({ onSubmit, initialData = {}, onClose }) => {
  const [formData, setFormData] = useState({
    // Primary Contact
    phone: initialData.phone || '',
    email: initialData.email || '',
    address: initialData.address || '',
    city: initialData.city || '',
    state: initialData.state || '',
    zipcode: initialData.zipcode || '',
    // Emergency Contact
    emergency_name: initialData.emergency_name || '',
    emergency_relationship: initialData.emergency_relationship || '',
    emergency_phone: initialData.emergency_phone || '',
    emergency_email: initialData.emergency_email || '',
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
    if (errors[name]) {
      setErrors(prevErrors => ({
        ...prevErrors,
        [name]: ''
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    // Primary Contact Validation
    if (!formData.phone || formData.phone.length <= 9 || formData.phone.length > 11) {
      newErrors.phone = 'Please enter a valid phone number';
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email || !emailRegex.test(formData.email)) {
      newErrors.email = 'Please enter a valid email address';
    }
    if (!formData.address.trim()) {
      newErrors.address = 'Address is required';
    }
    if (!formData.city.trim()) {
      newErrors.city = 'City is required';
    }
    if (!formData.state.trim()) {
      newErrors.state = 'State is required';
    }
    if (!formData.zipcode || formData.zipcode.length != 4 ) {
      newErrors.zipcode = 'Please enter a valid post code';
    }

    // Emergency Contact Validation
    if (!formData.emergency_name.trim()) {
      newErrors.emergency_name = 'Emergency contact name is required';
    }
    if (!formData.emergency_relationship.trim()) {
      newErrors.emergency_relationship = 'Relationship is required';
    }
    if (!formData.emergency_phone || formData.emergency_phone.length <= 9 || formData.emergency_phone.length>11) {
      newErrors.emergency_phone = 'Please enter a valid emergency phone number';
    }
    if (formData.emergency_email && !emailRegex.test(formData.emergency_email)) {
      newErrors.emergency_email = 'Please enter a valid email address';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      console.log("validate form returned true, formData=>", formData);
      onSubmit(formData);
    }
  };

  const states = [
    'Australian Capital Territory',
    'New South Wales',
    'Northern Territory',
    'Queensland',
    'South Australia',
    'Tasmania',
    'Victoria',
    'Western Australia',
  ];

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 3 }}>
      <Box sx={{textAlign:"center", mb:2}} >
        <Typography variant="body"  >
          Primary Contact Details
        </Typography>
      </Box>
      <Grid container spacing={2}>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Street Address"
            name="address"
            value={formData.address}
            onChange={handleChange}
            error={!!errors.address}
            helperText={errors.address}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="City"
            name="city"
            value={formData.city}
            onChange={handleChange}
            error={!!errors.city}
            helperText={errors.city}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControl fullWidth error={!!errors.state}>
            <InputLabel>State</InputLabel>
            <Select
              label="State"
              name="state"
              value={formData.state}
              onChange={handleChange}
            >
              {states.map((state) => (
                <MenuItem key={state} value={state}>
                  {state}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{errors.state}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label="Post Code"
            name="zipcode"
            type="number"
            value={formData.zipcode}
            onChange={handleChange}
            error={!!errors.zipcode}
            helperText={errors.zipcode}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Phone Number"
            name="phone"
            type="number"
            value={formData.phone}
            onChange={handleChange}
            error={!!errors.phone}
            helperText={errors.phone}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Email Address"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            error={!!errors.email}
            helperText={errors.email}
          />
        </Grid>
      </Grid>

      <Divider sx={{ my: 2 }} />
      <Box sx={{textAlign:"center", mb:2}} >
        <Typography variant="body" >
          Emergency Contact Details
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Emergency Contact Name"
            name="emergency_name"
            value={formData.emergency_name}
            onChange={handleChange}
            error={!!errors.emergency_name}
            helperText={errors.emergency_name}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Relationship"
            name="emergency_relationship"
            value={formData.emergency_relationship}
            onChange={handleChange}
            error={!!errors.emergency_relationship}
            helperText={errors.emergency_relationship}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Emergency Phone Number"
            name="emergency_phone"
            type="number"
            value={formData.emergency_phone}
            onChange={handleChange}
            error={!!errors.emergency_phone}
            helperText={errors.emergency_phone}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Emergency Email Address"
            name="emergency_email"
            type="email"
            value={formData.emergency_email}
            onChange={handleChange}
            error={!!errors.emergency_email}
            helperText={errors.emergency_email}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          >
          Save Contact Details
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button fullWidth
          variant="contained"
          color="primary"
          onClick={onClose}>Cancel</Button>
        </Grid>
      </Grid>


    </Box>
  );
};

export default ContactDetailsForm;