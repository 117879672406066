// reducers/userReducer.js
import { ADDUSER_RESET, ADDUSER_REQUEST, ADDUSER_SUCCESS, ADDUSER_FAILURE, 
  GET_USERS_FAILURE, GET_USERS_SUCCESS, DELETE_USER_FAILURE, DELETE_USER_SUCCESS, GET_USERS_REQUEST } from '../actions/userActions';
import { replaceObjectInArrayById } from '../../utils/DataUtils';

const initialState = {
  loading: false,
  message: null,
  error: null,
  data: [],
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADDUSER_RESET:
      return initialState;
    case ADDUSER_REQUEST:
    case GET_USERS_REQUEST:
    case 'MODIFY_USER_REQUEST':
      return { ...state, loading: true, error: null, message: null };
    case ADDUSER_SUCCESS:
      return { ...state, loading: false, error: null, message: "User added successfully", data: [...state.data, action.payload] };
    case 'MODIFY_USER_SUCCESS':
      return {
        ...state,
        loading: false,
        error: null,
        message: "User modified successfully" ,
        data:[...replaceObjectInArrayById([...state.data], action.payload)]
      };
    case 'DELETE_USER_SUCCESS':
      return {
        ...state,
        loading: false,
        data: state.data.filter(p => p.id !== action.id),
        message: action.payload,
        error:null
      };
    case ADDUSER_FAILURE:
      return { ...state, loading: false, error: action.payload, message: null };
    case GET_USERS_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null, message:null };
    case GET_USERS_FAILURE:
      return { ...state, loading: false, error: action.payload, message: null };
    case DELETE_USER_FAILURE:
      return { ...state, loading: false, error: action.payload, message: null };
    default:
      return state;
  }
};


export default userReducer;
