import { call, put, takeLatest, select } from 'redux-saga/effects';
import { fetchWithAuth } from '../../utils/fetchApi';
import { 
  FETCH_NOTIFICATIONS_REQUEST, 
  FETCH_NOTIFICATIONS_SUCCESS, 
  FETCH_NOTIFICATIONS_FAILURE,
  MARK_AS_READ_REQUEST,
  MARK_AS_READ_SUCCESS,
  MARK_AS_READ_FAILURE,
  SEND_NOTIFICATION_REQUEST,
  sendNotificationSuccess,
  sendNotificationFailure
} from '../actions/notificationActions';

const fetchNotificationsApi = (id) => fetchWithAuth(`/api/notifications/${id}`).then(res => res.json());

function* fetchNotificationsSaga() {
  try {
    const currentUser = yield select((state => state.auth.user));
    const notifications = yield call(fetchNotificationsApi, currentUser.id);
    yield put({ type: FETCH_NOTIFICATIONS_SUCCESS, payload: notifications });
  } catch (error) {
    yield put({ type: FETCH_NOTIFICATIONS_FAILURE, error: error.message });
  }
}

function* markAsReadSaga(action) {
  try {
    const currentUser = yield select((state => state.auth.user));
    const response = yield call(fetchWithAuth, `/api/notifications/${action.payload}/read`, { 
      method: 'POST', 
      headers: { 'Content-Type': 'application/json' }, 
      body: JSON.stringify({ user_id: currentUser.id})
    });
    yield put({ type: MARK_AS_READ_SUCCESS, payload: action.payload });
  } catch (error) {
    yield put({ type: MARK_AS_READ_FAILURE, error: error.message });
  }
}

function*  sendNotification(action){
  try {
    const currentUser = yield select((state => state.auth.user));
    const response = yield call(fetchWithAuth,'/api/notifications/add', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        message: action.payload.message,
        sender_id: currentUser.id,
        recipient_ids: action.payload.recipientIds,
        is_broadcast: action.payload.isBroadcast
      })
    });
    if (!response.ok) {
      throw new Error('Error creating notification');
    }
    const data = yield response.json();
    yield put(sendNotificationSuccess(data.msg));
  } catch (error) {
    console.error('Error sending notification:', error);
    yield put(sendNotificationFailure(error.message));
  }
};

// Watcher Saga
export default function* notificationSaga() {
  yield takeLatest(FETCH_NOTIFICATIONS_REQUEST, fetchNotificationsSaga);
  yield takeLatest(MARK_AS_READ_REQUEST, markAsReadSaga);
  yield takeLatest(SEND_NOTIFICATION_REQUEST, sendNotification);
}