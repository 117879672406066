export const locationActions = {
    addLocationRequest: (data) => ({ type: 'ADD_LOCATION_REQUEST', payload: data }),
    addLocationSuccess: (data) => ({ type: 'ADD_LOCATION_SUCCESS', payload: data }),
    addLocationFailure: (error) => ({ type: 'ADD_LOCATION_FAILURE', payload: error }),
    getLocationsRequest: (data) => ({ type: 'GET_LOCATIONS_REQUEST', payload: data }),
    getLocationsSuccess: (data) => ({ type: 'GET_LOCATIONS_SUCCESS', payload: data }),
    getLocationsFailure: (error) => ({ type: 'GET_LOCATIONS_FAILURE', payload: error }),
    deleteLocationRequest: (data) => ({ type: 'DELETE_LOCATION_REQUEST', payload: data }),
    deleteLocationSuccess: (data, id) => ({ type: 'DELETE_LOCATION_SUCCESS', payload: data, id }),
    deleteLocationFailure: (error) => ({ type: 'DELETE_LOCATION_FAILURE', payload: error }),
    modifyLocationRequest: (data) => ({ type: 'MODIFY_LOCATION_REQUEST', payload: data }),
    modifyLocationSuccess: (data) => ({ type: 'MODIFY_LOCATION_SUCCESS', payload: data }),
    modifyLocationFailure: (error) => ({ type: 'MODIFY_LOCATION_FAILURE', payload: error }),
  };