import { replaceObjectInArrayById } from '../../utils/DataUtils';

const initialState = {
  rules:[],
  message:null,
  loading: false,
  error: null,
};
  
const ruleReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_RULE_REQUEST':
    case 'DELETE_RULE_REQUEST':
    case 'MODIFY_RULE_REQUEST':
    case 'GET_RULES_REQUEST':
      return { ...state, loading: true, error: null, message: null };
    case 'ADD_RULE_SUCCESS':
      return {
        ...state,
        loading: false,
        rules: [...state.rules, action.payload],
        message: 'Successfully added the rule'
      };
    case 'MODIFY_RULE_SUCCESS':
      return {
        ...state,
        loading: false,
        rules: [...replaceObjectInArrayById([...state.rules], action.payload)],
        message: 'Successfully modified the rule'
      };
    case 'DELETE_RULE_SUCCESS':
      return {
        ...state,
        loading: false,
        rules: [...removeDeletedObj([...state.rules], action.id)],
        message: action.payload
      };
    case 'GET_RULES_SUCCESS':
      return { ...state, loading: false,
        rules: [...action.payload]
       };
    case 'ADD_RULE_FAILURE':
    case 'GET_RULES_FAILURE':
    case 'MODIFY_RULE_FAILURE':
    case 'DELETE_RULE_FAILURE':
      return { ...state, loading: false, error: action.payload };
    case 'CLEAR_ERROR':
      return { ...state, error: null, message: null};
    default:
      return state;
  }
};

const removeDeletedObj = (rules, id) => {
  let index = rules.findIndex(r => r.id == id);
  if (index > -1 ){
    rules.splice(index, 1);
  }
  return rules;
}

export default ruleReducer;