export const ruleActions = {
    addRuleRequest: (data) => ({ type: 'ADD_RULE_REQUEST', payload: data }),
    addRuleSuccess: (data) => ({ type: 'ADD_RULE_SUCCESS', payload: data }),
    addRuleFailure: (error) => ({ type: 'ADD_RULE_FAILURE', payload: error }),
    getRulesRequest: (data) => ({ type: 'GET_RULES_REQUEST', payload: data }),
    getRulesSuccess: (data) => ({ type: 'GET_RULES_SUCCESS', payload: data }),
    getRulesFailure: (error) => ({ type: 'GET_RULES_FAILURE', payload: error }),
    deleteRuleRequest: (data, obj) => ({ type: 'DELETE_RULE_REQUEST', payload: data, rule: obj }),
    deleteRuleSuccess: (data, id) => ({ type: 'DELETE_RULE_SUCCESS', payload: data, id }),
    deleteRuleFailure: (error) => ({ type: 'DELETE_RULE_FAILURE', payload: error }),
    modifyRuleRequest: (data, id) => ({ type: 'MODIFY_RULE_REQUEST', payload: data, id }),
    modifyRuleSuccess: (data) => ({ type: 'MODIFY_RULE_SUCCESS', payload: data }),
    modifyRuleFailure: (error) => ({ type: 'MODIFY_RULE_FAILURE', payload: error }),
  };