import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Button,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
  OutlinedInput,
  FormHelperText,
  ListItemText,
  Checkbox,
  Alert, Paper, TableContainer, TableHead, Table, TableRow, TableCell, TableBody,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import enAU from 'date-fns/locale/en-AU';
import { useDispatch, useSelector } from "react-redux";
import { integrationActions } from "../../../../redux/actions/integrationActions";
import MenuProps from "../../../common/MenuProps";

const SearchDeputyTimesheets = ({ employeesList, onSearch, saveDeputyData }) => {
  const { deputyData, deputyMessage, deputyError } = useSelector((state) => state.timesheet);
  const { employees } = useSelector(state => state.integration.deputy)
  const [formData, setFormData] = useState({
    selectedEmployees: [],
    startTime: null,
    endTime: null,
    status: "",
  });
  const [errors, setErrors] = useState({});

  const handleReset = () => {
    setFormData({
      selectedEmployees: [],
      startTime: null,
      endTime: null,
      status: "",
    });
    setErrors({});
  };
  const dispatch = useDispatch();
  useEffect(()=>{
    if (!employees || employees.length == 0) {
      dispatch(integrationActions.deputyRefDataRequest())
    }
  },[]);
  const handleDataChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };
  const handleSelectChange = (event) => {
    const {
      target: { value },
    } = event;
    handleDataChange("selectedEmployees",
      typeof value === 'string' ? value.split(',') : value,
    );
  };
  const validateForm = (formattedData) => {
    let tempErrors = {};
    // tempErrors.selectedEmployee = formattedData.selectedEmployee ? "" : "Employee is required";
    tempErrors.startTime = formattedData.startTime ? "" : "Start Date is required";
    tempErrors.endTime = formattedData.endTime ? "" : "End Date is required";
    // tempErrors.status = formattedData.status ? "" : "Status is required";

    if (
      formattedData.startTime &&
      formattedData.endTime &&
      formattedData.endTime <= formattedData.startTime
    ) {
      tempErrors.endTime = "End Date must be after Start Date";
    }

    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === "");
  };

  const searchTimesheets = () => {
    let formattedData;
    if (formData.startTime) {
      formattedData = {
        ...formData,
        startTime: (formData.startTime.valueOf() / 1000) | 0,
        endTime: (formData.endTime?.valueOf() / 1000) | 0,
      };
    } else { 
      formattedData = {
        ...formData
      }
    }
    return formattedData;
  };

  const handleSearch = (e) => {
    e.preventDefault();
    let formattedData = searchTimesheets();
    if (validateForm(formattedData)) {
      onSearch(formattedData);
    } else {
      console.log(
        "validation failed formattedData=>",
        JSON.stringify(formattedData)
      );
    }
  };

  const getEmployeeNames = (selectedIds) => {
    return selectedIds.map(id => {
      const employee = employees.find(emp => emp.id === id);
      return employee ? `${employee.first_name} ${employee.last_name}` : '';
    }).filter(name => name !== '').join(', ');
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enAU}>
      <Box sx={{ p:2, m:2, borderColor:'lightgrey', borderBlockStyle:'dashed' }} component="fieldset">
        <legend><strong>Deputy Timesheets </strong></legend>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "100%" }}>
          <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2, width: "100%" }}>
            <FormControl variant="outlined" sx={{ flex: 1, minWidth: 200 }} >
              <InputLabel id="employee-label">Employees</InputLabel>
              <Select
                labelId="employee-label"
                id="employeeId"
                multiple
                value={formData.selectedEmployees}
                renderValue={(selected) => getEmployeeNames(selected)}
                onChange={handleSelectChange}
                input={<OutlinedInput label="Employees" />}
                MenuProps={MenuProps}
              >
                {employees &&
                  employees.map((emp) => (
                    <MenuItem key={emp.id} value={emp.id}>
                      <Checkbox checked={formData.selectedEmployees.indexOf(emp.id) > -1} />
                      <ListItemText primary={emp.first_name+' '+emp.last_name} />
                    </MenuItem>
                  ))}
              </Select>
              {/* {errors.selectedEmployee && <FormHelperText>{errors.selectedEmployee}</FormHelperText>} */}
            </FormControl>

            <FormControl variant="outlined" sx={{ flex: 1, minWidth: 200 }} >
              <InputLabel id="status-label">Status</InputLabel>
              <Select
                labelId="status-label"
                id="status-select"
                value={formData.status}
                onChange={(e) => handleDataChange("status", e.target.value)}
                input={<OutlinedInput label="Status" />}
              >
                <MenuItem value="Approved">Approved</MenuItem>
                <MenuItem value="Pending">Pending</MenuItem>
              </Select>
              {/* {errors.status && <FormHelperText>{errors.status}</FormHelperText>} */}
            </FormControl>
          </Box>

          <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }, gap: 2, width: "100%" }}>
            <FormControl sx={{ flex: 1, minWidth: 200 }} error={!!errors.startTime} required>
              <DatePicker
                label="Start Date *"
                required
                value={formData.startTime}
                onChange={(newValue) => handleDataChange("startTime", newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
              {errors.startTime && <FormHelperText>{errors.startTime}</FormHelperText>}
            </FormControl>
            
            <FormControl sx={{ flex: 1, minWidth: 200 }} error={!!errors.endTime} required>
              <DatePicker
                label="End Date *"
                required
                value={formData.endTime}
                onChange={(newValue) => handleDataChange("endTime", newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
              {errors.endTime && <FormHelperText>{errors.endTime}</FormHelperText>}
            </FormControl>
          </Box>

          <Box sx={{ display: "flex", gap: 2, justifyContent: "flex-start" }}>
            <Button variant="contained" color="primary" onClick={handleSearch}>
              Search
            </Button>
            <Button variant="contained" color="primary" onClick={handleReset}>
              Reset
            </Button>
          </Box>
        </Box>
        { deputyData && 
          <>
            {/* <Alert sx={{mt:2}} severity="success">{'Successfully fetched data from Deputy'}</Alert> */}
            <Box sx={{mt:1}}>
              {deputyMessage && <Alert severity="success">{deputyMessage}</Alert>}
              {deputyError && (
                <Alert severity="error">
                  {Array.isArray(deputyError) ? (
                    deputyError.map((message, index) => (
                      <div key={index}>{message}</div>
                    ))
                  ) : (
                    deputyError
                  )}
                </Alert>
              )}
            </Box>
            <Box sx={{my:2}}>
            { deputyData && 
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead sx={{backgroundColor:'#4386f4'}}>
                    <TableRow >
                      <TableCell sx={{color:'white',fontWeight:'bold'}} >Staff Name</TableCell>
                      <TableCell sx={{color:'white',fontWeight:'bold'}} >Start Time</TableCell>
                      <TableCell sx={{color:'white',fontWeight:'bold'}} >End Time</TableCell>
                      <TableCell sx={{color:'white',fontWeight:'bold'}} >Total Time</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    { deputyData.length !== 0 ?
                        deputyData.map((row, i) => (
                      <TableRow
                        key={i}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell >{row.employee_name}</TableCell>
                        <TableCell >{new Date(row.start_time*1000).toLocaleString()}</TableCell>
                        <TableCell >{new Date(row.end_time*1000).toLocaleString()}</TableCell>
                        <TableCell >{row.total_time}</TableCell>
                      </TableRow>
                    ))
                    :
                    'Search returned empty from Deputy'
                  }
                  </TableBody>
                </Table>
              </TableContainer>
            }
            </Box>
            <Button sx={{m:1}} variant="contained" color="primary" onClick={()=>saveDeputyData(deputyData)}>
              Import
            </Button>
            
          </>
        }
      </Box>
    </LocalizationProvider>
  );
};

export default SearchDeputyTimesheets;