import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchWithAuth } from  '../../utils/fetchApi';
import { participantActions } from '../actions/participantActions';

function* addParticipantSaga(action) {
  try {
    const response = yield call(fetchWithAuth, '/api/participants/add', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(action.payload),
    });
    const data = yield response.json();
    if (!response.ok) {
      throw new Error(data.error || 'Failed to add new Participant');
    }

    yield put(participantActions.addParticipantSuccess(data));
  } catch (error) {
    yield put(participantActions.addParticipantFailure(error.message));
  }
}

function* modifyParticipantSaga(action) {
  try {
    const response = yield call(fetchWithAuth, `/api/participants/${action.payload.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(action.payload),
    });
    const data = yield response.json();
    if (!response.ok) {
      throw new Error(data.error || 'Failed to modify Participant');
    }

    yield put(participantActions.modifyParticipantSuccess(data));
  } catch (error) {
    yield put(participantActions.modifyParticipantFailure(error.message));
  }
}

function* deleteParticipantSaga(action) {
  try {
    const response = yield call(fetchWithAuth, `/api/participants/${action.payload}`, {
      method: 'DELETE',
    });
    const data = yield response.json();
    if (!response.ok) {
      throw new Error(data.error || 'Failed to delete new Participant');
    }

    yield put(participantActions.deleteParticipantSuccess(data.msg, action.payload));
  } catch (error) {
    yield put(participantActions.deleteParticipantFailure(error.message));
  }
}

function* getParticipantsSaga(action) {
  let url = '/api/participants/list';
  if (action.payload) {
    const params = new URLSearchParams();
    const { first_name, last_name } = action.payload;
    if (first_name) {
      params.append('first_name', first_name);
    }
    if (last_name) {
      params.append('last_name', last_name);
    }
    if (params.toString()) {
      url += `?${params.toString()}`;
    }
  }
  try {
    const response = yield call(fetchWithAuth, url, {
      method: 'GET'
    });

    if (!response.ok) {
      throw new Error('Failed to get list of participants');
    }
    const data = yield response.json();
    yield put(participantActions.getParticipantsSuccess(data));
  } catch (error) {
    yield put(participantActions.getParticipantsFailure(error.message));
  }
}

export default function* participantSaga() {
  yield takeLatest('ADD_PARTICIPANT_REQUEST', addParticipantSaga);
  yield takeLatest('MODIFY_PARTICIPANT_REQUEST', modifyParticipantSaga);
  yield takeLatest('GET_PARTICIPANTS_REQUEST', getParticipantsSaga);
  yield takeLatest('DELETE_PARTICIPANT_REQUEST', deleteParticipantSaga);
}