// reportSagas.js
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  FETCH_REPORTS,
  FETCH_REPORT,
  EXPORT_REPORT,
  EMAIL_REPORT_REQUEST,
  fetchReportsSuccess,
  fetchReportSuccess,
  fetchReportFailure,
  emailReportSuccess,
  emailReportFailure,
} from '../actions/reportActions';
import { fetchWithAuth } from '../../utils/fetchApi';
import downloadFile from '../../utils/DocUtils';

function* fetchReportsSaga() {
  try {
    const response = yield call(fetchWithAuth, '/api/reports/list', {
      method: 'GET'
    });
    const data = yield response.json();
    console.log("fetchReportsSaga=>",data);
    yield put(fetchReportsSuccess(data));
  } catch (error) {
    console.error('Error fetching reports:', error);
  }
}

function* fetchReportSaga(action) {
  try {
    const response = yield call(fetchWithAuth, `/api/reports/${action.reportId}`,{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(action.filter)
    });
    const data = yield response.json();
    if (!response.ok) {
      yield put(fetchReportFailure(data.msg));
    }

    yield put(fetchReportSuccess(data));
  } catch (error) {
    console.error('Error fetching report:', error);
  }
}

function* exportReportSaga(action) {
  try {
    const response = yield call(fetchWithAuth, `/api/reports/${action.reportId}/export/${action.format}`,
        {
          method: 'POST',
          responseType: 'blob',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(action.filter)
        }
    );
    if (!response.ok) {
      throw new Error('Failed to generate download URL');
    }

    const data = yield response.json();
    const downloadUrl = data.url;
    let docResponse;
    if (downloadUrl.includes('s3.amazonaws.com')) {
      docResponse = yield call(fetch, downloadUrl, {
        method: 'GET'
      });
    } else {
      docResponse = yield call(fetchWithAuth, downloadUrl, {
        method: 'GET'
      });
    }

    if (!docResponse.ok) {
      throw new Error('Failed to fetch document');
    }
      // Get the blob from the response
    const blob = yield docResponse.blob();
    // Get the filename from the Content-Disposition header
    const contentDisposition = docResponse.headers.get('Content-Disposition');

    downloadFile(blob, contentDisposition, action.format);

    // Dispatch a success action if needed
    yield put({ type: 'EXPORT_REPORT_SUCCESS' });
  } catch (error) {
    console.error('Error exporting report:', error);

    // Log additional details about the response
    if (error.response) {
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
      
      // If the response is JSON, log it
      try {
        const errorBody = yield error.response.json();
        console.error('Error body:', errorBody);
      } catch (e) {
        console.error('Error response is not JSON');
      }
    }

    // Dispatch an error action
    yield put({ type: 'EXPORT_REPORT_FAILURE', error: error.message });
  }
}


function* emailReportSaga(action) {
  try {
    const response = yield call(fetchWithAuth, `/api/reports/1/mail/pdf`,{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({'userId': action.payload})
    });
    const data = yield response.json();
    if (!response.ok) {
      yield put(emailReportFailure(data.msg));
    }

    yield put(emailReportSuccess(data));
  } catch (error) {
    console.error('Error fetching report:', error);
  }
}


export default function* watchReportSagas() {
  yield takeLatest(FETCH_REPORTS, fetchReportsSaga);
  yield takeLatest(FETCH_REPORT, fetchReportSaga);
  yield takeLatest(EXPORT_REPORT, exportReportSaga);
  yield takeLatest(EMAIL_REPORT_REQUEST, emailReportSaga);
}