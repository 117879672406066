import store from '../redux/store';
import { refreshToken, clearToken } from '../redux/actions/authActions';

let refreshTimer = null;

export const startTokenRefreshTimer = () => {
  // Clear any existing timer
  if (refreshTimer) {
    clearTimeout(refreshTimer);
  }

  // Set timer to refresh token after 14 minutes (840000 ms)
  refreshTimer = setTimeout(async () => {
    try {
      await store.dispatch(refreshToken());
      // Restart the timer after successful refresh
      startTokenRefreshTimer();
      console.log("Started timer for 15 min at ", new Date());
    } catch (error) {
      console.error('Failed to refresh token:', error);
      store.dispatch(clearToken());
      window.location.href = '/login';
    }
  }, 840000); // 14 minutes
};

export const stopTokenRefreshTimer = () => {
  console.log("clearning timer at ", new Date());
    if (refreshTimer) {
      clearTimeout(refreshTimer);
    }
  };