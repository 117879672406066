export const FETCH_TEMPLATES = 'FETCH_TEMPLATES';
export const FETCH_TEMPLATES_SUCCESS = 'FETCH_TEMPLATES_SUCCESS';
export const CREATE_TEMPLATE = 'CREATE_TEMPLATE';
export const UPDATE_TEMPLATE = 'UPDATE_TEMPLATE';
export const DELETE_TEMPLATE = 'DELETE_TEMPLATE';
export const SET_CURRENT_TEMPLATE = 'SET_CURRENT_TEMPLATE';
export const TOGGLE_DIALOG = 'TOGGLE_DIALOG';

export const fetchTemplates = () => ({ type: FETCH_TEMPLATES });
export const fetchTemplatesSuccess = (templates) => ({ type: FETCH_TEMPLATES_SUCCESS, templates });
export const createTemplate = (template) => ({ type: CREATE_TEMPLATE, template });
export const updateTemplate = (template) => ({ type: UPDATE_TEMPLATE, template });
export const deleteTemplate = (id) => ({ type: DELETE_TEMPLATE, id });
export const setCurrentTemplate = (template) => ({ type: SET_CURRENT_TEMPLATE, template });
export const toggleDialog = (isOpen) => ({ type: TOGGLE_DIALOG, isOpen });
