// reducers/authReducer.js
import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, SET_TOKEN, 
  CLEAR_TOKEN, UPDATE_LAST_ACTIVITY,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE
} from '../actions/authActions';

const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: !!localStorage.getItem('token'),
  lastActivity: parseInt(localStorage.getItem('lastActivity') || '0'),
  loading: false,
  user: null,
  error: null,
  message: null,
  showErrorPage: false
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_PASSWORD_REQUEST:
      return { ...state, loading: true, error: null };
    case RESET_PASSWORD_SUCCESS:
      return { ...state, loading: false, message: action.message };
    case RESET_PASSWORD_FAILURE:
      return { ...state, loading: false, error: action.error };
    case LOGIN_REQUEST:
      return { ...state, loading: true, error: null };
    case LOGIN_SUCCESS:
      localStorage.setItem('token', action.payload.accessToken);
      localStorage.setItem('lastActivity', Date.now().toString());
      return { ...state,
        isAuthenticated: true, 
        loading: false, 
        token: action.payload.accessToken, 
        lastActivity : Date.now(),
        user: action.payload.user
      };
    case LOGIN_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case SET_TOKEN:
      localStorage.setItem('token', action.payload);
      localStorage.setItem('lastActivity', Date.now().toString());
      return { ...state,
        isAuthenticated: true, 
        loading: false, 
        token: action.payload, 
        lastActivity : Date.now()
      };
    case CLEAR_TOKEN:
      localStorage.removeItem('token');
      localStorage.removeItem('lastActivity');
      return { ...state,
        isAuthenticated: false, 
        token: null, 
        lastActivity : null
      };
    case UPDATE_LAST_ACTIVITY:      
      localStorage.setItem('lastActivity', Date.now().toString());
      return { ...state,
        lastActivity: Date.now()
      };
    case 'SHOW_ERROR_PAGE':
      return {
        ...state,
        error: action.payload,
        showErrorPage: true
      };
    default:
      return state;
  }
};

export default authReducer;
