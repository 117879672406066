export const participantActions = {
    addParticipantRequest: (data) => ({ type: 'ADD_PARTICIPANT_REQUEST', payload: data }),
    addParticipantSuccess: (data) => ({ type: 'ADD_PARTICIPANT_SUCCESS', payload: data }),
    addParticipantFailure: (error) => ({ type: 'ADD_PARTICIPANT_FAILURE', payload: error }),
    modifyParticipantRequest: (data) => ({ type: 'MODIFY_PARTICIPANT_REQUEST', payload: data }),
    modifyParticipantSuccess: (data) => ({ type: 'MODIFY_PARTICIPANT_SUCCESS', payload: data }),
    modifyParticipantFailure: (error) => ({ type: 'MODIFY_PARTICIPANT_FAILURE', payload: error }),
    getParticipantsRequest: (data) => ({ type: 'GET_PARTICIPANTS_REQUEST', payload: data }),
    getParticipantsSuccess: (data) => ({ type: 'GET_PARTICIPANTS_SUCCESS', payload: data }),
    getParticipantsFailure: (error) => ({ type: 'GET_PARTICIPANTS_FAILURE', payload: error }),
    deleteParticipantRequest: (data) => ({ type: 'DELETE_PARTICIPANT_REQUEST', payload: data }),
    deleteParticipantSuccess: (data, id) => ({ type: 'DELETE_PARTICIPANT_SUCCESS', payload: data, id }),
    deleteParticipantFailure: (error) => ({ type: 'DELETE_PARTICIPANT_FAILURE', payload: error }),
  };