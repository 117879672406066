import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import { Box } from '@mui/material';

const Layout = () => {
  return (
    <div>
      <Header />
      <Box
        sx={{
          flex: '1 0 auto',
          p: 2,
        }}
      >
        <Outlet />
      </Box>
      <Footer sx={{ flexShrink: 0 }}/>
    </div>
  );
};

export default Layout;
