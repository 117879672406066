// Action types
export const FETCH_NOTIFICATIONS_REQUEST = 'FETCH_NOTIFICATIONS_REQUEST';
export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS';
export const FETCH_NOTIFICATIONS_FAILURE = 'FETCH_NOTIFICATIONS_FAILURE';
export const MARK_AS_READ_REQUEST = 'MARK_AS_READ_REQUEST';
export const MARK_AS_READ_SUCCESS = 'MARK_AS_READ_SUCCESS';
export const MARK_AS_READ_FAILURE = 'MARK_AS_READ_FAILURE';
export const SNOOZE_NOTIFICATION = 'SNOOZE_NOTIFICATION';
export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST';
export const SEND_NOTIFICATION_REQUEST = 'SEND_NOTIFICATION_REQUEST';
export const SEND_NOTIFICATION_SUCCESS = 'SEND_NOTIFICATION_SUCCESS';
export const SEND_NOTIFICATION_FAILURE = 'SEND_NOTIFICATION_FAILURE';

// Action creators
export const fetchUsersRequest = () => ({ type: FETCH_USERS_REQUEST });
export const sendNotificationRequest = (message, recipientIds, isBroadcast) => ({
  type: SEND_NOTIFICATION_REQUEST,
  payload: { message, recipientIds, isBroadcast }
});

export const sendNotificationSuccess = (message) => ({
  type: SEND_NOTIFICATION_SUCCESS,
  payload: message
});

export const sendNotificationFailure = (error) => ({
  type: SEND_NOTIFICATION_FAILURE,
  error
});

export const fetchNotificationsRequest = () => ({ type: FETCH_NOTIFICATIONS_REQUEST });
export const markAsReadRequest = (notificationId) => ({ 
  type: MARK_AS_READ_REQUEST, 
  payload: notificationId 
});
export const snoozeNotification = (notificationId) => ({
  type: SNOOZE_NOTIFICATION,
  payload: notificationId
});