// reportActions.js
export const FETCH_REPORTS = 'FETCH_REPORTS';
export const FETCH_REPORTS_SUCCESS = 'FETCH_REPORTS_SUCCESS';
export const FETCH_REPORT = 'FETCH_REPORT';
export const FETCH_REPORT_SUCCESS = 'FETCH_REPORT_SUCCESS';
export const FETCH_REPORT_FAILURE = 'FETCH_REPORT_FAILURE';
export const EXPORT_REPORT = 'EXPORT_REPORT';

export const EMAIL_REPORT_REQUEST = 'EMAIL_REPORT_REQUEST';
export const EMAIL_REPORT_SUCCESS = 'EMAIL_REPORT_SUCCESS';
export const EMAIL_REPORT_FAILURE = 'EMAIL_REPORT_FAILURE';

export const fetchReports = () => ({ type: FETCH_REPORTS });
export const fetchReportsSuccess = (reports) => ({ type: FETCH_REPORTS_SUCCESS, reports });
export const fetchReport = (reportId, filter) => ({ type: FETCH_REPORT, reportId, filter });
export const fetchReportSuccess = (reportData) => ({ type: FETCH_REPORT_SUCCESS, reportData });
export const fetchReportFailure = (message) => ({ type: FETCH_REPORT_FAILURE, message });
export const exportReport = (reportId, filter, format) => ({ type: EXPORT_REPORT, reportId, filter, format });
export const emailReportRequest = ( userId ) => ({ type: EMAIL_REPORT_REQUEST, payload: userId });
export const emailReportSuccess = ( data ) => ({ type: EMAIL_REPORT_SUCCESS, payload: data });
export const emailReportFailure = ( error ) => ({ type: EMAIL_REPORT_FAILURE, payload: error });