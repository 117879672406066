import { replaceObjectInArrayById } from '../../utils/DataUtils';

const initialState = {
  activityTypes:[],
  message:null,
  loading: false,
  error: null,
};
  
const activityTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_ACTIVITY_TYPE_REQUEST':
    case 'GET_ACTIVITY_TYPES_REQUEST':
      return { ...state, loading: true, error: null, message: null };
    case 'ADD_ACTIVITY_TYPE_SUCCESS':
      return {
        ...state,
        loading: false,
        error:null,
        activityTypes: [...state.activityTypes, action.payload],
        message: 'Successfully added the activityType'
      };
    case 'MODIFY_ACTIVITY_TYPE_SUCCESS':
      return {
        ...state,
        loading: false,
        error:null,
        activityTypes: [...replaceObjectInArrayById([...state.activityTypes], action.payload)],
        message: 'Successfully modified the activityType'
      };
    case 'DELETE_ACTIVITY_TYPE_SUCCESS':
      return {
        ...state,
        loading: false,
        activityTypes: state.activityTypes.filter(a => a.id !== action.id),
        message: action.payload,
        error:null
      };
    case 'GET_ACTIVITY_TYPES_SUCCESS':
      return { ...state, loading: false, error:null,
        activityTypes: [...action.payload]
       };
    case 'ADD_ACTIVITY_TYPE_FAILURE':
    case 'GET_ACTIVITY_TYPES_FAILURE':
      return { ...state, loading: false, error: action.payload };
    case 'CLEAR_ERROR':
      return { ...state, error: null, message: null};
    default:
      return state;
  }
};


export default activityTypeReducer;