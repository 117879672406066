import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box } from '@mui/material';

const PayrollDataReport = ({ data, title }) => {
  const dateRange = Array.from({ length: 14 }, (_, i) => i + 1);

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <Box sx={{ p: 2, background: 'linear-gradient(to left, darkred, navy)', color:'white' }}>
        <Typography variant="h5" gutterBottom align="center">
          Payroll Data For Xero Timesheet and/or Pay Run
        </Typography>
        <hr></hr>
        <Typography variant="subtitle1" align="center">
          Fortnight Ending {title}
        </Typography>
      </Box>
      {data.map((employee) => (
        <Box key={employee.name} sx={{ mt: 4 }}>
          <Typography variant="h6" sx={{ mb: 2 , backgroundColor:'lightgrey'}}>{employee.name}</Typography>
          <TableContainer component={Paper}>
            <Table size="small" aria-label={`${employee.name}'s payroll data`}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ bgcolor: '#e6f3d6' }}>PayRateCode</TableCell>
                  <TableCell sx={{ bgcolor: '#e6f3d6' }}>Total</TableCell>
                  {dateRange.map((day) => (
                    <TableCell key={day} align="right" sx={{ bgcolor: '#e6f3d6' }}>{day}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {employee.categories.map((category) => (
                  <TableRow key={category.name}>
                    <TableCell component="th" scope="row">{category.name}</TableCell>
                    <TableCell align="right">{category.total.toFixed(2)}</TableCell>
                    {category.daily_hours.map((hours, index) => (
                      <TableCell key={index} align="right">
                        {hours > 0 ? hours.toFixed(2) : ''}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell component="th" scope="row">Daily Total</TableCell>
                  <TableCell align="right"> {employee.total_hours> 0 ? employee.total_hours.toFixed(2): ''} </TableCell>
                  {employee.daily_totals.map((total, index) => (
                    <TableCell key={index} align="right">
                      {total > 0 ? total.toFixed(2) : ''}
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ))}
    </Paper>
  );
};

export default PayrollDataReport;