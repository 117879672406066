import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Grid, Dialog, DialogContent, DialogTitle, Select, 
  MenuItem, FormControl, InputLabel, FormHelperText 
} from '@mui/material';


const AddActivityType = ({ handleClose, open, initialData }) => {

  const [data, setData] = useState({...initialData});
  const [errors, setErrors] = useState({});


  useEffect(() => {
    setData({...initialData});
    setErrors({});
  }, [initialData]);


  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ['activity_code', 'activity_name', 'activity_type', 'is_active', 'pay_item_code', 'activity_description' ];

    requiredFields.forEach(field => {
      if (!data[field] || data[field].toString().trim() === '') {
        newErrors[field] = 'This field is required';
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleSubmit = () => {
    if (validateForm()) {
      console.log('ActivityType submitted:', JSON.stringify(data));
      handleClose(data);
    } else {
      console.log('Form has errors. Please correct them.');
    }
  };

  return (
    <Box>
      <Dialog
        open={open}
        onClose={() => handleClose(null)} 
        fullWidth
        maxWidth="md"
      >
        <DialogTitle sx={{background:'linear-gradient(to left, darkred, navy)', color:'white'}}>
          {initialData.id ? 'Modify Activity Type' : 'Add Activity Type'}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ my: 'auto', p: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Activity Code"
                  name="activity_code"
                  value={data.activity_code}
                  onChange={handleChange}
                  error={!!errors.activity_code}
                  helperText={errors.activity_code}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Activity Name"
                  name="activity_name"
                  value={data.activity_name}
                  onChange={handleChange}
                  error={!!errors.activity_name}
                  helperText={errors.activity_name}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  multiline
                  label="Activity Description"
                  name="activity_description"
                  value={data.activity_description}
                  onChange={handleChange}
                  error={!!errors.activity_description}
                  helperText={errors.activity_description}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth  error={!!errors.activity_type} required>
                  <InputLabel id="activity-type-label">Billing Type</InputLabel>
                  <Select
                    labelId="activity-type-label"
                    name="activity_type"
                    value={data.activity_type}
                    onChange={handleChange}
                    label="Billing Type"
                  >
                    <MenuItem value="Billable">Billable</MenuItem>
                    <MenuItem value="Non Billable">Non Billable</MenuItem>
                  </Select>
                  {errors.activity_type && <FormHelperText>{errors.activity_type}</FormHelperText>}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Pay Item Code"
                  name="pay_item_code"
                  value={data.pay_item_code}
                  onChange={handleChange}
                  error={!!errors.pay_item_code}
                  helperText={errors.pay_item_code}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth  error={!!errors.is_active} required>
                  <InputLabel id="is-active-label">Status</InputLabel>
                  <Select
                    labelId="is-active-label"
                    name="is_active"
                    value={data.is_active}
                    onChange={handleChange}
                    label="Status"
                  >
                    <MenuItem value="1">Active</MenuItem>
                    <MenuItem value="0">Inactive</MenuItem>
                  </Select>
                  {errors.is_active && <FormHelperText>{errors.is_active}</FormHelperText>}
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="External System Id"
                  name="external_system_id"
                  
                  value={data.external_system_id}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth  >
                  <InputLabel id="is_shift_time-label">Based on shift time </InputLabel>
                  <Select
                    labelId="is_shift_time-label"
                    name="is_shift_time"
                    value={data.is_shift_time}
                    onChange={handleChange}
                    label="Based on shift time"
                  >
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {/* <Grid item xs={12} sm={6}>
                <FormControl fullWidth >
                  <InputLabel id="support_category-label">Support Category</InputLabel>
                  <Select
                    labelId="support_category-label"
                    name="support_category"
                    value={data.support_category}
                    onChange={handleChange}
                    label="Support Category"
                  >
                    <MenuItem value="">----Select----</MenuItem>
                    <MenuItem value="Daily Living">Daily Living</MenuItem>
                    <MenuItem value="Consumables">Consumables</MenuItem>
                    <MenuItem value="Assistive Technology">Assistive Technology</MenuItem>
                    <MenuItem value="Social and Community Participation">Social and Community Participation</MenuItem>
                    <MenuItem value="Early Intervention">Early Intervention</MenuItem>
                    <MenuItem value="Therapeutic Supports">Therapeutic Supports</MenuItem>
                    <MenuItem value="High Support Needs">High Support Needs</MenuItem>
                    <MenuItem value="Specialist Disability Accommodation (SDA)">Specialist Disability Accommodation (SDA)</MenuItem>
                    <MenuItem value="Home Modifications">Home Modifications</MenuItem>
                    <MenuItem value="Capital Supports">Capital Supports</MenuItem>
                    <MenuItem value="Coordination and Management">Coordination and Management</MenuItem>
                    <MenuItem value="Innovation and Technology">Innovation and Technology</MenuItem>
                    <MenuItem value="Capacity Building">Capacity Building</MenuItem>
                    <MenuItem value="Supported Independent Living (SIL)">Supported Independent Living (SIL)</MenuItem>
                  </Select>
                </FormControl>
              </Grid> */}
              {/* <Grid item xs={12} sm={6}>
              <FormControl variant="outlined" sx={{ mb:1, textAlign: "left" }} fullWidth>
                <InputLabel id="employee-label" >Assigned to Staffs</InputLabel>
                <Select
                fullWidth
                  labelId="employee-label"
                  id="multiple-checkbox"
                  multiple
                  value={data.selectedEmployees}
                  onChange={handleSelectChange}
                  input={<OutlinedInput label="Employees" />}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                >
                  {employeesList && employeesList.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox checked={data.selectedEmployees.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              </Grid> */}

              <Grid item xs={12} sm={6}>
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{ mt: 2 }}
                >
                  {initialData.id ? 'Update' : 'Save'}
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  sx={{ mt: 2 }}
                  fullWidth
                  onClick={() => handleClose(null)} 
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default AddActivityType;