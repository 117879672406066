import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchWithAuth } from  '../../utils/fetchApi';
import { activityTypeActions } from '../actions/activityTypeActions';

function* addActivityTypeSaga(action) {
  try {
    const response = yield call(fetchWithAuth, '/api/activity_types/add', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(action.payload),
    });

    const data = yield response.json();
    if (!response.ok) {
      throw new Error(data.error || 'Failed to add new ActivityType');
    }
    yield put(activityTypeActions.addActivityTypeSuccess(data));
  } catch (error) {
    yield put(activityTypeActions.addActivityTypeFailure(error.message));
  }
}

function* modifyActivityTypeSaga(action) {
  try {
    const response = yield call(fetchWithAuth, `/api/activity_types/${action.payload.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(action.payload),
    });

    const data = yield response.json();
    if (!response.ok) {
      throw new Error(data.error || 'Failed to modify  ActivityType');
    }

    yield put(activityTypeActions.modifyActivityTypeSuccess(action.payload));
  } catch (error) {
    yield put(activityTypeActions.modifyActivityTypeFailure(error.message));
  }
}

function* deleteActivityTypeSaga(action) {
  try {
    const response = yield call(fetchWithAuth, `/api/activity_types/${action.payload}`, {
      method: 'DELETE',
    });
    const data = yield response.json();
    if (!response.ok) {
      throw new Error(data.error || 'Failed to delete new ActivityType');
    }

    yield put(activityTypeActions.deleteActivityTypeSuccess(data.msg, action.payload));
  } catch (error) {
    yield put(activityTypeActions.deleteActivityTypeFailure(error.message));
  }
}

function* getActivityTypesSaga(action) {
  try {
    let url = '/api/activity_types/list';
    if (action.payload) {
      const params = new URLSearchParams();
      const { code, name } = action.payload;
      if (code) {
        params.append('code', code);
      }
      if (name) {
        params.append('name', name);
      }
      if (params.toString()) {
        url += `?${params.toString()}`;
      }
    }
    const response = yield call(fetchWithAuth, url, {
      method: 'GET'
    });

    if (!response.ok) {
      throw new Error('Failed to get list of activityTypes');
    }
    const data = yield response.json();
    yield put(activityTypeActions.getActivityTypesSuccess(data));
  } catch (error) {
    yield put(activityTypeActions.getActivityTypesFailure(error.message));
  }
}

export default function* activityTypeSaga() {
  yield takeLatest('ADD_ACTIVITY_TYPE_REQUEST', addActivityTypeSaga);
  yield takeLatest('MODIFY_ACTIVITY_TYPE_REQUEST', modifyActivityTypeSaga);
  yield takeLatest('GET_ACTIVITY_TYPES_REQUEST', getActivityTypesSaga);
  yield takeLatest('DELETE_ACTIVITY_TYPE_REQUEST', deleteActivityTypeSaga);
}