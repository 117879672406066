import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  TextField, 
  Button, 
  Grid, 
  Card, 
  CardContent, 
  useMediaQuery,
  InputAdornment,
  Snackbar,
  Alert,
  Link,
  AppBar,
  Toolbar
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loginRequest } from '../../redux/actions/authActions';



const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
  },
});

const FeatureCard = ({ title, description, imageSrc }) => (
  <Box sx={{ textAlign: 'center', position: 'relative' }}>
    <img src={imageSrc} alt={title} style={{ width: '100%', height: '350px', objectFit: 'cover' }} />
    <div className='text-overlay'>
      <Typography variant="h6" sx={{ mt: 2, fontWeight: 'bold' }}>{title}</Typography>
      <Typography variant="body2" sx={{ mt: 1 }}>{description}</Typography>
    </div>
  </Box>
);

const Login = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });


  const dispatch = useDispatch();
  const { loading, error, user } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [user, navigate]);

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError(validateEmail(e.target.value) ? '' : 'Invalid email format');
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setPasswordError(e.target.value.length >= 8 ? '' : 'Password must be at least 8 characters');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!emailError && !passwordError && email && password) {
      dispatch(loginRequest({ email, password }));
      // setTimeout(() => {
      //   setSnackbar({ open: true, message: 'Login successful!', severity: 'success' });
        
      // }, 1000);
    } else {
      setSnackbar({ open: true, message: 'Please correct the errors and try again.', severity: 'error' });
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ 
        minHeight: '100vh', 
        // backgroundColor: '#1976d2', 
        background: 'linear-gradient(to left, darkred, navy)',
        display: 'flex', 
        flexDirection: 'column', 
        padding: 0
      }}>

        <AppBar position="static" color="transparent" elevation={0}>
          <Toolbar sx={{ backgroundColor: 'black', justifyContent: 'space-between' }}>
            <img src="logo.png" alt="Eazify Logo" style={{ width: 40, height: 40, backgroundColor: 'white', borderRadius: '8px' }} />
            <Typography variant="h6" component="div" sx={{ color: 'white' }}>
              Eazify
            </Typography>
          </Toolbar>
        </AppBar>

        <Grid container spacing={3} sx={{ flexGrow: 1 }}>
          {!isMobile && (
            <Grid item xs={12} md={8}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <FeatureCard 
                    title="Efficiency and Accuracy" 
                    description="Eazify significantly reduces the time and effort required to manage shifts, timesheets, and payroll, allowing providers to focus more on client care. Automated processes ensure high accuracy, reducing the likelihood of human errors."
                    imageSrc="https://assets.api.uizard.io/api/cdn/stream/943cd94e-ab5f-4c8b-83a0-5637dca6bb98.jpg"
                  />
                </Grid>
                <Grid item xs={4}>
                  <FeatureCard 
                    title="User-friendly Interface" 
                    description="Designed with user experience in mind, Eazify's intuitive interface ensures that even those with minimal technical expertise can navigate and utilize the platform effectively."
                    imageSrc="https://assets.api.uizard.io/api/cdn/stream/d65bae59-4ace-450e-9df6-f84815407573.jpg"
                  />
                </Grid>
                <Grid item xs={4}>
                  <FeatureCard 
                    title="Cost-effective Solution" 
                    description="Eazify offers a cost-effective solution by streamlining administrative tasks, reducing overhead costs, and minimizing the need for extensive manual labor."
                    imageSrc="https://assets.api.uizard.io/api/cdn/stream/7a8af57f-5441-4155-818a-5dca8e4d5bf1.jpg"
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} md={4}>
            <Card sx={{ height: '500' }}>
              <CardContent>
                <Typography variant="h5" sx={{ mb: 3, textAlign: 'center' }}>Log in</Typography>
                <form onSubmit={handleSubmit}>
                  <TextField
                    fullWidth
                    placeholder="Email"
                    variant="outlined"
                    margin="normal"
                    value={email}
                    onChange={handleEmailChange}
                    error={!!emailError}
                    helperText={emailError}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EmailIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    fullWidth
                    placeholder="Password"
                    type="password"
                    variant="outlined"
                    margin="normal"
                    value={password}
                    onChange={handlePasswordChange}
                    error={!!passwordError}
                    helperText={passwordError}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  {error && <Typography sx={{  textAlign: 'center'}} color="error">{error}</Typography>}
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2 }}
                    type="submit"
                  >
                    Log in
                  </Button>
                  <Box sx={{ mt: 2, textAlign: 'right' }}>
                    <Typography variant="body2" color="primary" sx={{ cursor: 'pointer' }}>
                      <Link onClick={() => navigate('/forgot-password')} >Forgot password?</Link>
                    </Typography>
                  </Box>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleSnackbarClose}>
          <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>
    </ThemeProvider>
  );
};

export default Login;