// templateReducer.js
import {
    FETCH_TEMPLATES_SUCCESS,
    SET_CURRENT_TEMPLATE,
    TOGGLE_DIALOG
  } from '../actions/templateActions';
  
  const initialState = {
    templates: [],
    currentTemplate: { id: null, name: '', content: '' },
    isDialogOpen: false
  };
  
  const templateReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_TEMPLATES_SUCCESS:
        return { ...state, templates: action.templates };
      case SET_CURRENT_TEMPLATE:
        return { ...state, currentTemplate: action.template };
      case TOGGLE_DIALOG:
        return { ...state, isDialogOpen: action.isOpen };
      default:
        return state;
    }
  };
  
  export default templateReducer;