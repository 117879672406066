import {
  FETCH_NOTIFICATIONS_REQUEST,
  MARK_AS_READ_REQUEST,
  FETCH_NOTIFICATIONS_SUCCESS,
  MARK_AS_READ_SUCCESS,
  FETCH_NOTIFICATIONS_FAILURE,
  MARK_AS_READ_FAILURE,
  SNOOZE_NOTIFICATION,
  SEND_NOTIFICATION_SUCCESS,
  SEND_NOTIFICATION_FAILURE
} from '../actions/notificationActions';

const initialState = {
  items: [],
  snoozedItems: [],
  loading: false,
  error: null,
  users:[],
  message: null
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_NOTIFICATIONS_REQUEST:
    case MARK_AS_READ_REQUEST:
      return { ...state, loading: true, error: null, message:null };
    case FETCH_NOTIFICATIONS_SUCCESS:
      return { ...state, loading: false, error:null, message:null, items: action.payload };
    case SEND_NOTIFICATION_SUCCESS:
      return { ...state, loading: false, error:null, message: action.payload };
    case SEND_NOTIFICATION_FAILURE:
      return { ...state, loading: false, error:action.error, message: null };
    case MARK_AS_READ_SUCCESS:
      return { 
        ...state, 
        loading: false, 
        error:null,
        message:null,
        items: state.items.filter(item => item.id !== action.payload) 
      };
    case FETCH_NOTIFICATIONS_FAILURE:
    case MARK_AS_READ_FAILURE:
      return { ...state, loading: false, error: action.error, message:null };
    case SNOOZE_NOTIFICATION:
      const snoozedItem = state.items.find(item => item.id === action.payload);
      return {
        ...state,
        error:null,
        message:null,
        items: state.items.filter(item => item.id !== action.payload),
        snoozedItems: [...state.snoozedItems, snoozedItem],
      };
    default:
      return state;
  }
};

export default notificationReducer;