import React, { useEffect } from "react";
import {
    Box,
    Button,
    Typography,
    Alert,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper
  } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { integrationActions } from "../../../../redux/actions/integrationActions";

const XeroPayrollRefData = () => {

    const dispatch = useDispatch();

    const {payrollRefData,  error, message} = useSelector(state => state.integration.xero);
    const handleXeroSync = () => {
        dispatch(integrationActions.xeroSyncRequest());
    };

    useEffect(() => {
      if (!payrollRefData) {
        dispatch(integrationActions.payrollRefDataRequest());
      }

    },[]);

    return (
        <Box>
          <Box sx={{ p:2, m:2, borderColor:'lightgrey', borderBlockStyle:'dashed' }} component="fieldset">
              <legend><strong>Xero Reference Data</strong></legend>
            <Typography variant="body" component="div">
              Sync refernce data from Xero.
            </Typography>
            {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
            {message && <Alert severity="success" sx={{ mt: 2 }}>{message}</Alert>}
            <Button
              variant="contained"
              color="primary"
              onClick={handleXeroSync}
              sx={{mt:2}}
            >
              Sync
            </Button>
          </Box>
          <Box sx={{ p:2, m: 2 , borderColor:'lightgrey', borderBlockStyle:'dashed' }} component="fieldset">
            <legend><strong>Pay period in Xero</strong></legend>
            { payrollRefData?.pay_periods && payrollRefData.pay_periods.length>0 && 
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead sx={{backgroundColor:'#4386f4'}}>
                  <TableRow >
                    <TableCell sx={{color:'white',fontWeight:'bold'}} >Calendar Id</TableCell>
                    <TableCell sx={{color:'white',fontWeight:'bold'}} >Name</TableCell>
                    <TableCell sx={{color:'white',fontWeight:'bold'}} >calendar_type</TableCell>
                    <TableCell sx={{color:'white',fontWeight:'bold'}} >start_date</TableCell>
                    <TableCell sx={{color:'white',fontWeight:'bold'}} >payment_date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  { payrollRefData.pay_periods.length !== 0 ?
                      payrollRefData.pay_periods.map((row, i) => (
                    <TableRow
                      key={i}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell >{row.payroll_calendar_id}</TableCell>
                      <TableCell >{row.name}</TableCell>
                      <TableCell >{row.calendar_type}</TableCell>
                      <TableCell >{row.start_date}</TableCell>
                      <TableCell >{row.payment_date}</TableCell>
                    </TableRow>
                  ))
                  :
                  'Sync returned empty response from Xero'
                }
                </TableBody>
              </Table>
            </TableContainer>
          }
          </Box>
          <Box sx={{ p:2, m: 2 , borderColor:'lightgrey', borderBlockStyle:'dashed' }} component="fieldset">
            <legend><strong>Employees in Xero</strong></legend>
            { payrollRefData?.employees && payrollRefData.employees.length>0 && 
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead sx={{backgroundColor:'#4386f4'}}>
                  <TableRow >
                    <TableCell sx={{color:'white',fontWeight:'bold'}} >First Name</TableCell>
                    <TableCell sx={{color:'white',fontWeight:'bold'}} >Last Time</TableCell>
                    <TableCell sx={{color:'white',fontWeight:'bold'}} >Email</TableCell>
                    <TableCell sx={{color:'white',fontWeight:'bold'}} >EmployeeId</TableCell>
                    <TableCell sx={{color:'white',fontWeight:'bold'}} >Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  { payrollRefData.employees.length !== 0 ?
                      payrollRefData.employees.map((row, i) => (
                    <TableRow
                      key={i}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell >{row.first_name}</TableCell>
                      <TableCell >{row.last_name}</TableCell>
                      <TableCell >{row.email}</TableCell>
                      <TableCell >{row.employee_id}</TableCell>
                      <TableCell >{row.status}</TableCell>
                    </TableRow>
                  ))
                  :
                  'Sync returned empty response from Xero'
                }
                </TableBody>
              </Table>
            </TableContainer>
          }
          </Box>
          <Box sx={{ p:2, m: 2 , borderColor:'lightgrey', borderBlockStyle:'dashed' }} component="fieldset">
            <legend><strong>Earning rates in Xero</strong></legend>
            { payrollRefData?.earnings_rates && payrollRefData.earnings_rates.length>0 && 
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead sx={{backgroundColor:'#4386f4'}}>
                  <TableRow >
                    <TableCell sx={{color:'white',fontWeight:'bold'}} >Earnings rate Id</TableCell>
                    <TableCell sx={{color:'white',fontWeight:'bold'}} >Name</TableCell>
                    <TableCell sx={{color:'white',fontWeight:'bold'}} >Earnings Type</TableCell>
                    <TableCell sx={{color:'white',fontWeight:'bold'}} >Rate Type</TableCell>
                    <TableCell sx={{color:'white',fontWeight:'bold'}} >Type of Unit</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  { payrollRefData.earnings_rates.length !== 0 ?
                      payrollRefData.earnings_rates.map((row, i) => (
                    <TableRow
                      key={i}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell >{row.earnings_rate_id}</TableCell>
                      <TableCell >{row.name}</TableCell>
                      <TableCell >{row.earnings_type}</TableCell>
                      <TableCell >{row.rate_type}</TableCell>
                      <TableCell >{row.type_of_units}</TableCell>
                    </TableRow>
                  ))
                  :
                  'Sync returned empty response from Xero'
                }
                </TableBody>
              </Table>
            </TableContainer>
          }
          </Box>
          <Box sx={{ p:2, m: 2 , borderColor:'lightgrey', borderBlockStyle:'dashed' }} component="fieldset">
            <legend><strong>History of uploaded timesheets in Xero</strong></legend>
            { payrollRefData?.timesheets && payrollRefData.timesheets.length>0 && 
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead sx={{backgroundColor:'#4386f4'}}>
                  <TableRow >
                    <TableCell sx={{color:'white',fontWeight:'bold'}} >timesheet_id</TableCell>
                    <TableCell sx={{color:'white',fontWeight:'bold'}} >employee_id</TableCell>
                    <TableCell sx={{color:'white',fontWeight:'bold'}} >start_date</TableCell>
                    <TableCell sx={{color:'white',fontWeight:'bold'}} >end_date</TableCell>
                    <TableCell sx={{color:'white',fontWeight:'bold'}} >status</TableCell>
                    <TableCell sx={{color:'white',fontWeight:'bold'}} >earnings_rate_id</TableCell>
                    <TableCell sx={{color:'white',fontWeight:'bold'}} >number_of_units</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  { payrollRefData.timesheets.length !== 0 ?
                      payrollRefData.timesheets.map((row, i) => (
                    <TableRow
                      key={i}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell >{row.timesheet_id}</TableCell>
                      <TableCell >{row.employee_id}</TableCell>
                      <TableCell >{row.start_date}</TableCell>
                      <TableCell >{row.end_date}</TableCell>
                      <TableCell >{row.status}</TableCell>
                      <TableCell >{row.earnings_rate_id}</TableCell>
                      <TableCell >{row.number_of_units}</TableCell>
                    </TableRow>
                  ))
                  :
                  'Sync returned empty response from Xero'
                }
                </TableBody>
              </Table>
            </TableContainer>
          }
          </Box>

        </Box>
      );
}

export default XeroPayrollRefData;