export const SUBMIT_HANDOVER_NOTE = 'SUBMIT_HANDOVER_NOTE';
export const SUBMIT_HANDOVER_NOTE_SUCCESS = 'SUBMIT_HANDOVER_NOTE_SUCCESS';
export const SUBMIT_HANDOVER_NOTE_FAILURE = 'SUBMIT_HANDOVER_NOTE_FAILURE';

export const FETCH_HANDOVER_NOTE = 'FETCH_HANDOVER_NOTE';
export const FETCH_HANDOVER_NOTE_SUCCESS = 'FETCH_HANDOVER_NOTE_SUCCESS';
export const FETCH_HANDOVER_NOTE_FAILURE = 'FETCH_HANDOVER_NOTE_FAILURE';

export const UPDATE_HANDOVER_NOTE = 'UPDATE_HANDOVER_NOTE';
export const UPDATE_HANDOVER_NOTE_SUCCESS = 'UPDATE_HANDOVER_NOTE_SUCCESS';
export const UPDATE_HANDOVER_NOTE_FAILURE = 'UPDATE_HANDOVER_NOTE_FAILURE';

export const submitHandoverNote = (note) => ({
  type: SUBMIT_HANDOVER_NOTE,
  payload: note,
});

export const submitHandoverNoteSuccess = (note) => ({
  type: SUBMIT_HANDOVER_NOTE_SUCCESS,
  payload: note,
});

export const submitHandoverNoteFailure = (error) => ({
  type: SUBMIT_HANDOVER_NOTE_FAILURE,
  payload: error,
});

export const fetchHandoverNote = (id) => ({
  type: FETCH_HANDOVER_NOTE,
  payload: id,
});

export const fetchHandoverNoteSuccess = (note, message) => ({
  type: FETCH_HANDOVER_NOTE_SUCCESS,
  payload: note,
  message
});

export const fetchHandoverNoteFailure = (error) => ({
  type: FETCH_HANDOVER_NOTE_FAILURE,
  payload: error,
});

export const updateHandoverNote = (note) => ({
  type: UPDATE_HANDOVER_NOTE,
  payload: note,
});

export const updateHandoverNoteSuccess = (note, message) => ({
  type: UPDATE_HANDOVER_NOTE_SUCCESS,
  payload: note,
  message
});

export const updateHandoverNoteFailure = (error) => ({
  type: UPDATE_HANDOVER_NOTE_FAILURE,
  payload: error,
});