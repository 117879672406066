import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { 
  Badge, 
  IconButton, 
  Snackbar, 
  List, 
  ListItem, 
  ListItemText, 
  ListItemIcon,
  Button, 
  Typography,
  Chip,
  Box
} from '@mui/material';
import { 
  Notifications as NotificationsIcon, 
  Close as CloseIcon,
  Public as PublicIcon,
  Person as PersonIcon
} from '@mui/icons-material';
import SnoozeIcon from '@mui/icons-material/Snooze';
import {
    fetchNotificationsRequest,
    markAsReadRequest,
    snoozeNotification
} from '../../redux/actions/notificationActions';
import HandoverComponent from '../pages/timesheet/HandoverComponent';
import MultiStaffChatDialog from './MultiStaffChatDialog';

const ChatNotification = () => {
  const [open, setOpen] = useState(false);
  const [openSendNotification, setOpenSendNotification] = useState(false);
  const dispatch = useDispatch();
  const notifications = useSelector(state => state.notification.items);
  const snoozedNotifications = useSelector(state => state.notification.snoozedItems);

  useEffect(() => {
    dispatch(fetchNotificationsRequest());
  }, [dispatch]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleMarkAsRead = (notificationId) => {
    dispatch(markAsReadRequest(notificationId));
  };

  const handleSnooze = (notificationId) => {
    dispatch(snoozeNotification(notificationId));
  };

  const activeNotifications = [...notifications];

  const renderNotification = (notification) => {
    const isBroadcast = notification.is_broadcast;
    return (
      <ListItem 
        key={notification.id}
        sx={{
          backgroundColor: isBroadcast ? 'rgba(255, 223, 186, 0.2)' : 'inherit',
          borderLeft: isBroadcast ? '4px solid orange' : 'none',
          marginBottom: 1,
          borderRadius: 1,
        }}
      >
        <ListItemIcon>
          {isBroadcast ? <PublicIcon color="warning" /> : <PersonIcon color="primary" />}
        </ListItemIcon>
        <ListItemText 
          primary={
            <Typography  variant="body1">
              <Box sx={{color:'black'}}>
                {
                  notification.message && 
                  notification.message.includes("#") ?
                  notification.message.replace(/(?:#)([0-9]+)/, '')
                  :
                  notification.message
                }
                {notification.message && 
                  notification.message.includes("#") && 
                  <HandoverComponent mode={'view'} noteId={notification.message.match(/(?:#)([0-9]+)/)[1]} />
                }
              </Box>
              
              {isBroadcast && (
                <Chip 
                  label="Broadcast" 
                  size="small" 
                  color="warning" 
                  sx={{ marginLeft: 1 }}
                />
              )}
            </Typography>
          }
          secondary={
            <Typography variant="body2" color="text.secondary">
              From: {notification.sender} • {new Date(notification.created_at).toLocaleString()}
            </Typography>
          }
        />
        <Button size="small" sx={{minWidth:'25px'}} onClick={() => handleMarkAsRead(notification.id)}>
          <CloseIcon />
        </Button>
        {!isBroadcast && (
          <Button  size="small" sx={{minWidth:'25px'}} onClick={() => handleSnooze(notification.id)}>
            <SnoozeIcon />
          </Button>
        )}
      </ListItem>
    );
  };


  return (
    <>
      <IconButton onClick={handleOpen} sx={activeNotifications.length > 0 ? { color:'yellow'}: {color:'default'}}> 
      {/* color={activeNotifications.length > 0 ? "error" : "default"} */}
        <Badge badgeContent={activeNotifications.length} color="secondary">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}
      >
        <List sx={{ 
          bgcolor: 'background.paper',
          width: '100%',
          maxWidth: 360,
          maxHeight: 400,
          overflow: 'auto',
          borderRadius: 1,
          boxShadow: 3,
        }}>
          {activeNotifications.length > 0 ? (
            activeNotifications.map(renderNotification)
          ) : (
            <>
              <ListItem>
                <ListItemText sx={{color:'black'}} primary="No new notifications" >
                </ListItemText>
              </ListItem>
              <Box sx={{ml:1}}>
                <Button   onClick={()=> setOpenSendNotification(true)}>
                  Send notification
                </Button>
              </Box>
            </>
          )}
        </List>
      </Snackbar>
      <MultiStaffChatDialog open={openSendNotification} setOpen={setOpenSendNotification}/>
    </>
  );
};

export default ChatNotification;