import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Layout from './components/layout/Layout';
import Admin from './components/pages/Admin';
import Timesheet from './components/pages/TimeSheet';
import Report from './components/pages/Report';
import Profile from './components/pages/Profile';
import Login from './components/auth/Login';
import ForgotPassword from './components/auth/ForgotPassword';
import PrivateRoute from './utils/PrivateRoute';
import TimesheetsProcessed from './components/pages/TimeSheetsProcessed';
import TimeSheetPage from './components/pages/timesheet/AddTimeSheetPage';
import ResetPassword from './components/auth/ResetPassword';
import ErrorPage from './components/layout/ErrorPage';
import { SharedDataProvider } from './utils/SharedDataContext';

const AuthenticatedApp = () => (
  <SharedDataProvider>
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route element={<PrivateRoute />}>
          <Route path="/admin" element={<Admin />} />
          <Route path="/add-timesheets" element={<TimeSheetPage />} />
          <Route path="/timesheets" element={<Timesheet />} />
          <Route path="/processed-timesheets" element={<TimesheetsProcessed />} />
          <Route path="/Reports" element={<Report />} />
          <Route path="/profile" element={<Profile />} />
        </Route>
      </Route>
    </Routes>
  </SharedDataProvider>
);

const App = () => {
  const auth = useSelector((state) => state.auth);

  if (auth.showErrorPage) {
    return <ErrorPage error={auth.error} />;
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={
          auth.isAuthenticated ? 
            (auth.user && auth.user.role === 'Practitioner') ? 
              <Navigate to="/add-timesheets" /> :
              <Navigate to="/timesheets" />
            : <Login />
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/*" element={<AuthenticatedApp />} />
      </Routes>
    </Router>
  );
};

export default App;
