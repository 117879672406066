import React from 'react';

const ContactDetailsView = ({data}) => {

  return (
    <>
      {
        data && (
        <div >
          <table width="100%">
            <tr style={{background:'lightgrey'}}>
            <th width="60%">
              Primary
            </th>
            <th width="40%">
              Emergency
            </th>
            </tr>
    
            <tr>
              <td width="60%">
                <strong>Address:</strong>{data.address},
                <br/> {data.city}, {data.state} - {data.zipcode}<br/>
                <strong>Email:</strong> {data.email}<br/>
                <strong>Phone:</strong> {data.phone}
              </td>
              <td width="40%">
              <strong>Name:</strong> {data.emergency_name}<br/>
              <strong>Relation:</strong> {data.emergency_relationship}<br/>
              <strong>Email:</strong> {data.emergency_email}<br/>
              <strong>Phone:</strong> {data.emergency_phone}
              </td>
            </tr>
          </table>
        </div>
      )}
    </>

  );
};

export default ContactDetailsView;