export const integrationActions = {
    deputyEmployeeSyncRequest: () => ({ type: 'DEPUTY_EMPLOYEE_SYNC_REQUEST'}),
    deputyEmployeeSyncSuccess: (data, message) => ({ type: 'DEPUTY_EMPLOYEE_SYNC_SUCCESS', payload: data, message }),
    deputyEmployeeSyncFailure: (error) => ({ type: 'DEPUTY_EMPLOYEE_SYNC_FAILURE', payload: error }),
    xeroSyncRequest: () => ({ type: 'XERO_SYNC_REQUEST'}),
    xeroSyncSuccess: (data, message) => ({ type: 'XERO_SYNC_SUCCESS', payload: data, message }),
    xeroSyncFailure: (error) => ({ type: 'XERO_SYNC_FAILURE', payload: error }),
    payrollRefDataRequest: () => ({ type: 'PAYROLL_REF_DATA_REQUEST'}),
    payrollRefDataSuccess: (data, message) => ({ type: 'PAYROLL_REF_DATA_SUCCESS', payload: data, message }),
    payrollRefDataFailure: (error) => ({ type: 'PAYROLL_REF_DATA_FAILURE', payload: error }),
    xeroUploadRequest: (data) => ({ type: 'XERO_UPLOAD_REQUEST', payload: data}),
    xeroUploadSuccess: ( message) => ({ type: 'XERO_UPLOAD_SUCCESS', payload:message }),
    xeroUploadFailure: (error) => ({ type: 'XERO_UPLOAD_FAILURE', payload: error }),
    deputyRefDataRequest: () => ({ type: 'DEPUTY_REF_DATA_REQUEST'}),
    deputyRefDataSuccess: (data) => ({ type: 'DEPUTY_REF_DATA_SUCCESS', payload: data}),
    deputyRefDataFailure: (error) => ({ type: 'DEPUTY_REF_DATA_FAILURE', payload: error}),
  };