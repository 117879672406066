import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Typography, Alert, Grid, TextField, InputAdornment } from '@mui/material';
import { addUserRequest, getUsersRequest, deleteUserRequest, modifyUserRequest } from '../../../../redux/actions/userActions';
import AddUser from "./AddStaff";
import { DataGrid } from '@mui/x-data-grid';
import EmailIcon from '@mui/icons-material/Email';

const columns = [
  {
    field: 'first_name',
    renderHeader: () => (
      <strong style={{ color: 'white' }}>
        {'First Name'}
      </strong>
    ),
    flex: 1,
    editable: false
  },
  {
    field: 'last_name',
    renderHeader: () => (
      <strong style={{ color: 'white' }}>
        {'Last Name'}
      </strong>
    ),
    flex: 1,
  },
  {
    field: 'initials',
    renderHeader: () => (
      <strong style={{ color: 'white' }}>
        {'Initials'}
      </strong>
    ),
    flex: 1,
  },
  {
    field: 'email',
    renderHeader: () => (
      <strong style={{ color: 'white' }}>
        {'Email'}
      </strong>
    ),
    flex: 1,
  },
  {
    field: 'role',
    renderHeader: () => (
      <strong style={{ color: 'white' }}>
        {'Role'}
      </strong>
    ),
    flex: 1,
  },
];

const ManageStaff = ({ data }) => {
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState('add');
  const [filter, setFilter] = useState({
    email: '',
    first_name: ''
  });
  const { error, message } = useSelector((state) => state.user);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const dispatch = useDispatch();
  const initialData = {
    id: null,
    first_name: '',
    last_name: '',
    initials: '',
    email: '',
    password: '',
    role: 'Support Worker',
    contact_detail: '',
    user_pref: '',
    documents: []
  }
  const [userData, setUserData] = useState(initialData);

  const handleAddUser = () => {
    setMode('add');
    setOpen(true);
  }
  const handleGetUsers = (e) => {
    e.preventDefault();
    console.log('inside getusrs =', filter);
    dispatch(getUsersRequest(filter));
  }

  const handleClose = (staff) => {
    setOpen(false);
    if (staff) {
      if (!staff.id) {
        dispatch(addUserRequest({ ...staff }));
      } else {
        dispatch(modifyUserRequest({ ...staff }));
      }
    }
    setUserData(initialData);
  };

  const onDeleteStaff = () => {
    console.log("rowSelectionModel=", rowSelectionModel);
    if (rowSelectionModel && rowSelectionModel.length === 1) {
      dispatch(deleteUserRequest(rowSelectionModel[0]));
    }
    else {
      alert('Select a row to delete');
    }
  }

  const onModifyStaff = () => {
    setMode('modify');
    if (rowSelectionModel && rowSelectionModel.length === 1) {
      let selectedUsers = data.filter(item => item.id === rowSelectionModel[0])
      if (selectedUsers && selectedUsers.length === 1) {
        setUserData(selectedUsers[0]);
        console.log(selectedUsers[0]);
        setOpen(true);
      }
    }
    else {
      alert('Select a row to delete');
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilter({ ...filter, [name]: value });
  };

  return (
    <Box sx={{ mb: 2, ml: 2 }} >
      <Typography variant="h6">Manage Staff Members</Typography>
      {error && <Alert severity="error">{error}</Alert>}
      {message && <Alert severity="success">{message}</Alert>}
      <Box sx={{ mt: 2 }}>
        <form onSubmit={handleGetUsers}>
          <Grid container spacing={2}>
            
            <Grid item xs={4} sm={3}>
              <TextField
                fullWidth
                placeholder="Email"
                variant="outlined"
                
                name="email"
                value={filter.email}
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={4} sm={3}>
              <TextField
                fullWidth
                label="First Name"
                name="first_name"
                value={filter.first_name}
                onChange={handleChange}
              />
            </Grid>
            <Grid item sx={{mt:2}}>
              <Button variant="contained" color="primary" type="submit">
                Search
              </Button>
              
              <Button variant="contained" sx={{ ml: 4 }} color="secondary" onClick={handleAddUser}>
                Add
              </Button>
              <Button variant="contained" sx={{ ml: 2 }} color="warning" onClick={onModifyStaff}>
                Modify
              </Button>
              <Button variant="contained" sx={{ ml: 2 }} color="error" onClick={onDeleteStaff}>
                Delete
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
      <Box sx={{ mt: 2, height: 500, width: '100%' }}>
        <DataGrid autoHeight getRowId={(row) => row.id}
          sx={{ "& .MuiDataGrid-withBorderColor": { backgroundColor: '#4386f4' } }}
          rows={data}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 7,
              },
            },
          }}
          pageSizeOptions={[7]}
          checkboxSelection
          disableMultipleRowSelection
          disableRowSelectionOnClick
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
        />
      </Box>
      <AddUser
        handleClose={handleClose}
        open={open}
        initialData={userData}
      />
    </Box>
  );
}

export default ManageStaff;