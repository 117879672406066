import React, { useState } from 'react';
import { Card, CardContent, Typography, Box, TableRow, Table, TableCell, Checkbox } from '@mui/material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import UpdateIcon from '@mui/icons-material/Update';

const TimesheetMobileCard = ({ row, onRowSelectionModelChange, rowSelectionModel }) => {
  const [isSelected, setSelected] = useState(false);

  const setCardSelected = (e, id) => {
    setSelected(!isSelected);
    if (e.target.checked) {
      onRowSelectionModelChange([...rowSelectionModel,id]);
    } else {
      const removedArray = rowSelectionModel.filter( r => r.id === id);
      onRowSelectionModelChange(removedArray);
    }

  }

  return (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Box sx={{display:'flex', flexDirection:'row', justifyContent: 'space-between'}}>
          <Checkbox
                checked={isSelected}
                onChange={(e) => setCardSelected(e, row.id)}
          />
          <Box>
          <Typography variant="h6" gutterBottom>
            {row.customer_name} 
          </Typography>
          </Box>
          <Box> {row.status === 'Approved' ? <TaskAltIcon color="success" /> : <UpdateIcon color="secondary" />}</Box>
        </Box>
        <Table>
          <TableRow>
            <TableCell sx={{backgroundColor:'whitesmoke'}}>
              <Typography variant="body2" color="text.secondary">
                Start Time
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2" color="text.secondary">
                {new Date(row.start_time*1000).toLocaleString()}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{backgroundColor:'whitesmoke'}}>
              <Typography variant="body2" color="text.secondary">
                End Time
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2" color="text.secondary">
                {new Date(row.end_time*1000).toLocaleString()}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{backgroundColor:'whitesmoke'}}>
              <Typography variant="body2" color="text.secondary">
                Total Time
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2" color="text.secondary">
                {Math.round(row.total_time * 100) / 100} hrs
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{backgroundColor:'whitesmoke'}}>
              <Typography variant="body2" color="text.secondary">
                Activity Summary
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2" color="text.secondary">
                {row.employee_comment}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{backgroundColor:'whitesmoke'}}>
              <Typography variant="body2" color="text.secondary">
                Supervisor Comment
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2" color="text.secondary">
                {row.supervisor_comment}
              </Typography>
            </TableCell>
          </TableRow>
        </Table>
      </CardContent>
    </Card>
  )
};

export default TimesheetMobileCard;