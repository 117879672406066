import React, { useEffect, useState} from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Popover, Box, Button, Typography, Alert, MenuItem, Grid, TextField,
  FormControl, InputLabel, Select
 } from '@mui/material';
import { locationActions } from '../../../../redux/actions/locationActions';
import AddLocation from "./AddLocation";
import ContactDetailsView from "../contactDetail/ContactDetailView";
import { DataGrid } from '@mui/x-data-grid';
import ContactPageIcon from '@mui/icons-material/ContactPage';

const ManageLocations = () => {
  const columns = [
    {
      field: 'location_name',
      renderHeader: () => (
        <strong style={{color:'white'}}>
          {'Location Name'}
        </strong>
      ),
      flex: 1,
      editable: false
    },
    {
      field: 'location_type',
      renderHeader: () => (
        <strong style={{color:'white'}}>
          {'Location Type'}
        </strong>
      ),
      flex: 1,
    },
    {
      field: 'contact_person',
      renderHeader: () => (
        <strong style={{color:'white'}}>
          {'Contact Person'}
        </strong>
      ),
      flex: 1,
    },
    {
      field: 'active_from',
      renderHeader: () => (
        <strong style={{color:'white'}}>
          {'Active From'}
        </strong>
      ),
      flex: 1,
      renderCell: ({ row }) => {
        return (
          row.active_from && new Date(row.active_from*1000).toLocaleDateString()
        )
      },
    },
    {
      field: 'active_to',
      renderHeader: () => (
        <strong style={{color:'white'}}>
          {'Active To'}
        </strong>
      ),
      flex: 1,
      renderCell: ({ row }) => {
        return (
          row.active_to && new Date(row.active_to*1000).toLocaleDateString()
        )
      },
    },
    {
      field: 'residence_status',
      renderHeader: () => (
        <strong style={{color:'white'}}>
          {'Status'}
        </strong>
      ),
      flex: 1,
    },
    {
      field: 'contact_detail',
      renderHeader: () => (
        <strong style={{color:'white'}}>
          {'Contact Details'}
        </strong>
      ),
      flex: 1,
      renderCell: ({ row }) => {
        return (
          row.contact_detail && <ContactPageIcon  onClick={(e) => handleContactIconClick(e, row.contact_detail)}/>
        )
      },
    },
  ];
  const { message, locations, error } = useSelector((state) => state.location);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [open, setOpen] = useState(false);
  const [contactDetail, setContactDetail] = useState(null);
  const initialData = {
    id: null,
    location_name: '',
    location_type: '',
    location_description: '',
    active_from: null,
    active_to: null,
    full_address: '',
    contact_person: '',
    residence_status: '',
    facility_details: '',
    contact_detail: '',
    notes: ''
  };
  const [locationData, setLocationData] = useState(initialData);
  const dispatch = useDispatch();

  const [filter, setFilter] = useState({
    name: '',
    type: ''
  });

  useEffect(()=>{
    if (!locations || locations.length == 0) {
      dispatch(locationActions.getLocationsRequest());
    }
  }, []);

  const handleAddLocation = () => {
    setOpen(true);
  }
  const handleGetLocations=(e) =>{
    e.preventDefault();
    dispatch(locationActions.getLocationsRequest(filter));
  }

  const handleClose = (location) => {
    setOpen(false);
    console.log('got new location', location);
    if(location) {
      if (!location.id) {
        dispatch(locationActions.addLocationRequest(location));
      } else {
        dispatch(locationActions.modifyLocationRequest(location));
      }
    }
    setLocationData(initialData);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const handleContactIconClick = (event, contact_detail) => {
    setAnchorEl(event.currentTarget);
    setContactDetail(contact_detail);
  };
  
  const handlePopOverClose = () => {
    setAnchorEl(null);
  };

  const openPopOver = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const onDeleteLocation = () => {
    if (rowSelectionModel && rowSelectionModel.length === 1){
      dispatch(locationActions.deleteLocationRequest(rowSelectionModel[0]));
    }
    else {
      alert('Select a row to delete');
    }
  }

  const onModifyLocation = () => {
    
    if (rowSelectionModel && rowSelectionModel.length === 1){
      let selectedLocations = locations.filter(item => item.id === rowSelectionModel[0])
      if (selectedLocations && selectedLocations.length === 1 ) {
        setLocationData(selectedLocations[0]);
        console.log(selectedLocations[0]);
        setOpen(true);
      }
    }
    else {
      alert('Select a row to delete');
    }
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilter({ ...filter, [name]: value });
  };

  return (
    <Box sx={{ mb: 2, ml:2 }} >
      <Typography variant="h6">Locations</Typography>
      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      {message && <Alert severity="success">{message}</Alert>}
      <Box sx={{ mt: 2 }}>
        <form onSubmit={handleGetLocations}>
          <Grid container spacing={2}>
            
            <Grid item xs={4} sm={3}>
              <FormControl fullWidth >
                <InputLabel id="location-type-label">Location Type</InputLabel>
                <Select
                  labelId="location-type-label"
                  name="type"
                  value={filter.type}
                  onChange={handleChange}
                  label="Location Type"
                >
                  <MenuItem value="Private home">Private home</MenuItem>
                  <MenuItem value="Group home">Group home</MenuItem>
                  <MenuItem value="Supported accommodation">Supported accommodation</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4} sm={3}>
              <TextField
                fullWidth
                label="Location Name"
                name="name"
                value={filter.name}
                onChange={handleChange}
              />
            </Grid>
            <Grid item sx={{mt:2}}>
              <Button variant="contained" color="primary" type="submit">
                Search
              </Button>
              
              <Button variant="contained" sx={{ ml: 4 }} color="secondary" onClick={handleAddLocation}>
                Add
              </Button>
              <Button variant="contained" sx={{ ml: 2 }} color="warning" onClick={onModifyLocation}>
                Modify
              </Button>
              <Button variant="contained" sx={{ ml: 2 }} color="error" onClick={onDeleteLocation}>
                Delete
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>

      <Box sx={{ mt:2, height: 500, width: '100%' }}>
        <DataGrid autoHeight getRowId={(row) => row.id}
        sx={{ "& .MuiDataGrid-withBorderColor": { backgroundColor: '#4386f4' }}}
          rows={locations}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 7,
              },
            },
          }}
          pageSizeOptions={[7]}
          checkboxSelection
          disableRowSelectionOnClick
          disableMultipleRowSelection
          onRowSelectionModelChange={(newRowSelectionModel) => {
            console.log("newRowSelectionModel",newRowSelectionModel);
             setRowSelectionModel(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
        />
      </Box>
      <AddLocation
        handleClose={handleClose}
        initialData={locationData}
        open={open}
      />
      <Popover
        id={id}
        open={openPopOver}
        anchorEl={anchorEl}
        onClose={handlePopOverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <ContactDetailsView data={contactDetail} />
      </Popover>
    </Box>
  );

}

export default ManageLocations;