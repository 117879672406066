import { replaceObjectInArrayById } from '../../utils/DataUtils';

const initialState = {
  participants:[],
  message:null,
  loading: false,
  error: null,
};
  
const participantReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_PARTICIPANT_REQUEST':
    case 'GET_PARTICIPANTS_REQUEST':
      return { ...state, loading: true, error: null, message: null };
    case 'ADD_PARTICIPANT_SUCCESS':
      return {
        ...state,
        loading: false,
        participants: [...state.participants, action.payload],
        message: 'Successfully added the participant',
        error:null
      };
    case 'MODIFY_PARTICIPANT_SUCCESS':
      return {
        ...state,
        loading: false,
        participants: [...replaceObjectInArrayById([...state.participants], action.payload)],
        message: 'Successfully modified the participant',
        error:null
      };
    case 'DELETE_PARTICIPANT_SUCCESS':
      return {
        ...state,
        loading: false,
        participants: state.participants.filter(p => p.id !== action.id),
        message: action.payload,
        error:null
      };
    case 'GET_PARTICIPANTS_SUCCESS':
      return { ...state, loading: false, error:null,
        participants: [...action.payload]
       };
    case 'ADD_PARTICIPANT_FAILURE':
    case 'GET_PARTICIPANTS_FAILURE':
      return { ...state, loading: false, error: action.payload };
    case 'CLEAR_ERROR':
      return { ...state, error: null, message: null};
    default:
      return state;
  }
};

export default participantReducer;