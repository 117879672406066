import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Button, Input, CircularProgress, Alert } from '@mui/material';
import { uploadFileRequest } from '../../../../redux/actions/uploaderActions';

const Uploader = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const dispatch = useDispatch();
  const { uploading, error, success } = useSelector((state) => state.uploader);

  useEffect(() => {
    if (success) {
      setSelectedFile(null);
    }
  }, [success]);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleFileUpload = () => {
    if (!selectedFile) {
      alert('Please select a file first!');
      return;
    }
    dispatch(uploadFileRequest(selectedFile));
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="body" component="div">
        Import from the filled up CSV template file
      </Typography>
      {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
      {success && <Alert severity="success" sx={{ mt: 2 }}>{success}</Alert>}
      <Input
        type="file"
        accept=".csv"
        onChange={handleFileChange}
        sx={{ my: 2 }}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleFileUpload}
        disabled={uploading}
      >
        {uploading ? <CircularProgress size={24} /> : 'Import'}
      </Button>

    </Box>
  );
};

export default Uploader;
