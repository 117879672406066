import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Select, InputLabel, MenuItem, OutlinedInput, Grid, FormControl, Paper } from '@mui/material';
import { fetchReports, fetchReport, exportReport } from '../../../redux/actions/reportActions';
import PayrollDataReport from './PayrollDataReport';
import FortnightDateSelector from '../../common/FortnightDateSelector';
import { formatDateFromLocaleDateString } from '../../../utils/DateUtils';

const ReportModule = () => {
  const [filter, setFilter] = useState();
  const [selReport, setSelReport] = useState('');
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);
  const { reports, reportData } = useSelector(state => state.report);

  useEffect(() => {
    dispatch(fetchReports());
  }, [dispatch]);

  const handleDateChange = ({ startDate, endDate }) => {
    const filterDt = {
      startDate: startDate.toLocaleDateString(),
      endDate: endDate.toLocaleDateString(),
      ...(user.role !== 'Manager' && { userId: user.id })
    };
    setFilter(filterDt);
    dispatch(fetchReport(selReport, filterDt));
  };

  const handleReportSelect = (value) => {
    setSelReport(value);
  };

  const handleExport = (format) => {
    dispatch(exportReport(selReport, filter, format));
  };

  return (
    <Paper elevation={2} sx={{ p: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel id="report-label">Report Type</InputLabel>
            <Select
              labelId="report-label"
              id="report-select"
              value={selReport}
              onChange={(e) => handleReportSelect(e.target.value)}
              input={<OutlinedInput label="Report" />}
              sx={{ width: '250px', mb: 1, textAlign: 'left' }}
            >
              {reports && reports.map(r => (
                <MenuItem key={r.id} value={r.id}>{r.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {selReport === 1 && (
          <>
            <Grid item xs={12} sm={8}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <FortnightDateSelector onDateChange={handleDateChange} />
                <Button sx={{ mr: 2 }} variant="contained" onClick={() => handleExport('xlsx')}>Export to Excel</Button>
                <Button sx={{ mr: 2 }} variant="contained" onClick={() => handleExport('pdf')}>Export to PDF</Button>
                <Button sx={{ mr: 2 }} variant="contained" onClick={() => handleExport('docx')}>Export to DOCX</Button>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <PayrollDataReport data={reportData} title={filter && formatDateFromLocaleDateString(filter.endDate)} />
            </Grid>
          </>
        )}
      </Grid>
    </Paper>
  );
};

export default ReportModule;