import React, { useState, useEffect } from 'react';
import { FormControlLabel, Checkbox, FormGroup } from '@mui/material';

const ValidationForm = ({ onChange, initialData }) => {
  const [validations, setValidations] = useState({
    reimbursementEvidence: initialData['reimbursementEvidence'] === "True" ? true : false,
    convertNightshift: initialData['convertNightshift'] === "True" ? true :false,
    sameRateWeekend: initialData['sameRateWeekend'] === "True" ? true :false,
  });

  useEffect(() => {
    onChange(validations);
  }, [validations, onChange]);

  const handleValidationChange = (e) => {
    setValidations({
      ...validations,
      [e.target.name]: e.target.checked,
    });
  };

  return (
    <FormGroup>
      <FormControlLabel
        control={<Checkbox checked={validations.reimbursementEvidence} onChange={handleValidationChange} name="reimbursementEvidence" />}
        label="Upload reimbursement evidence mandatory for timesheet submission"
      />
      <FormControlLabel
        control={<Checkbox checked={validations.convertNightshift} onChange={handleValidationChange} name="convertNightshift" />}
        label="Allow convert active nightshift to sleepover"
      />
      <FormControlLabel
        control={<Checkbox checked={validations.sameRateWeekend} onChange={handleValidationChange} name="sameRateWeekend" />}
        label="Apply same rate for Saturday and Sunday"
      />
    </FormGroup>
  );
};

export default ValidationForm;
