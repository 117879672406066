// sagas/uploaderSaga.js
import { call, put, takeLatest, select } from 'redux-saga/effects';
import {
  UPLOAD_FILE_REQUEST,
  uploadFileSuccess,
  uploadFileFailure,
} from '../actions/uploaderActions';
import { fetchWithAuth } from '../../utils/fetchApi';

function* uploadFile(action) {
  try {
    const formData = new FormData();
    formData.append('file', action.payload);

    const response = yield call(fetchWithAuth, '/api/timesheets/upload', {
      method: 'POST',
      body: formData,
    });
    const data = yield response.json();

    if (response.ok) {
      yield put(uploadFileSuccess(data.msg));
    } else {
      yield put(uploadFileFailure(data.error));
    }
  } catch (error) {
    yield put(uploadFileFailure(error.message));
  }
}

export default function* uploaderSaga() {
  yield takeLatest(UPLOAD_FILE_REQUEST, uploadFile);
}
