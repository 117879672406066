import { call, put, takeEvery } from 'redux-saga/effects';
import { fetchWithAuth } from  '../../utils/fetchApi';
import { ruleActions } from '../actions/ruleActions';

function* addRuleSaga(action) {
  try {
    const response = yield call(fetchWithAuth, '/api/rules/add', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(action.payload),
    });
    const data = yield response.json();
    if (!response.ok) {
        throw new Error(data.error || 'Failed to add new rule');
    }
    yield put(ruleActions.addRuleSuccess( data ));
  } catch (error) {
    yield put(ruleActions.addRuleFailure(error.message ));
  }
}

function* modifyRuleSaga(action) {
  try {
    const response = yield call(fetchWithAuth, `/api/rules/${action.id}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(action.payload),
    });
    const data = yield response.json();
    if (!response.ok) {
        throw new Error(data.msg || 'Failed to modfy  rule');
    }
    yield put(ruleActions.modifyRuleSuccess( data ));
  } catch (error) {
    yield put(ruleActions.modifyRuleFailure(error.message ));
  }
}

function* getRuleSaga(action) {
    try {
      let url = '/api/rules/list';
      if (action.payload) {
        const params = new URLSearchParams();
        const { category, rule_name } = action.payload;
        if (category) {
          params.append('category', category);
        }
        if (rule_name) {
          params.append('rule_name', rule_name);
        }
        if (params.toString()) {
          url += `?${params.toString()}`;
        }
      }
      const response = yield call(fetchWithAuth, url, {
        method: 'GET'
      });
      const data = yield response.json();
      if (!response.ok) {
          throw new Error(data.msg || 'Failed to get rules');
      }
      yield put(ruleActions.getRulesSuccess( data ));
    } catch (error) {
      yield put(ruleActions.getRulesFailure(error.message ));
    }
  }

  function* deleteRuleSaga(action) {
    try {
      const response = yield call(fetchWithAuth, `/api/rules/${action.payload}`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(action.rule),
      });
      const data = yield response.json();
      if (!response.ok) {
          throw new Error(data.error || 'Failed to get rules');
      }
      let id ;
      if (typeof action.payload === 'number') {
        id = action.payload;
      }
      else {
        const regex = /(\d+)(\?|$)/;
        id = action.payload.match(regex)[1];
      }

      yield put(ruleActions.deleteRuleSuccess( data.msg , id ));
    } catch (error) {
      yield put(ruleActions.deleteRuleFailure(error ));
    }
  }

export function* ruleSaga() {
  yield takeEvery('ADD_RULE_REQUEST', addRuleSaga);
  yield takeEvery('MODIFY_RULE_REQUEST', modifyRuleSaga);
  yield takeEvery('DELETE_RULE_REQUEST', deleteRuleSaga);
  yield takeEvery('GET_RULES_REQUEST', getRuleSaga);
}
