import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Dialog, DialogContent, DialogTitle } from '@mui/material';
import RuleForm from './RuleForm';
import { ruleActions } from '../../../../redux/actions/ruleActions';

const AddRule = ({ handleClose, open, initialData }) => {
  const [data, setData] = useState({...initialData});
  const dispatch = useDispatch();

  useEffect(() => {
    setData({...initialData});
  }, [initialData]);

  const handleSubmit = (formData) => {
    console.log('formData in handle sumbit =>', formData);
    if (formData.id) {
      dispatch(ruleActions.modifyRuleRequest(formData, formData.id));
    } else {
      dispatch(ruleActions.addRuleRequest(formData));
    }
    handleClose();
  };

  return (
    <Box>
      <Dialog
        open={open}
        onClose={() => handleClose(null)} 
        fullWidth
        maxWidth="md"
      >
        <DialogTitle sx={{background:'linear-gradient(to left, darkred, navy)', color:'white'}}>
          {initialData.id ? 'Modify Rule' : 'Add Rule'}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mx: 'auto', p: 2 }}>
            <RuleForm onClose={() => handleClose(null)} onSubmit={handleSubmit} initialData={data} />
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default AddRule;