// src/redux/actions/timesheetActions.js
export const FETCH_TIMESHEETS_REQUEST = 'FETCH_TIMESHEETS_REQUEST';
export const FETCH_TIMESHEETS_SUCCESS = 'FETCH_TIMESHEETS_SUCCESS';
export const FETCH_TIMESHEETS_FAILURE = 'FETCH_TIMESHEETS_FAILURE';

export const FETCH_DEPUTY_TIMESHEETS_REQUEST = 'FETCH_DEPUTY_TIMESHEETS_REQUEST';
export const FETCH_DEPUTY_TIMESHEETS_SUCCESS = 'FETCH_DEPUTY_TIMESHEETS_SUCCESS';
export const FETCH_DEPUTY_TIMESHEETS_FAILURE = 'FETCH_DEPUTY_TIMESHEETS_FAILURE';

export const SEARCH_TIMESHEETS_REQUEST = 'SEARCH_TIMESHEETS_REQUEST';

export const SAVE_SUPERVISOR_COMMENT = 'SAVE_SUPERVISOR_COMMENT';
export const SAVE_SUPERVISOR_COMMENT_SUCCESS = 'SAVE_SUPERVISOR_COMMENT_SUCCESS';
export const SAVE_SUPERVISOR_COMMENT_FAILURE = 'SAVE_SUPERVISOR_COMMENT_FAILURE';

export const UNDO_APPROVE_REQUEST = 'UNDO_APPROVE_REQUEST';
export const UNDO_APPROVE_SUCCESS = 'UNDO_APPROVE_SUCCESS';
export const UNDO_APPROVE_FAILURE = 'UNDO_APPROVE_FAILURE';
export const ADD_NEW_TIMESHEET_REQUEST= 'ADD_NEW_TIMESHEET_REQUEST';
export const ADD_NEW_TIMESHEET_SUCCESS= 'ADD_NEW_TIMESHEET_SUCCESS';
export const ADD_NEW_TIMESHEET_FAILURE= 'ADD_NEW_TIMESHEET_FAILURE';

export const SEARCH_PROCESSED_TIMESHEETS_REQUEST= 'SEARCH_PROCESSED_TIMESHEETS_REQUEST';
export const SEARCH_PROCESSED_TIMESHEETS_SUCCESS= 'SEARCH_PROCESSED_TIMESHEETS_SUCCESS';
export const SEARCH_PROCESSED_TIMESHEETS_FAILURE= 'SEARCH_PROCESSED_TIMESHEETS_FAILURE';

export const SAVE_HOURS_ADJUSTED_REQUEST = 'SAVE_HOURS_ADJUSTED_REQUEST';
export const SAVE_HOURS_ADJUSTED_SUCCESS = 'SAVE_HOURS_ADJUSTED_SUCCESS';
export const SAVE_HOURS_ADJUSTED_FAILURE = 'SAVE_HOURS_ADJUSTED_FAILURE';

export const SAVE_CATEGORY_CHANGE_REQUEST = 'SAVE_CATEGORY_CHANGE_REQUEST';
export const SAVE_CATEGORY_CHANGE_SUCCESS = 'SAVE_CATEGORY_CHANGE_SUCCESS';
export const SAVE_CATEGORY_CHANGE_FAILURE = 'SAVE_CATEGORY_CHANGE_FAILURE';

export const DELETE_TIMESHEETS_REQUEST = 'DELETE_TIMESHEETS_REQUEST';
export const DELETE_TIMESHEETS_SUCCESS = 'DELETE_TIMESHEETS_SUCCESS';
export const DELETE_TIMESHEETS_FAILURE = 'DELETE_TIMESHEETS_FAILURE';


export const SAVE_DEPUTY_DATA_REQUEST = 'SAVE_DEPUTY_DATA_REQUEST';
export const SAVE_DEPUTY_DATA_SUCCESS = 'SAVE_DEPUTY_DATA_SUCCESS';
export const SAVE_DEPUTY_DATA_FAILURE = 'SAVE_DEPUTY_DATA_FAILURE';

export const GET_TIMESHEET_REQUEST = 'GET_TIMESHEET_REQUEST';
export const GET_TIMESHEET_SUCCESS = 'GET_TIMESHEET_SUCCESS';
export const GET_TIMESHEET_FAILURE = 'GET_TIMESHEET_FAILURE';

export const UPDATE_TIMESHEET_REQUEST = 'UPDATE_TIMESHEET_REQUEST';
export const UPDATE_TIMESHEET_SUCCESS = 'UPDATE_TIMESHEET_SUCCESS';
export const UPDATE_TIMESHEET_FAILURE = 'UPDATE_TIMESHEET_FAILURE';

export const fetchTimesheetsRequest = () => ({
  type: FETCH_TIMESHEETS_REQUEST,
});

export const fetchTimesheetsSuccess = (data) => ({
  type: FETCH_TIMESHEETS_SUCCESS,
  payload: data,
});

export const fetchTimesheetsFailure = (error) => ({
  type: FETCH_TIMESHEETS_FAILURE,
  payload: error,
});

export const fetchDeputyTimesheetsRequest = (data) => ({
  type: FETCH_DEPUTY_TIMESHEETS_REQUEST,
  payload: data,
});

export const fetchDeputyTimesheetsSuccess = (data) => ({
  type: FETCH_DEPUTY_TIMESHEETS_SUCCESS,
  payload: data,
});

export const fetchDeputyTimesheetsFailure = (error) => ({
  type: FETCH_DEPUTY_TIMESHEETS_FAILURE,
  payload: error,
});

export const searchTimesheetsRequest = (payload) => ({
  type: SEARCH_TIMESHEETS_REQUEST,
  payload,
});

export const saveSupervisorComment = (data) => ({
  type: SAVE_SUPERVISOR_COMMENT,
  payload: data,
});

export const saveSupervisorCommentSuccess = (data) => ({
  type: SAVE_SUPERVISOR_COMMENT_SUCCESS,
  payload: data,
});

export const saveSupervisorCommentFailure = (error) => ({
  type: SAVE_SUPERVISOR_COMMENT_FAILURE,
  payload: error,
});

export const undoApproveRequest = (data) => ({
  type: UNDO_APPROVE_REQUEST,
  payload: data,
});

export const undoApproveSuccess = (data) => ({
  type: UNDO_APPROVE_SUCCESS,
  payload: data,
});

export const undoApproveFailure = (error) => ({
  type: UNDO_APPROVE_FAILURE,
  payload: error,
});

export const addNewTimesheetRequest = (data) => ({
  type: ADD_NEW_TIMESHEET_REQUEST,
  payload: data,
});

export const addTimesheetDataSuccess = (data) => ({
  type: ADD_NEW_TIMESHEET_SUCCESS,
  payload: data,
});

export const addTimesheetDataFailure = (error) => ({
  type: ADD_NEW_TIMESHEET_FAILURE,
  payload: error,
});

export const searchProcessedTimesheetsRequest = (data) => ({
  type: SEARCH_PROCESSED_TIMESHEETS_REQUEST,
  payload: data,
});

export const searchProcessedTimesheetsSuccess = (data) => ({
  type: SEARCH_PROCESSED_TIMESHEETS_SUCCESS,
  payload: data,
});

export const searchProcessedTimesheetsFailure = (error) => ({
  type: SEARCH_PROCESSED_TIMESHEETS_FAILURE,
  payload: error,
});


export const saveHoursAdjustedRequest = (data) => ({
  type: SAVE_HOURS_ADJUSTED_REQUEST,
  payload: data,
});

export const saveHoursAdjustedSuccess = (data) => ({
  type: SAVE_HOURS_ADJUSTED_SUCCESS,
  payload: data,
});

export const saveHoursAdjustedFailure = (error) => ({
  type: SAVE_HOURS_ADJUSTED_FAILURE,
  payload: error,
});


export const saveCategoryChangeRequest = (data) => ({
  type: SAVE_CATEGORY_CHANGE_REQUEST,
  payload: data,
});



export const saveCategoryChangeSuccess = (data) => ({
  type: SAVE_CATEGORY_CHANGE_SUCCESS,
  payload: data,
});



export const saveCategoryChangeFailure = (data) => ({
  type: SAVE_CATEGORY_CHANGE_FAILURE,
  payload: data,
});


export const deleteTimesheetsRequest = (data) => ({
  type: DELETE_TIMESHEETS_REQUEST,
  payload: data,
});

export const deleteTimesheetsSuccess = (data) => ({
  type: DELETE_TIMESHEETS_SUCCESS,
  payload: data,
});

export const deleteTimesheetsFailure = (error) => ({
  type: DELETE_TIMESHEETS_FAILURE,
  payload: error,
});

export const saveDeputyDataRequest = (data) => ({
  type: SAVE_DEPUTY_DATA_REQUEST,
  payload: data,
});

export const saveDeputyDataSuccess = (data) => ({
  type: SAVE_DEPUTY_DATA_SUCCESS,
  payload: data,
});

export const saveDeputyDataFailure = (error) => ({
  type: SAVE_DEPUTY_DATA_FAILURE,
  payload: error,
});

export const getTimesheetRequest = (id) => ({
  type: GET_TIMESHEET_REQUEST,
  payload: id,
});

export const getTimesheetSuccess = (timesheet) => ({
  type: GET_TIMESHEET_SUCCESS,
  payload: timesheet,
});

export const getTimesheetFailure = (error) => ({
  type: GET_TIMESHEET_FAILURE,
  payload: error,
});

export const updateTimesheetRequest = (timesheetData) => ({
  type: UPDATE_TIMESHEET_REQUEST,
  payload: timesheetData ,
});

export const updateTimesheetSuccess = (timesheet) => ({
  type: UPDATE_TIMESHEET_SUCCESS,
  payload: timesheet,
});

export const updateTimesheetFailure = (error) => ({
  type: UPDATE_TIMESHEET_FAILURE,
  payload: error,
});