import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Chip, Box, Tooltip, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { processShiftRequest } from '../../../redux/actions/shiftActions';
import CommentsDialog from './CommentsDialog';
import DescriptionIcon from '@mui/icons-material/Description';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import UpdateIcon from '@mui/icons-material/Update';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { saveSupervisorComment, undoApproveRequest, deleteTimesheetsRequest } from '../../../redux/actions/timesheetActions';
import TimesheetMobileCard from './TimesheetMobileCard';


const TimeSheetDataGrid = ({ data, onView, onEdit }) => {

  const columns = [
    {
      field: 'start_time',
      headerName: 'Start of Shift',
      flex: 1,
      editable: false,
      renderCell: ({ row }) => {
        return (
          <Box >
            <Box  >
              <span>{new Date(row.start_time*1000).toLocaleDateString()} </span>
              <Chip label={new Date(row.start_time*1000).toLocaleTimeString()} variant="outlined" size="small"/>
            </Box>
          </Box>
        )
      },
    },
    {
      field: 'end_time',
      headerName: 'End of Shift',
      flex: 1,
      editable: false,
      renderCell: ({ row }) => {
        return (
          <Box >
            <Box  >
              <span>{new Date(row.end_time*1000).toLocaleDateString()} </span>
              <Chip label={new Date(row.end_time*1000).toLocaleTimeString()} variant="outlined" size="small" />
            </Box>
          </Box>
        )
      },
    },
    {
      field: 'total_time',
      headerName: 'Time (hrs)',
      type: 'number',
      flex: .5,
      editable: false,
      renderCell: ({ row }) => {
        return (
          row.total_time >10?
          <Box sx={{color:'red'}}>
            {Math.round(row.total_time * 100) / 100}
          </Box>
          : Math.round(row.total_time * 100) / 100
        )
      },
    },
    {
      field: 'employee_comment',
      headerName: 'Activity Summary',
      headerAlign: 'left',
      flex: 1.5,
      renderCell: ({row}) =>  (
        
        <Tooltip title={row.employee_comment} >
         <span >
          {
            row.employee_comment && row.employee_comment.length > 30 ? 
             <>
             <span> {row.employee_comment.slice(0,27)} </span>
             <span>{'...'}</span>
             
             <DescriptionIcon/>
             </> :
            row.employee_comment
          }
          </span>
         </Tooltip>
  
       ),
    },
    {
      field: 'customer_name',
      headerName: 'Participant',
      headerAlign: 'left',
      flex: .5
    },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'left',
      flex: .5,
      renderCell: ({row}) =>  (
        
        <Tooltip title={row.status} >
         <span >
          {
            row.status && row.status == 'Approved' ? 
            <TaskAltIcon color="success"/> :
            <UpdateIcon color="secondary"/>
          }
          </span>
         </Tooltip>
  
       ),
    },
    {
      field: 'supervisor_comment',
      headerName: 'Supervisor Comment',
      headerAlign: 'left',
      flex: 1.5,
      renderCell: ({row}) =>  (
        <Tooltip title={row.supervisor_comment} >
         <span >
          {
            row.supervisor_comment ? 
            <>
              <EditNoteIcon onClick={() => handleEditIconClick(row.supervisor_comment, row.id)}/>
              <span> {row.supervisor_comment} </span>
            </>
            :
            <>
              <EditNoteIcon onClick={() => handleEditIconClick('', row.id)}/> 
              <span>{row.supervisor_comment}</span>
            </>
          }
          </span>
         </Tooltip>
  
       ),
    },
  ];

  const dispatch = useDispatch();
  const role = useSelector((state) => state.auth.user.role);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const [open, setOpen] = useState(false);
  const [comment, setComment] = useState('');
  const [selectedTimeSheet, setSelectedTimeSheet] = useState('');
  const isMobile = useMediaQuery('(max-width:600px)');

  const handleEditIconClick = (currentComment, id) => {
    setComment(currentComment);
    setSelectedTimeSheet(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    dispatch(saveSupervisorComment({comment, selectedTimeSheet}));
  };

  const handleUndoApprove = () => {
    if (rowSelectionModel.length === 0) {
      alert('Please select a row to proceed');
      return;
    }
    dispatch(undoApproveRequest(rowSelectionModel));
  }


  const handleApprove = () => {
    if (rowSelectionModel.length === 0) {
      alert('Please select a row to proceed');
      return;
    }

    let selectedTimesheets = [];
    for (let i of rowSelectionModel){
      const selectedRowArrray = data.filter((item) => item.id === i) ;
      const selectedRow = selectedRowArrray && selectedRowArrray[0];
      if (selectedRow.status === 'Approved') {
        alert('Approved timesheets were selected. Unselect to proceed.');
        return;
      }
      
      selectedTimesheets.push(
        {
          'id':selectedRow.id,
          'staff_id':selectedRow.staff_id,
          'start_time':selectedRow.start_time,
          'end_time':selectedRow.end_time,
          'total_time':selectedRow.total_time,
          'work_type_code':selectedRow.work_type_code
        }
      )

    }
    dispatch(processShiftRequest(selectedTimesheets));

  }

  const handleViewNEdit = (e) => {
    e.preventDefault();
    
    if (rowSelectionModel.length !== 1 ) {
      alert('Please select one row to proceed');
      return;
    }
    const selectedRowData = data.find(item => item.id === rowSelectionModel[0]);
    if (e.target.id === 'Edit') {
      if (selectedRowData.status === 'Approved') {
        alert('Approved timesheets cannot be modified.');
        return;
      }
      onEdit(selectedRowData) 
    } else {
      onView(selectedRowData);
    }  
  }

  const handleDelete = (e) => {
    if (rowSelectionModel.length === 0) {
      alert('Please select a row to proceed');
      return;
    }
    //validate if the soruce of all are eazify
    let eligibleRows = [];
    for( let d of rowSelectionModel) {
      
      const selectedRowData = data.filter(item => item.id === d);
      if (selectedRowData) {
        const selectedData = selectedRowData && selectedRowData[0];
        if (selectedData.source === 'eazify' && selectedData.status === 'Pending'){
          console.log("this row is eligible and will be delted", JSON.stringify(selectedRowData));
          eligibleRows.push(d);
        }
        else{
          alert('Selected data cannot be deleted.');
          return;
        }
      }

      
    };

    dispatch(deleteTimesheetsRequest(eligibleRows));
  }

  return (
    <>
      <Box sx={{textAlign:'left'}}>
      
        { (role === 'Manager'|| role === 'Team Lead') && 
          <>
            <Button sx={{mb:1, ml:1}} variant="contained" color="success" onClick={handleApprove}> Approve </Button> 
            <Button sx={{mb:1, ml:1}} variant="contained" color="warning" onClick={handleUndoApprove}> Undo Approve</Button> 
          </>
        }
        <Button sx={{mb:1, ml:1}} variant="contained" color="info" onClick={handleViewNEdit}> View </Button> 
        <Button id="Edit" sx={{mb:1, ml:1}} variant="contained" color="info" onClick={handleViewNEdit}> Modify </Button> 
        <Button sx={{mb:1, ml:1}} variant="contained" color="error" onClick={handleDelete}> Delete </Button> 
  
      </Box>
      <Box sx={{ width: '100%' }}>
        {isMobile ? (
          data.map((row) => (
            <TimesheetMobileCard key={row.id} row={row} 
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            rowSelectionModel={rowSelectionModel}
            />
          ))
        ) : (
          <DataGrid
            autoHeight
            getRowId={(row) => row.id}
            sx={{ "& .MuiDataGrid-withBorderColor": { backgroundColor: '#f7f7f7' }}}
            rows={data}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 7,
                },
              },
            }}
            pageSizeOptions={[7]}
            checkboxSelection
            disableRowSelectionOnClick
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            rowSelectionModel={rowSelectionModel}
          />
        )}
        <CommentsDialog
          selectedValue={comment}
          open={open}
          handleClose={handleClose}
          setSelectedValue={setComment}
        />

      </Box>
    </>
  );
};

export default TimeSheetDataGrid;