import {
  PROCESS_SHIFT_REQUEST,
  PROCESS_SHIFT_SUCCESS,
  PROCESS_SHIFT_FAILURE
} from '../actions/shiftActions';

const initialState = {
  processing: false,
  data: null,
  error: null,
};

const shiftReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROCESS_SHIFT_REQUEST:
      return {
        ...state,
        error: null,
        processing: true
      };
    case PROCESS_SHIFT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: null,
        processing: false
      };
    case PROCESS_SHIFT_FAILURE:
      return {
        ...state,
        data: null,
        error: action.payload,
        processing: false,
      };
    default:
      return state;
  }
};

export default shiftReducer;
