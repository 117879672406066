import { replaceObjectInArrayById } from '../../utils/DataUtils';

const initialState = {
  locations:[],
  message:null,
  loading: false,
  error: null,
};
  
const locationReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_LOCATION_REQUEST':
    case 'GET_LOCATIONS_REQUEST':
      return { ...state, loading: true, error: null, message: null };
    case 'ADD_LOCATION_SUCCESS':
      return {
        ...state,
        loading: false,
        error:null,
        locations: [...state.locations, action.payload],
        message: 'Successfully added the location'
        
      };
    case 'MODIFY_LOCATION_SUCCESS':
      return {
        ...state,
        loading: false,
        error:null,
        locations: [...replaceObjectInArrayById([...state.locations], action.payload)],
        message: 'Successfully modified the location'
      };
    case 'DELETE_LOCATION_SUCCESS':
      return {
        ...state,
        loading: false,
        locations: state.locations.filter(l => l.id !== action.id),
        message: action.payload,
        error:null
      };
    case 'GET_LOCATIONS_SUCCESS':
      return { ...state, loading: false, error:null,
        locations: [...action.payload]
       };
    case 'ADD_LOCATION_FAILURE':
    case 'GET_LOCATIONS_FAILURE':
      return { ...state, loading: false, error: action.payload };
    case 'CLEAR_ERROR':
      return { ...state, error: null, message: null};
    default:
      return state;
  }
};

export default locationReducer;