// sagas/authSaga.js
import { call, put, takeLatest } from "redux-saga/effects";
import {
  LOGIN_REQUEST,
  REFRESH_TOKEN,
  LOGOUT_REQUEST,
  FORGOT_PASSWORD_REQUEST,
  loginSuccess,
  loginFailure,
  setToken,
  clearToken,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  RESET_PASSWORD_REQUEST,
  resetPasswordSuccess,
  resetPasswordFailure
} from "../actions/authActions";
import { fetchWithAuth } from "../../utils/fetchApi";
import {
  startTokenRefreshTimer,
  stopTokenRefreshTimer,
} from "../../utils/authUtils";

function* loginSaga(action) {
  try {
    const response = yield call(fetch, "/api/auth/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(action.payload),
    });
    const data = yield response.json();
    if (response.ok) {
      // const decodedToken = jwtDecode(data.accessToken);
      yield put(
        loginSuccess(data)
      );
      startTokenRefreshTimer();
    } else {
      yield put(loginFailure(data.error));
    }
  } catch (error) {
    yield put(loginFailure(error.message));
  }
}

function* forgotPasswordSaga(action) {
  try {
    const response = yield call(fetch, "/api/auth/reset/password", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({'email':action.payload}),
    });
    const data = yield response.json();
    if (response.ok) {
      // const decodedToken = jwtDecode(data.accessToken);
      yield put(
        forgotPasswordSuccess(data.msg)
      );
    } else {
      yield put(forgotPasswordFailure(data.error));
    }
  } catch (error) {
    yield put(forgotPasswordFailure(error.message));
  }
}

function* refreshTokenSaga() {
  try {
    const response = yield call(fetchWithAuth, `/api/auth/refresh`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = yield response.json();
    if (response.ok) {
      yield put(setToken(data.accessToken));
      return data.accessToken;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    console.error("Token refresh error:", error);
    yield put(clearToken());
    throw error;
  }
}

function* logoutSaga(action) {
  console.log("logout called stooping timer and cear token")
  stopTokenRefreshTimer();
  yield put(clearToken());
  if (!action.payload) {
    window.location.href = '/login';
  }
}

function* resetPassword(action) {
  try {
    const { token, password } = action.payload;
    const response = yield call(fetch, `/api/auth/reset/${token}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ password }),
    });
    
    if (!response.ok) {
      throw new Error('Password reset failed');
    }

    const data = yield response.json();
    yield put(resetPasswordSuccess(data.msg));
  } catch (error) {
    yield put(resetPasswordFailure(error.message));
  }
}

export default function* watchAuth() {
  yield takeLatest(LOGIN_REQUEST, loginSaga);
  yield takeLatest(LOGOUT_REQUEST, logoutSaga);
  yield takeLatest(REFRESH_TOKEN, refreshTokenSaga);
  yield takeLatest(FORGOT_PASSWORD_REQUEST, forgotPasswordSaga);
  yield takeLatest(RESET_PASSWORD_REQUEST, resetPassword);

}
