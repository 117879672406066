import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  MenuItem,
  Select,
  FormControl,
  OutlinedInput,
  ToggleButtonGroup,
  ToggleButton,
  FormLabel,
  InputLabel,
  ListItemText,
  Checkbox,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { useDispatch } from "react-redux";
import { searchProcessedTimesheetsRequest } from "../../../redux/actions/timesheetActions";
import rangeOfDates from "../../../utils/DateUtils";
import enAU from 'date-fns/locale/en-AU';
import MenuProps from "../../common/MenuProps";

const SearchProcessedTimesheet = ({ employeesList, onSearch }) => {

  const [dateSelection, setDateSelection] = useState("week");
  const [formData, setFormData] = useState({
    selectedEmployees: [],
    startTime: null,
    endTime: null,
  });
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const handleDateSelection = (event, newSelection) => {
    setDateSelection(newSelection);
    if (newSelection !== "custom") {
      setFormData({ ...formData, ...rangeOfDates(newSelection) });
    }
  };

  const handleReset = () => {
    setDateSelection("week");
    setFormData({
      selectedEmployees: [],
      ...rangeOfDates("week"),
    });
    setErrors({});
  };

  const handleDataChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const validateForm = (formattedData) => {
    let tempErrors = {};
    tempErrors.startTime = formattedData.startTime
      ? ""
      : "Start Date is required";
    tempErrors.endTime = formattedData.endTime ? "" : "End Date is required";

    if (
      formattedData.startTime &&
      formattedData.endTime &&
      formattedData.endTime <= formattedData.startTime
    ) {
      tempErrors.endTime = "End Date must be after Start Date";
    }

    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === "");
  };

  useEffect(() => {
    dispatch(searchProcessedTimesheetsRequest(searchFormattedRequest()));
  }, [dispatch]);

  const searchFormattedRequest = () => {
    let formattedData ;
    if (formData.startTime) {
      formattedData = {
        ...formData,
        startTime: (formData.startTime.valueOf() / 1000) | 0,
        endTime: (formData.endTime.valueOf() / 1000) | 0,
      };
    } else {
      const wekDt = rangeOfDates("week");
      setFormData({ ...formData, ...wekDt});
      formattedData = {
        ...formData,
        startTime: (wekDt.startTime.valueOf() / 1000) | 0,
        endTime: (wekDt.endTime.valueOf() / 1000) | 0,
      };
    }
    return formattedData;
  };
  const handleSearch = (e) => {
    e.preventDefault();
    let formattedData = searchFormattedRequest();
    if (validateForm(formattedData)) {
      onSearch(formattedData);
    } else {
      console.log(
        "validation failed formattedData=>",
        JSON.stringify(formattedData)
      );
    }
  };

  const handleSelectChange = (event) => {
    const {
      target: { value },
    } = event;
    handleDataChange("selectedEmployees",
      typeof value === 'string' ? value.split(',') : value,
    );
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enAU}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      
        <FormControl variant="outlined" sx={{ textAlign: "left" }}>
          <InputLabel id="employee-label" >Employees</InputLabel>
          <Select
            labelId="employee-label"
            id="multiple-checkbox"
            multiple
            value={formData.selectedEmployees}
            onChange={handleSelectChange}
            input={<OutlinedInput label="Employees" />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={MenuProps}
          >
            {employeesList && employeesList.map((name) => (
              <MenuItem key={name} value={name}>
                <Checkbox checked={formData.selectedEmployees.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>

        </FormControl>

        <FormControl variant="outlined" sx={{ textAlign: "left" }}>
          <FormLabel id="date-select-label" sx={{mb:1, fontSize:'1em'}}>
            Date Range
          </FormLabel>


          <ToggleButtonGroup
            value={dateSelection}
            exclusive
            onChange={handleDateSelection}
            size='small'
            color="secondary"
            sx={{mb:2}}
          >
            <ToggleButton sx={{fontSize:'0.65em', fontWeight: 'bold'}} value="week">Week</ToggleButton>
            <ToggleButton sx={{fontSize:'0.65em', fontWeight: 'bold'}} value="fortnight">Fortnight</ToggleButton>
            <ToggleButton sx={{fontSize:'0.65em', fontWeight: 'bold'}} value="month">Month</ToggleButton>
            <ToggleButton sx={{fontSize:'0.65em', fontWeight: 'bold'}} value="custom">Custom</ToggleButton>
          </ToggleButtonGroup>
          <Box sx={{my:1}}>
            <DateTimePicker
              label="Start Date"
              value={formData.startTime}
              onChange={(newValue) => handleDataChange("startTime", newValue)}
              renderInput={(params) => <TextField {...params} fullWidth />}
              sx={{ mb: 2}}
              disabled={dateSelection && dateSelection !== "custom"}
              
            />
            <DateTimePicker
              label="End Date"
              value={formData.endTime}
              onChange={(newValue) => handleDataChange("endTime", newValue)}
              renderInput={(params) => <TextField {...params} fullWidth />}
              disabled={dateSelection && dateSelection !== "custom"}
            />
          </Box>
        </FormControl>
        <Button variant="contained" color="primary" onClick={handleSearch}>
          Search
        </Button>
        <Button onClick={handleReset} variant="contained" color="primary">
          Reset
        </Button>
      </Box>
    </LocalizationProvider>
  );
};

export default SearchProcessedTimesheet;
