import React from 'react';
import { Paper, Container } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { modifyUserRequest } from '../../redux/actions/userActions';
import UserProfileEdit from './administration/staff/UserProfileEdit';

const Profile = () => {

  const userData = useSelector((state) => state.auth.user);

  const dispatch = useDispatch();
  const handleSave = (updatedData) => {
    // Handle saving the updated user data
    console.log('Saving updated user data:', updatedData);
    if (updatedData) {
      dispatch(modifyUserRequest(updatedData));
    }
  };

  return (
    <Container maxWidth="lg">

      <Paper elevation={3} style={{ padding: '10px', margin: '10px auto' }}>

      <UserProfileEdit initialData={userData} onSave={handleSave} />

      </Paper>


    </Container>
  );
};

export default Profile;
