import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  List,
  ListItem,
  ListItemText,
  Alert,
  Typography,
  CircularProgress,
  Box
} from '@mui/material';
import {sendNotificationRequest} from '../../redux/actions/notificationActions';
import { useSharedData } from '../../utils/SharedDataContext';


const MultiStaffChat = () => {
  const dispatch = useDispatch();
  const {staffs} = useSharedData();
  const currentUser = useSelector(state => state.auth.user);
  const {loading, error, message} = useSelector(state => state.notification);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [chatMessage, setChatMessage] = useState('');
  const [isBroadcast, setIsBroadcast] = useState(false);

  const handleUserToggle = (userId) => {
    setSelectedUsers(prevSelected =>
      prevSelected.includes(userId)
        ? prevSelected.filter(id => id !== userId)
        : [...prevSelected, userId]
    );
  };

  const handleSendMessage = () => {
    if (chatMessage.trim() && (selectedUsers.length > 0 || isBroadcast)) {
      dispatch(sendNotificationRequest(chatMessage, selectedUsers, isBroadcast));
      setChatMessage('');
      setSelectedUsers([]);
      setIsBroadcast(false);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">Error: {error}</Typography>;
  }

  return (
    <Box sx={{ mb: 2, ml:2, pb:2 }}>
      <Typography variant="h6" >Send Chat Message</Typography>
      {message && <Alert severity="success">{message}</Alert>}
      <Box sx={{mr:2}}>
      <TextField
        fullWidth
        multiline
        rows={4}
        variant="outlined"
        label="Message"
        value={chatMessage}
        onChange={(e) => setChatMessage(e.target.value)}
        margin="normal"
      />
      </Box>
      {
        (currentUser.role !== 'Support Worker' && currentUser.role !== 'Practitioner') &&
        <FormControlLabel
          control={
            <Checkbox
              checked={isBroadcast}
              onChange={(e) => setIsBroadcast(e.target.checked)}
              // disabled={currentUser.role === 'Support Worker' || currentUser.role === 'Practitioner'}
            />
          }
          label="Send as broadcast"
        />
      }
      {!isBroadcast && (
        <Box sx={{ maxHeight: '350px', overflow: 'auto', mb: 1 }}>
        <List >
          {staffs.filter(user => user.id !== currentUser.id).map(user => (
            <ListItem
            key={user.id} dense button 
            onClick={() => handleUserToggle(user.id)}>
              <Checkbox
                edge="start"
                checked={selectedUsers.includes(user.id)}
                tabIndex={-1}
                disableRipple
              />
              <ListItemText primary={user.first_name+ ' '+ user.last_name} secondary={user.role} />
            </ListItem>
          ))}
        </List>
        </Box>
      )}
      <Button
        variant="contained"
        color="primary"
        onClick={handleSendMessage}
        disabled={!chatMessage.trim() || (!isBroadcast && selectedUsers.length === 0)}
      >
        Send Message
      </Button>
    </Box>
  );
};

export default MultiStaffChat;