
const initialState = {
  deputy:{
    employees: [],
    error: null,
    message: null,
  },
  xero:{
    payrollRefData: null,
    error: null,
    message: null,
    uploadMessage: null,
    uploadError: null
  }
};

const integrationReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'DEPUTY_EMPLOYEE_SYNC_REQUEST':
    case 'DEPUTY_REF_DATA_REQUEST':
      return {
        ...state,
        deputy: {
          error: null,
          message: null,
        }
      };
    case 'DEPUTY_EMPLOYEE_SYNC_SUCCESS':
    case 'DEPUTY_REF_DATA_SUCCESS':
      return {
        ...state,
        deputy: {
          employees: action.payload,
          message: action.message,
          error: null,
        }
      };
    case 'DEPUTY_EMPLOYEE_SYNC_FAILURE':
    case 'DEPUTY_REF_DATA_FAILURE':
      return {
        ...state,
        deputy: {
          message: null,
          error: action.payload,
          employees: null,
        }
      };
      case 'XERO_SYNC_REQUEST':
      case 'PAYROLL_REF_DATA_REQUEST':
        return {
          ...state,
          xero: {
            error: null,
            message: null,
          }
        };
      case 'XERO_SYNC_SUCCESS':
      case 'PAYROLL_REF_DATA_SUCCESS':
        return {
          ...state,
          xero: {
            payrollRefData: action.payload,
            message: action.message,
            error: null,
          }
        };
      case 'XERO_SYNC_FAILURE':
      case 'PAYROLL_REF_DATA_FAILURE':
        return {
          ...state,
          xero: {
            message: null,
            error: action.payload,
            payrollRefData: null,
          }
        };
      case 'XERO_UPLOAD_REQUEST':
        return {
          ...state,
          xero: {
            uploadMessage: null,
            uploadError: null,
          }
        };
      case 'XERO_UPLOAD_SUCCESS':
        return {
          ...state,
          xero: {
            uploadMessage: action.payload,
            uploadError: null,
          }
        };
      case 'XERO_UPLOAD_FAILURE':
        return {
          ...state,
          xero: {
            uploadMessage: null,
            uploadError: action.payload,
          }
        };
    default:
      return state;
  }
};

export default integrationReducer;
