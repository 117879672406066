// Report.js
import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Paper, Typography, Tabs, Tab, Alert } from '@mui/material';
import  ReportModule  from './report/ReportModule';
import TemplateManagement  from './report/TemplateManagement';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Report = () => {

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { reports, selectedReport } = useSelector((state) => state.report);
  const { templates, selectedTemplate } = useSelector((state) => state.template);

  return (
    <Paper elevation={2} sx={{p:2}}>

        <Box  xs={12} sm={12}>
          <Tabs
            variant='contained'
            value={value}
            onChange={handleChange}
            sx={{borderRight: 1, borderColor: 'divider', backgroundColor:'whitesmoke' }}
          >
            <Tab label="Tabular Reports" id="report-tab-0" />
            {/* <Tab label="Template Based Reports" id="report-tab-1" /> */}
          </Tabs>

          <TabPanel value={value} index={0}>
            <ReportModule />
          </TabPanel>
          {/* <TabPanel value={value} index={1}>
            <TemplateManagement  />
          </TabPanel> */}
        </Box>
    </Paper>
  );
};

export default Report;
