import { call, put, takeEvery } from 'redux-saga/effects';
import { fetchWithAuth } from  '../../utils/fetchApi';
import { integrationActions } from '../actions/integrationActions';

function* deputyEmployeeSaga(action) {
  try {
    const response = yield call(fetchWithAuth, '/api/integrations/deputy/employees', {
      method: 'GET'
    });
    const data = yield response.json();
    if (!response.ok) {
        throw new Error(data.msg || 'Failed to sync integration with deputy');
    }
    yield put(integrationActions.deputyEmployeeSyncSuccess( data, "Successfully sync data from Deputy" ));
  } catch (error) {
    yield put(integrationActions.deputyEmployeeSyncFailure(error.message ));
  }
}

function* xeroSyncSaga(action) {
  try {
    const response = yield call(fetchWithAuth, '/api/integrations/xero/sync', {
      method: 'GET'
    });
    const data = yield response.json();
    if (!response.ok) {
        throw new Error(data.error || 'Failed to sync data from xero');
    }
    yield put(integrationActions.xeroSyncSuccess( data, "Successfully sync data from xero" ));
  } catch (error) {
    yield put(integrationActions.xeroSyncFailure(error.message ));
  }
}

function* payrollRefDataSaga(action) {
  try {
    const response = yield call(fetchWithAuth, '/api/integrations/xero/list', {
      method: 'GET'
    });
    const data = yield response.json();
    if (!response.ok) {
        throw new Error(data.msg || 'Failed to fetch synched payroll reference data ');
    }
    yield put(integrationActions.payrollRefDataSuccess( data, "Successfully fetched synched payroll reference data" ));
  } catch (error) {
    yield put(integrationActions.payrollRefDataFailure(error.message ));
  }
}

function* xeroUploadSaga(action) {
  try {
    const response = yield call(fetchWithAuth, '/api/integrations/xero/upload', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({'staff_id': action.payload}),
    });
    const data = yield response.json();
    if (!response.ok) {
        throw new Error(data.error || 'Failed to upload xero timesheet data ');
    }
    yield put(integrationActions.xeroUploadSuccess("Successfully upload xero timesheet data" ));
  } catch (error) {
    yield put(integrationActions.xeroUploadFailure(error.message ));
  }
}

function* deputyRefDataSaga(action) {
  try {
    const response = yield call(fetchWithAuth, '/api/integrations/deputy/list', {
      method: 'GET'
    });
    const data = yield response.json();
    if (!response.ok) {
        throw new Error(data.msg || 'Failed to fetch synched payroll reference data ');
    }
    yield put(integrationActions.deputyRefDataSuccess( data, "Successfully fetched synched deputy reference data" ));
  } catch (error) {
    yield put(integrationActions.deputyRefDataFailure(error.message ));
  }
}

export function* integrationSaga() {
  yield takeEvery('DEPUTY_EMPLOYEE_SYNC_REQUEST', deputyEmployeeSaga);
  yield takeEvery('XERO_SYNC_REQUEST', xeroSyncSaga);
  yield takeEvery('PAYROLL_REF_DATA_REQUEST', payrollRefDataSaga);
  yield takeEvery('XERO_UPLOAD_REQUEST', xeroUploadSaga);
  yield takeEvery('DEPUTY_REF_DATA_REQUEST', deputyRefDataSaga);
}
