// reducers/index.js
import { combineReducers } from 'redux';
import authReducer from './authReducer';
import userReducer from './userReducer';
import timesheetReducer from './timesheetReducer';
import uploaderReducer from './uploaderReducer';
import shiftReducer from './shiftReducer';
import processedTimesheetReducer from './processedTimesheetReducer';
import documentReducer from './documentReducer';
import participantReducer from './participantReducer';
import locationReducer from './locationReducer';
import activityTypeReducer from './activityTypeReducer';
import templateReducer from './templateReducer';
import reportReducer from './reportReducer';
import notificationReducer from './notificationReducer';
import handoverReducer from './handoverReducer';
import ruleReducer from './ruleReducer';
import integrationReducer from './integrationReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  timesheet: timesheetReducer,
  document: documentReducer,
  uploader: uploaderReducer,
  shift: shiftReducer,
  processedTimesheet: processedTimesheetReducer,
  participant: participantReducer,
  location:locationReducer,
  activityType:activityTypeReducer,
  template: templateReducer,
  report: reportReducer,
  notification: notificationReducer,
  handover: handoverReducer,
  rule: ruleReducer,
  integration: integrationReducer,
});

export default rootReducer;
