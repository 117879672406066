import React, { useEffect, useState }   from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Typography, Alert } from '@mui/material';
import TimesheetEntryForm from './TimesheetEntryForm';
import { addNewTimesheetRequest, updateTimesheetRequest, getTimesheetRequest } from '../../../redux/actions/timesheetActions';
import { useSharedData } from '../../../utils/SharedDataContext';

export default function TimeSheetPage({id}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [mode, setMode] = useState('add');
  const [timesheetData, setTimesheetData] = useState({});

  const {staffNames} = useSharedData();
  const {message, timesheet} = useSelector((state) => state.timesheet);

  useEffect(() => {
    if (id) {
      setMode(id === 'view' ? 'view' : 'modify');
      dispatch(getTimesheetRequest(id));
    }
  }, [id]);

  useEffect(() => {
    if (id && timesheet) {
      setTimesheetData(timesheet);
    }
  }, [timesheet]);


  const handleSubmit = (formData) => {
    if (formData && formData.employee_name && mode === 'add'){
      const newTimesheet = {
        ...formData, 
        activity_with: formData.activity_with.length > 0 ? formData.activity_with.join(", "):null ,
        start_time:(formData.start_time.valueOf()/1000 | 0),
        end_time:(formData.end_time.valueOf()/1000 | 0)
      };
      dispatch(addNewTimesheetRequest(newTimesheet));
    } else if (formData && formData.employee_name && mode ==='modify') {
      console.log(' modify formData = ', formData);
      const newTimesheet = {
        ...formData, 
        activity_with: formData.activity_with.length > 0 ? formData.activity_with.join(", "):null ,
        start_time:(formData.start_time.valueOf()/1000 | 0),
        end_time:(formData.end_time.valueOf()/1000 | 0)
      };
      dispatch(updateTimesheetRequest(newTimesheet));
    }
    navigate('/timesheets');
  };

  const handleReset = () => {
    // Reset logic
  };

  const handleClose = () => {
    navigate('/timesheets');
  };

  return (
    <Box maxWidth="md" sx={{ margin:'auto', overflowY: "scroll", maxHeight:'670px'}}>
      <Typography sx={{background:'linear-gradient(to left, darkred, navy)', color:'white', p:1}}>
        {mode === 'add' ? 'Add Timesheet' : mode === 'modify' ? 'Modify Timesheet' : 'View Timesheet'}
      </Typography>
      {message && <Alert severity="success">{message}</Alert>}
      <TimesheetEntryForm
        onSubmit={handleSubmit}
        onReset={handleReset}
        onClose={handleClose}
        staffNames={staffNames}
        initialData={timesheetData}
        mode={mode}
      />
    </Box>
  );
}