
const downloadFile = (blob, contentDisposition, downloadUrl) => {
  let filename = 'document.pdf'; // Default filename
  if (contentDisposition) {
    const filenameMatch = contentDisposition.match(/filename="?(.+?)"?$/i);
    if (filenameMatch) {
      filename = filenameMatch[1];
    }
  } else {
    if (downloadUrl) {
      const filenameMatch = downloadUrl.match(/filename="?(.+?)"?$/i);
      if (filenameMatch) {
        filename = filenameMatch[1];
      }
    }
  }
  // Create a temporary URL for the blob
  const url = window.URL.createObjectURL(blob);
  // Create a link element and trigger the download
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  // Clean up the temporary URL
  window.URL.revokeObjectURL(url);
}

export default downloadFile;