import React   from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, Box, TextField, Dialog } from '@mui/material';

export default function CommentsDialog({handleClose, open, selectedValue, setSelectedValue}) {
  return (
    <Box>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            handleClose();
          },
        }}
      >
      <DialogTitle sx={{background:'linear-gradient(to left, darkred, navy)', color:'white'}}>Supervisor Comments</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter comments
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            multiline
            id="SupervisorComments"
            name="SupervisorComments"
            label="Supervisor Comments"
            type="text"
            value={selectedValue}
            onChange={(event) => {
              setSelectedValue(event.target.value);
            }}
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant='outlined'>Cancel</Button>
          <Button type="submit" variant='outlined'>Add</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
