export const activityTypeActions = {
    addActivityTypeRequest: (data) => ({ type: 'ADD_ACTIVITY_TYPE_REQUEST', payload: data }),
    addActivityTypeSuccess: (data) => ({ type: 'ADD_ACTIVITY_TYPE_SUCCESS', payload: data }),
    addActivityTypeFailure: (error) => ({ type: 'ADD_ACTIVITY_TYPE_FAILURE', payload: error }),
    getActivityTypesRequest: (data) => ({ type: 'GET_ACTIVITY_TYPES_REQUEST', payload: data }),
    getActivityTypesSuccess: (data) => ({ type: 'GET_ACTIVITY_TYPES_SUCCESS', payload: data }),
    getActivityTypesFailure: (error) => ({ type: 'GET_ACTIVITY_TYPES_FAILURE', payload: error }),
    deleteActivityTypeRequest: (data) => ({ type: 'DELETE_ACTIVITY_TYPE_REQUEST', payload: data }),
    deleteActivityTypeSuccess: (data, id) => ({ type: 'DELETE_ACTIVITY_TYPE_SUCCESS', payload: data, id }),
    deleteActivityTypeFailure: (error) => ({ type: 'DELETE_ACTIVITY_TYPE_FAILURE', payload: error }),
    modifyActivityTypeRequest: (data) => ({ type: 'MODIFY_ACTIVITY_TYPE_REQUEST', payload: data }),
    modifyActivityTypeSuccess: (data) => ({ type: 'MODIFY_ACTIVITY_TYPE_SUCCESS', payload: data }),
    modifyActivityTypeFailure: (error) => ({ type: 'MODIFY_ACTIVITY_TYPE_FAILURE', payload: error }),
  };