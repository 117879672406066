import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchWithAuth } from  '../../utils/fetchApi';
import { documentActions } from '../actions/documentActions';
import downloadFile from '../../utils/DocUtils';

function* uploadDocumentSaga(action) {
  try {
    const file = action.payload.get('file');
    const fileCategory = action.payload.get('filecategory');
    const response = yield call(fetchWithAuth, '/api/documents/generate-presigned-url', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        filename: file.name,
        content_type: file.type,
        filecategory: fileCategory
      }),
    });
    const data = yield response.json();

    if (!response.ok) {
      throw new Error(data.error || 'Failed to generate presigned URL');
    }

    const { url, fields, method, unique_id, original_filename, filecategory, content_type } = data;

    if (fields) {
      // S3 upload
      const formData = new FormData();
      Object.entries(fields).forEach(([key, value]) => {
        formData.append(key, value);
      });
      formData.append('file', file);

      const uploadResponse = yield call(fetch, url, {
        method: method,
        body: formData,
      });

      if (!uploadResponse.ok) {
        throw new Error('Failed to upload file to S3');
      }

      // Confirm S3 upload
      const confirmResponse = yield call(fetchWithAuth, '/api/documents/confirm-s3-upload', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          unique_id,
          filename: original_filename,
          size: file.size,
          content_type: file.type,
          filecategory: fileCategory
        }),
      });

      if (!confirmResponse.ok) {
        throw new Error('Failed to confirm S3 upload');
      }
    } else {
      // Local upload
      const localUploadResponse = yield call(fetchWithAuth, url, {
        method: 'POST',
        body: action.payload,
      });

      if (!localUploadResponse.ok) {
        throw new Error('Failed to upload file locally');
      }
    }

    yield put(documentActions.uploadDocumentSuccess({
        unique_id: unique_id,
        filename: original_filename,
        size: file.size,
        filecategory: fileCategory
      },
      action.payload.get('module'),
      action.payload.get('id')
    ));
  } catch (error) {
    console.log('Error in upload doc', error);
    yield put(documentActions.uploadDocumentFailure(error.message));
  }
}

function* downloadDocumentSaga(action) {
  try {
    const response = yield call(fetchWithAuth, '/api/documents/generate-download-url', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        unique_id: action.payload,
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to generate download URL');
    }

    const data = yield response.json();
    const downloadUrl = data.url;
    let docResponse;
    if (downloadUrl.includes('s3.amazonaws.com')) {
      docResponse = yield call(fetch, downloadUrl, {
        method: 'GET'
      });
    } else {
      docResponse = yield call(fetchWithAuth, downloadUrl, {
        method: 'GET'
      });
    }

    if (!docResponse.ok) {
      throw new Error('Failed to fetch document');
    }
      // Get the blob from the response
    const blob = yield docResponse.blob();
    // Get the filename from the Content-Disposition header
    const contentDisposition = docResponse.headers.get('Content-Disposition');
    downloadFile(blob, contentDisposition, downloadUrl);

    yield put(documentActions.downloadDocumentSuccess(downloadUrl));
  } catch (error) {
    yield put(documentActions.downloadDocumentFailure(error.message));
  }
}



export default function* documentSaga() {
  yield takeLatest('UPLOAD_DOC_REQUEST', uploadDocumentSaga);
  yield takeLatest('DOWNLOAD_DOC_REQUEST', downloadDocumentSaga);
}