import React from 'react';
import { useDispatch } from 'react-redux';
import { Typography, Button, Box, Container, Card, CardContent, AppBar, Toolbar } from '@mui/material';
import { logoutRequest } from '../../redux/actions/authActions';

const ErrorPage = ({ error }) => {
  const dispatch = useDispatch();

  // Ensure error is a string or convert it to a string
  const errorMessage = error ? error.message || String(error) : '';

  return (
    <Box sx={{ 
      minHeight: '100vh', 
      background: 'linear-gradient(to left, darkred, navy)',
      display: 'flex', 
      flexDirection: 'column',
      padding: 0
    }}>
      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar sx={{ backgroundColor: 'black', justifyContent: 'space-between' }}>
          <img src="/logo.png" alt="Eazify Logo" style={{ width: 40, height: 40, backgroundColor: 'white', borderRadius: '8px' }} />
          <Typography variant="h6" component="div" sx={{ color: 'white' }}>
            Eazify
          </Typography>
        </Toolbar>
      </AppBar>
      
      <Container maxWidth="sm" sx={{ mt: 8 }}>
        <Card sx={{ boxShadow: 3, borderRadius: 2 }}>
          <CardContent sx={{ p: 4 }}>
            <Typography variant="h4" sx={{ mb: 3, textAlign: 'center', fontWeight: 'bold' }}>
              Eazify Error Page
            </Typography>
            {errorMessage && (
              <Typography variant="body2" sx={{ mb: 2, textAlign: 'center', color: 'error.main' }}>
                {errorMessage}
              </Typography>
            )}

            <Button 
              variant="text" 
              onClick={() => dispatch(logoutRequest(null))}
              fullWidth
            >
              Back to Login
            </Button>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
}

export default ErrorPage;