import React, { useState, useEffect } from 'react';
import { TextField, Select, MenuItem, FormControl, InputLabel, Grid, FormHelperText } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { useDispatch, useSelector } from 'react-redux';
import { integrationActions } from '../../../../redux/actions/integrationActions';
import { useSharedData } from '../../../../utils/SharedDataContext';

const ShiftTimeForm = ({ onChange, initialData, errors }) => {
  const {activityTypeNameIds} = useSharedData();
  const dispatch = useDispatch();
  const { payrollRefData } = useSelector (state => state.integration.xero);

  useEffect(() => {
    if (!payrollRefData || payrollRefData.length === 0) {
      dispatch(integrationActions.payrollRefDataRequest());
    }
  }, []);

  const timeToDate = (time) => {
    if (time === '' || time === undefined || time === false) {
      return null;
    }
    // Convert time to a number to handle string inputs
    const numTime = Number(time);
    // Check if the conversion resulted in NaN
    if (isNaN(numTime)) {
      return null;
    }
    // Extract hours and minutes from the input
    const hours = Math.floor(numTime);
    const minutes = Math.round((numTime - hours) * 60);
  
    // Create a new Date object set to the current date
    const date = new Date();
    // Set the hours and minutes on the Date object
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  };

  const [formData, setFormData] = useState({
    activity_type: initialData.activity_type || '',
    shift_type: initialData.shift_type || '',
    start_time: timeToDate(initialData.start_time),
    end_time: timeToDate(initialData.end_time),
    rate_type: initialData.rate_type || '',
    rate_value: initialData.rate_value || '',
    other_rate_type: initialData.other_rate_type || '',
    xero_pay_item: initialData.xero_pay_item || '',
  });
  
  useEffect(() => {
    onChange(formData);
  }, [formData, onChange]);

  const handleChange = (field) => (e) => {
    const value = e && e.target ? e.target.value : e;
    setFormData({ ...formData, [field]: value });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth margin="normal" required>
          <InputLabel id="shift-type-label">Shift Type</InputLabel>
          <Select
            labelId="shift-type-label"
            value={formData.shift_type}
            onChange={handleChange('shift_type')}
            label="Shift Type"
            required
          >
            <MenuItem value="Weekday">Weekday</MenuItem>
            <MenuItem value="Weekday Evening">Weekday Evening</MenuItem>
            <MenuItem value="Active Night Shift">Active Night Shift</MenuItem>
            <MenuItem value="Weekend (Saturday)">Weekend (Saturday)</MenuItem>
            <MenuItem value="Weekend (Saturday) Night">Weekend (Saturday) Night</MenuItem>
            <MenuItem value="Weekend (Sunday)">Weekend (Sunday)</MenuItem>
            <MenuItem value="Weekend (Sunday) Night">Weekend (Sunday) Night</MenuItem>
            <MenuItem value="Public Holiday">Public Holiday</MenuItem> 
          </Select>
          {errors.shift_type && <FormHelperText>{errors.shift_type}</FormHelperText>}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth margin="normal">
          <InputLabel>Activity Type</InputLabel>
          <Select
            name="activity_type"
            value={formData.activity_type}
            onChange={handleChange("activity_type")}
            label="Activity Type"
          >
            {activityTypeNameIds && activityTypeNameIds.map((act) => (
              <MenuItem key={act.id} value={act.id}>{act.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth error={!!errors.start_time} required>
          <TimePicker
            label="Start Time *"
            value={formData.start_time}
            required
            onChange={handleChange('start_time')}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                error={!!errors.start_time}
                helperText={errors.start_time}
              />
            )}
          />
          {errors.start_time && <FormHelperText>{errors.start_time}</FormHelperText>}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth error={!!errors.end_time}>
          <TimePicker
            label="End Time *"
            value={formData.end_time}
            onChange={handleChange('end_time')}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                error={!!errors.end_time}
                helperText={errors.end_time}
              />
            )}
          />
          {errors.end_time && <FormHelperText>{errors.end_time}</FormHelperText>}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <InputLabel id="rate-type-label">Rate Type</InputLabel>
          <Select
            labelId="rate-type-label"
            value={formData.rate_type}
            onChange={handleChange('rate_type')}
            label="Rate Type"
          >
            <MenuItem value="Each">Each</MenuItem>
            <MenuItem value="Hour">Hour</MenuItem>
            <MenuItem value="Daily">Daily</MenuItem>
            <MenuItem value="Week">Week</MenuItem>
            <MenuItem value="Month">Month</MenuItem>
            <MenuItem value="Annual">Annual</MenuItem>
            <MenuItem value="Other">Other</MenuItem>
          </Select>
          {/* <FormHelperText>{errors.rate_type}</FormHelperText> */}
        </FormControl>
      </Grid>
      {formData.rate_type === 'Other' && (
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              label="Other Rate Type"
              value={formData.other_rate_type}
              onChange={handleChange('other_rate_type')}
              fullWidth
              // error={!!errors.other_rate_type}
              // helperText={errors.other_rate_type}
            />
          </FormControl>
        </Grid>
      )}
      <Grid item xs={12} sm={6}>
        <TextField
          label="Rate Value"
          type="number"
          value={formData.rate_value}
          onChange={handleChange('rate_value')}
          fullWidth
        />
      </Grid>
      {
        payrollRefData?.earnings_rates && 
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth  >
            <InputLabel id="xero_pay_item-label">Xero Pay Item </InputLabel>
            <Select
              labelId="xero_pay_item-label"
              value={formData.xero_pay_item}
              onChange={handleChange('xero_pay_item')}
              label="Xero Pay Item"
              
            >
              {
                payrollRefData.earnings_rates && payrollRefData.earnings_rates.map (rate => 
                  <MenuItem key={rate.earnings_rate_id} value={rate.earnings_rate_id}>{rate.name}</MenuItem>
                )
              }
            </Select>
            {/* <FormHelperText>{errors.xero_pay_item}</FormHelperText> */}
          </FormControl>
        </Grid>

      }

    </Grid>
  );
};

export default ShiftTimeForm;