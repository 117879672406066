// sagas/userSaga.js
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { fetchWithAuth } from '../../utils/fetchApi';
import {
  ADDUSER_REQUEST,
  addUserSuccess,
  addUserFailure,
  modifyUserSuccess,
  modifyUserFailure,
  GET_USERS_REQUEST, 
  DELETE_USER_REQUEST,
  getUsersSuccess,
  getUsersFailure,
  deleteUserSuccess,
  deleteUserFailure
} from '../actions/userActions';

function* addUserSaga(action) {
  try {
    const response = yield call(fetchWithAuth, '/api/users/register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(action.payload),
    });
    const data = yield response.json();
    if (response.ok) {
      yield put(addUserSuccess(data));
    } else {
      yield put(addUserFailure(data.error));
    }
  } catch (error) {
    yield put(addUserFailure(error.message));
  }
}

function* modifyUserSaga(action) {
  try {
    const response = yield call(fetchWithAuth, `/api/users/${action.payload.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(action.payload),
    });
    const data = yield response.json();
    if (response.ok) {
      yield put(modifyUserSuccess(data));
    } else {
      yield put(modifyUserFailure(data.error));
    }
  } catch (error) {
    yield put(modifyUserFailure(error.message));
  }
}

function* getUsersSaga(action) {
  try {
    let url = '/api/users/list';
    if (action.payload) {
      const params = new URLSearchParams();
      const { email, first_name } = action.payload;
      if (email) {
        params.append('email', email);
      }
      if (first_name) {
        params.append('first_name', first_name);
      }
      if (params.toString()) {
        url += `?${params.toString()}`;
      }
    }
    const response = yield call(fetchWithAuth, url, {
      method: 'GET'
    });
    const data = yield response.json();
    if (!response.ok) {
      throw new Error(data.error || 'Failed to fetch users');
    }
    yield put(getUsersSuccess(data));

  } catch (error) {
    yield put(getUsersFailure(error.message));
  }
}

function* deleteUserSaga(action) {
  try {
    const response = yield call(fetchWithAuth, `/api/users/${action.payload}`, {
      method: 'DELETE',
    });
    const data = yield response.json();
    if (response.ok) {
      yield put(deleteUserSuccess(data.msg, action.payload ));
    } else {
      yield put(deleteUserFailure(data.error));
    }
  } catch (error) {
    yield put(deleteUserFailure(error.error));
  }
}

export default function* watchUser() {
  yield takeLatest(ADDUSER_REQUEST, addUserSaga);
  yield takeLatest('MODIFY_USER_REQUEST', modifyUserSaga);
  yield takeLatest(GET_USERS_REQUEST, getUsersSaga);
  yield takeLatest(DELETE_USER_REQUEST, deleteUserSaga);
}
