import React, { useEffect, useState} from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Popover, Box, Button, Typography, Alert, Grid, TextField } from '@mui/material';
import { participantActions } from '../../../../redux/actions/participantActions';
import AddParticipant from "./AddParticipant";
import ContactDetailsView from "../contactDetail/ContactDetailView";
import { DataGrid } from '@mui/x-data-grid';
import ContactPageIcon from '@mui/icons-material/ContactPage';

const ManageParticipant = () => {

  const columns = [
    {
      field: 'first_name',
      renderHeader: () => (
        <strong style={{color:'white'}}>
          {'First Name'}
        </strong>
      ),
      flex: 1,
      editable: false
    },
    {
      field: 'last_name',
      renderHeader: () => (
        <strong style={{color:'white'}}>
          {'Last Name'}
        </strong>
      ),
      flex: 1,
      editable: false
    },
    {
      field: 'initials',
      renderHeader: () => (
        <strong style={{color:'white'}}>
          {'Initials'}
        </strong>
      ),
      flex: 1,
    },
    {
      field: 'gender',
      renderHeader: () => (
        <strong style={{color:'white'}}>
          {'Gender'}
        </strong>
      ),
      flex: 1,
    },
    {
      field: 'dob',
      renderHeader: () => (
        <strong style={{color:'white'}}>
          {'Date of birth'}
        </strong>
      ),
      flex: 1,
      renderCell: ({ row }) => {
        return (
          row.dob && new Date(row.dob*1000).toLocaleDateString()
        )
      },
    },
    {
      field: 'participant_number',
      renderHeader: () => (
        <strong style={{color:'white'}}>
          {'Participant#'}
        </strong>
      ),
      flex: 1,
    },
    {
      field: 'contact_detail',
      renderHeader: () => (
        <strong style={{color:'white'}}>
          {'Contact Details'}
        </strong>
      ),
      flex: 1,
      renderCell: ({ row }) => {
        return (
          row.contact_detail && <ContactPageIcon  onClick={(e) => handleContactIconClick(e, row.contact_detail)}/>
        )
      },
    },
  ];

  const { message, participants, error } = useSelector((state) => state.participant);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [contactDetail, setContactDetail] = useState(null);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const initialData = {
    id: null,
    first_name: '',
    last_name: '',
    initials: '',
    gender: '',
    dob: null,
    participant_number: '',
    contact_detail: ''
  };
  const [participantData, setParticipantData] = useState(initialData);
  const [filter, setFilter] = useState({
    first_name: '',
    last_name: ''
  });
  const handleAddParticipant = () => {
    setOpen(true);
  }
  const handleGetParticipants=(e) =>{
    e.preventDefault();
    dispatch(participantActions.getParticipantsRequest(filter));
  }

  const handleClose = (participant) => {
    setOpen(false);
    console.log('got new participant', participant);
    if(participant) {
      if (!participant.id) {
        dispatch(participantActions.addParticipantRequest(participant));
      } else {
        dispatch(participantActions.modifyParticipantRequest(participant));
      }
    }
    setParticipantData(initialData);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const handleContactIconClick = (event, contact_detail) => {
    setAnchorEl(event.currentTarget);
    setContactDetail(contact_detail);
  };
  
  useEffect(() => {
    if (!participants || participants.length === 0) {
      dispatch(participantActions.getParticipantsRequest());
    }
  }, []);

  const handlePopOverClose = () => {
    setAnchorEl(null);
  };

  const openPopOver = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const onDeleteParticipant = () => {
    if (rowSelectionModel && rowSelectionModel.length === 1){
      dispatch(participantActions.deleteParticipantRequest(rowSelectionModel[0]));
    }
    else {
      alert('Select a row to delete');
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilter({ ...filter, [name]: value });
  };

  const onModifyParticipant = () => {
    
    if (rowSelectionModel && rowSelectionModel.length === 1){
      let selectedParticipants = participants.filter(item => item.id === rowSelectionModel[0])
      if (selectedParticipants && selectedParticipants.length === 1 ) {
        setParticipantData(selectedParticipants[0]);
        console.log(selectedParticipants[0]);
        setOpen(true);
      }
    }
    else {
      alert('Select a row to delete');
    }
  }

  return (
    <Box sx={{ mb: 2, ml:2 }} >
      <Typography variant="h6">Participants</Typography>
      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      {message && <Alert severity="success">{message}</Alert>}
      <Box sx={{ mt: 2 }}>
        <form onSubmit={handleGetParticipants}>
          <Grid container spacing={2}>
            
            <Grid item xs={4} sm={3}>
            <TextField
                fullWidth
                label="First Name"
                name="first_name"
                value={filter.first_name}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={4} sm={3}>
              <TextField
                fullWidth
                label="Last Name"
                name="last_name"
                value={filter.last_name}
                onChange={handleChange}
              />
            </Grid>
            <Grid item sx={{mt:2}}>
              <Button variant="contained" color="primary" type="submit">
                Search
              </Button>
              
              <Button variant="contained" sx={{ ml: 4 }} color="secondary" onClick={handleAddParticipant}>
                Add
              </Button>
              <Button variant="contained" sx={{ ml: 2 }} color="warning" onClick={onModifyParticipant}>
                Modify
              </Button>
              <Button variant="contained" sx={{ ml: 2 }} color="error" onClick={onDeleteParticipant}>
                Delete
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
      <Box sx={{ mt:2, height: 500, width: '100%' }}>
        <DataGrid autoHeight getRowId={(row) => row.id}
        sx={{ "& .MuiDataGrid-withBorderColor": { backgroundColor: '#4386f4' }}}
          rows={participants}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 7,
              },
            },
          }}
          pageSizeOptions={[7]}
          checkboxSelection
          disableRowSelectionOnClick
          disableMultipleRowSelection
          onRowSelectionModelChange={(newRowSelectionModel) => {
            console.log("newRowSelectionModel",newRowSelectionModel);
             setRowSelectionModel(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
        />
      </Box>
      <AddParticipant
        handleClose={handleClose}
        initialData={participantData}
        open={open}
      />
      <Popover
        id={id}
        open={openPopOver}
        anchorEl={anchorEl}
        onClose={handlePopOverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <ContactDetailsView data={contactDetail} />
      </Popover>
    </Box>
  );

}

export default ManageParticipant;