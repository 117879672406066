import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import Uploader from './Uploader';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { fetchDeputyTimesheetsRequest, saveDeputyDataRequest} from '../../../../redux/actions/timesheetActions';
import SearchDeputyTimesheets from './SearchDeputyTimesheets';
import SyncDeputyEmployees from "./SyncDeputyEmployees";
import XeroPayrollRefData from "./XeroPayrollRefData";


const ManageIntegration = ({data}) => {
  const [expanded, setExpanded] = React.useState('');
  const dispatch = useDispatch();
  const getFromDeputy = (filters) => {
    console.log(filters)
    dispatch(fetchDeputyTimesheetsRequest(filters));
  };

  const getStaffNames = () => {
    if (data) {
      return data.map( user => ({
        name: user.first_name+ ' '+ user.last_name,
        id: user.id
      }));
    }
  }

  const saveDeputyData = (deputyData) => {
    console.log("saveDeputyData=>",JSON.stringify(deputyData));
    if (saveDeputyData.length === 0){
      alert("No data to save");
      return;
    }
    dispatch(saveDeputyDataRequest(deputyData));
  }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };


  return (
    <Box sx={{p:2}}>
      <Typography variant="h6">Integrations</Typography>
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon  sx={{color:'white'}}/>}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            sx={{background:'linear-gradient(to left, darkred, navy)', color:'white',m:1, borderRadius:'6px'}}
          >
            <Typography sx={{fontWeight:'bold'}}>
            DEPUTY Integration
            </Typography>
            
          </AccordionSummary>
          <AccordionDetails>
            <SyncDeputyEmployees />
            <SearchDeputyTimesheets employeesList={getStaffNames()} onSearch={getFromDeputy}  saveDeputyData={saveDeputyData}/>

          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{color:'white'}} />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
            sx={{background:'linear-gradient(to left, darkred, navy)', color:'white', m:1, borderRadius:'6px'}}
          >
            <Typography sx={{fontWeight:'bold'}}>
            CSV / Excel Integration
            </Typography>
            
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ p:2, m: 2 , borderColor:'lightgrey', borderBlockStyle:'dashed' }} component="fieldset">
              <legend><strong>Excel/csv Upload</strong></legend>

              <a href="EAZIFY_TIMESHEET_TEMPLATE.csv"> Click to download the template</a>
              <hr/>
              <Uploader />
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{color:'white'}} />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
            sx={{background:'linear-gradient(to left, darkred, navy)', color:'white', m:1, borderRadius:'6px'}}
          >
            <Typography sx={{fontWeight:'bold'}}>
            XERO Integration
            </Typography>
            
          </AccordionSummary>
          <AccordionDetails>
            <XeroPayrollRefData/>
          </AccordionDetails>
        </Accordion>
    </Box>
  );

}

export default ManageIntegration;