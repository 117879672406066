import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Grid, Tab, Tabs, Paper } from '@mui/material';

import ManageStaff from './administration/staff/ManageStaff';
import ManageIntegration from './administration/integration/ManageIntegration';
import ManageParticipant from './administration/participant/ManageParticipant';
import ManageLocations from './administration/location/ManageLocation';
import ManageActivityTypes from './administration/activityType/ManageActivityType';
import MultiStaffChat from '../common/MultiStaffChat';
import ManageRules from './administration/rule/ManageRule';

const Admin = () => {

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { data } = useSelector((state) => state.user);

  return (

    <>
      <Box sx={{  mx: 'auto', p: 2 }}>

        <Grid container spacing={2}>
          <Grid item xs={3} sm={1}>
            <Typography  variant="body" component="div" sx={{ bgcolor: 'background.paper', mb: 4, fontWeight:'bold' }}>
              Admin Action
            </Typography>
          {/* <Box 
            sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: '50%' }}
          > */}
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              sx={{width: '150px',borderRight: 1, borderColor: 'divider'}}
            >
              <Tab label="Staff" id="vertical-tab-0" />
              <Tab label="Participant" id="vertical-tab-1" />
              <Tab label="Location" id="vertical-tab-2" />
              <Tab label="Activity Type" id="vertical-tab-3" />              
              <Tab label="Integration" id="vertical-tab-4" />
              <Tab label="Messaging" id="vertical-tab-5" />
              <Tab label="Rule" id="vertical-tab-6" />
              {/* <Tab label="Role" id="vertical-tab-7" />
              <Tab label="Company" id="vertical-tab-8" /> */}
            </Tabs>
            </Grid>
            <Grid  sx={{pl:2}} xs={6} sm={11}>
            <TabPanel sx={{pt:0}} value={value} index={0}>
              <Box sx={{pl:3}}>
              <Paper elevation={3}>
              <ManageStaff data={data} />
              </Paper>
              </Box>
            </TabPanel>
            <TabPanel value={value} index={1}>
            <Box sx={{pl:3}}>
            <Paper elevation={3}>
                <ManageParticipant  />
                </Paper>
              </Box>
              
            </TabPanel>
            <TabPanel value={value} index={2}>
            <Box sx={{pl:3}}>
            <Paper elevation={3}>
              <ManageLocations />
              </Paper></Box>
            </TabPanel>
            <TabPanel value={value} index={3}>
              <Box sx={{pl:3}}>
                <Paper elevation={3}>
                  <ManageActivityTypes />
                </Paper>
              </Box>
            </TabPanel>
            <TabPanel value={value} index={4}>
              <Box sx={{pl:3}}>
                <Paper elevation={3}>
                  <ManageIntegration data={data} />
                </Paper>
              </Box>
            </TabPanel>
            <TabPanel value={value} index={5}>
              <Box sx={{pl:3}}>
                <Paper elevation={3} >
                  <MultiStaffChat />
                </Paper>
              </Box>
            </TabPanel>
            <TabPanel value={value} index={6}>
              <Box sx={{pl:3}}>
                <Paper elevation={3}>
                  <ManageRules />
                </Paper>
              </Box>
            </TabPanel>
            {/* <TabPanel value={value} index={7}>
              <Box sx={{pl:3}}>
                <Paper elevation={3}>
                  <Box sx={{ mb: 2, ml:2, pb:2 }}>
                  <Typography variant="h6" >Role</Typography>
                    
                  </Box>
                </Paper>
              </Box>
            </TabPanel>
            <TabPanel value={value} index={8}>
              <Box sx={{pl:3}}>
                <Paper elevation={3}>
                  <Box sx={{ mb: 2, ml:2, pb:2 }}>
                  <Typography variant="h6" >Company</Typography>
                    
                  </Box>
                </Paper>
              </Box>
            </TabPanel> */}
          </Grid>
        </Grid>
      </Box>
      
    </>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default Admin;
