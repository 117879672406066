import React, { useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileUploadDownload from '../document/FileUploadDownload';
import { useDispatch, useSelector } from 'react-redux';
import HandoverComponent from './HandoverComponent';
import { Button, Box, Alert, Grid, TextField } from '@mui/material';
import { sendNotificationRequest } from '../../../redux/actions/notificationActions';

export default function TimesheetDetailEntryAccordion({selectedAdditionals, uploadedFiles, handleFileUpload, isViewMode, formData, setFormData}) {

  const handover = useSelector(state => state.handover.handoverNote);
  const {error, message} = useSelector (state => state.notification)

  const [expanded, setExpanded] = React.useState(false);
  const dispatch = useDispatch();
  const getUploadedDocs = (fileCategory) => uploadedFiles && uploadedFiles.length > 0 &&
    uploadedFiles.filter(file => file.filecategory === fileCategory);


  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleSendNotificationClick = () =>{
    if (handover){
      dispatch(sendNotificationRequest(
        `A handover note has been shared with you #${handover.id} `,
        [handover.to_staff], false));
    }

  }

  const handleDetailsData = (e) => {
    const {name, value} = e.target;
    console.log(name, ' <= Name, value => ', value);
    // console.log('claimData-',claimData);
    // setClaimData({...claimData, [name]: value});
    setFormData({
      ...formData, 
      details_data: {
        ...formData.details_data,
        [name]: value
      }
    })

  }

  return (
    <div>
      {selectedAdditionals.includes('progress') && 
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            sx={{ backgroundColor:'lightgrey'}}
          >
            <Typography sx={{ width: '33%', flexShrink: 0 }}>
              Progress Reports
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>Upload report (mandatory)</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FileUploadDownload
              parentId={'panel1'}
              uploadedFiles={getUploadedDocs('Progress Report')}
              fileCategory={'Progress Report'}
              onUpload={handleFileUpload}
              disabled={isViewMode}
            />
          </AccordionDetails>
        </Accordion>
      }
      {selectedAdditionals.includes('incident') && 
        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
            sx={{ backgroundColor:'WhiteSmoke'}}
          >
            <Typography sx={{ width: '33%', flexShrink: 0 }}>Incident reports</Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              Upload report (mandatory)
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
          <Grid container spacing={3} sx={{ flexGrow: 1 }}>
              <Grid item xs={12} md={6}>
                <Box>
                  <TextField
                    fullWidth
                    name="incident_type"
                    label="Incident Type"
                    type="text"
                    margin="normal"
                    value={formData.details_data?.incident_type || ''}
                    onChange={handleDetailsData}
                    disabled={isViewMode}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography>
                Fill up the Incident report and upload.
                </Typography>
                <FileUploadDownload parentId={'panel2'} 
                  uploadedFiles={getUploadedDocs('Incident Report')} 
                  fileCategory={'Incident Report'}
                  onUpload={handleFileUpload}
                  disabled={isViewMode}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      }
      {selectedAdditionals.includes('handover') && 
        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
            sx={{ backgroundColor:'lightgrey'}}
          >
            <Typography sx={{ width: '33%', flexShrink: 0 }}>
              Handover Notes
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              Mandatory to fillup or upload notes
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={3} sx={{ flexGrow: 1 }}>
              <Grid item xs={12} md={6}>

                {
                  (handover || formData.handover_id) ? 
                  <>
                    <Typography sx={{m:1}}>
                      Click the below button to {isViewMode? 'view':'edit'} the handover note in Eazify.
                    </Typography>
                    <HandoverComponent mode={isViewMode? 'view':'edit'} noteId={handover && handover.id || formData.handover_id}/>
                    <Typography sx={{m:1}}>
                    <Alert severity='info' >Added handover note #{ (handover && handover.id) || formData.handover_id} </Alert>
                    </Typography>
                    {!isViewMode &&  (handover && handover.id) &&
                      <>
                        <Box sx={{m:1}}>
                          <Button variant="contained" onClick={handleSendNotificationClick} > Send Handover Notification</Button>
                        </Box>
                        {message && <Alert severity="success">{message}</Alert>}
                        {error && <Alert severity="error">{error}</Alert>}
                      </>
                    }
                  </>
                  :
                  <>
                    <Typography sx={{mb:2}}>
                      Click the below button to fill up the handover note in Eazify.
                    </Typography>
                    <HandoverComponent mode={'create'}/>
                  </>
                }               
              </Grid>

              {
              formData.handover_id  ? 
                <></> : 
                handover ? 
                  <></> :
                  <Grid item xs={12} md={6}>
                    <Typography>
                      Alternatively, Fill up the Handover document and upload.
                    </Typography>
                    <FileUploadDownload 
                      parentId={'panel3'}
                      uploadedFiles={getUploadedDocs('Handover Report')}
                      fileCategory={'Handover Report'}
                      disabled={isViewMode}
                      onUpload={handleFileUpload}
                    />
                  </Grid>
              }
                
            </Grid>
          </AccordionDetails>
        </Accordion>
      }
      {selectedAdditionals.includes('claim') && 
        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
            sx={{ backgroundColor:'WhiteSmoke'}}
          >
            <Typography sx={{ width: '33%', flexShrink: 0 }}>
              Reimbursement Claims
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              Upload Evidence (Mandatory)
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={3} sx={{ flexGrow: 1 }}>
              <Grid item xs={12} md={6}>
                <Box>
                  <TextField
                    fullWidth
                    name="claim_amount"
                    label="Claim Amount"
                    type="number"
                    margin="normal"
                    value={formData.details_data?.claim_amount || ''}
                    onChange={handleDetailsData}
                    disabled={isViewMode}
                  />
                </Box>
                <Box>
                  <TextField
                    fullWidth
                    label="Claim Type Evidence"
                    type="text"
                    name="claim_type"
                    margin="normal"
                    value={formData.details_data?.claim_type || ''}
                    onChange={handleDetailsData}
                    disabled={isViewMode}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography>
                Type in the the nature of document in the text field and upload the document
                </Typography>
                <FileUploadDownload
                  parentId={'panel4'}
                  uploadedFiles={getUploadedDocs('Claim')}
                  fileCategory={'Claim'}
                  onUpload={handleFileUpload}
                  disabled={isViewMode}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      }
    </div>
  );
}
