
export default function rangeOfDates(freq) {
  if (freq === "week") {
    const date = new Date(new Date().setHours(0, 0, 0, 0));
    const diff =
      date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
    return {
      startTime: new Date(date.setDate(diff)),
      endTime: new Date(date.setDate(diff + 6)),
    };
  } else if (freq === "fortnight") {
    const date = new Date(new Date().setHours(0, 0, 0, 0));
    const diff =
      date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
    return {
      endTime: new Date(date.setDate(diff + 6)),
      startTime: new Date(date.setDate(diff - 7)),
    };
  } else if (freq === "month") {
    const date = new Date();
    const y = date.getFullYear(),
      m = date.getMonth();
    return {
      startTime: new Date(y, m, 1),
      endTime: new Date(y, m + 1, 0),
    };
  } else {
    console.log("error: wrong freq value provide =>", freq);
    return {
      startTime: null,
      endTime: null,
    };
  }
};

export function formatDateFromLocaleDateString(inputDate) {
  // Parse the input date string
  const parts = inputDate.split('/');
  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1; // Months are 0-based in JavaScript Date
  const year = parseInt(parts[2], 10);

  // Create a Date object
  const date = new Date(year, month, day);

  // Array of month names
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  // Array of day names
  const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  // Format the date
  const formattedDate = `${String(date.getDate()).padStart(2, '0')} ${monthNames[date.getMonth()]} ${date.getFullYear()} ${dayNames[date.getDay()]}`;

  return formattedDate;
}