import React from 'react';
import { useDispatch } from 'react-redux';
import { logoutRequest } from '../redux/actions/authActions';
import ErrorPage from '../components/layout/ErrorPage';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Uncaught error:', error, errorInfo);
    this.props.onError(error);
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage error={this.state.error} />;
    }

    return this.props.children;
  }
}

const ErrorBoundaryWrapper = ({ children }) => {
  const dispatch = useDispatch();

  const handleError = (error) => {
    console.log("inside handle error in error page,", error);
    dispatch(logoutRequest(error));
  };

  return (
    <ErrorBoundary onError={handleError}>
      {children}
    </ErrorBoundary>
  );
};

export default ErrorBoundaryWrapper;