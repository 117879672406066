import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { forgotPasswordRequest } from '../../redux/actions/authActions';
import { Grid, Typography, Button, Box, TextField, Container, Card, CardContent, AppBar, Toolbar } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleForgotPassword = (e) => {
    e.preventDefault();
    dispatch(forgotPasswordRequest(email));
    setMessage('If an account exists for this email, you will receive password reset instructions.');
  };

  return (
    <Box sx={{ 
      minHeight: '100vh', 
      background: 'linear-gradient(to left, darkred, navy)',
      display: 'flex', 
      flexDirection: 'column',
      padding: 0
    }}>
      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar sx={{ backgroundColor: 'black', justifyContent: 'space-between' }}>
          <img src="logo.png" alt="Eazify Logo" style={{ width: 40, height: 40, backgroundColor: 'white', borderRadius: '8px' }} />
          <Typography variant="h6" component="div" sx={{ color: 'white' }}>
            Eazify
          </Typography>
        </Toolbar>
      </AppBar>
      
      <Container maxWidth="sm" sx={{ mt: 8 }}>
        <Card sx={{ boxShadow: 3, borderRadius: 2 }}>
          <CardContent sx={{ p: 4 }}>
            <Typography variant="h4" sx={{ mb: 3, textAlign: 'center', fontWeight: 'bold' }}>
              Forgot Password
            </Typography>

            <Typography variant="body1" sx={{ mb: 3, textAlign: 'center' }}>
              Enter your email to reset the password.
            </Typography>

            <form onSubmit={handleForgotPassword}>
              <TextField 
                fullWidth
                label="Email Address"
                name="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                sx={{ mb: 3 }}
              />

              <Typography variant="body2" sx={{ mb: 3, textAlign: 'center', color: 'text.secondary' }}>
                An email will be sent with instructions to reset your password.
              </Typography>

              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <Button 
                  variant="contained" 
                  type="submit" 
                  sx={{ flex: 1, mr: 1 }}
                >
                  Reset Password
                </Button>
                <Button 
                  variant="outlined" 
                  onClick={() => navigate('/login')}
                  sx={{ flex: 1, ml: 1 }}
                >
                  Back to Login
                </Button>
              </Box>
            </form>

            {message && (
              <Typography variant="body2" sx={{ mt: 2, textAlign: 'center', color: 'success.main' }}>
                {message}
              </Typography>
            )}
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
}

export default ForgotPassword;
