import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Typography,
  Grid,
  Chip,
  OutlinedInput,
  FormGroup,
  FormLabel,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import enAU from 'date-fns/locale/en-AU';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import TimesheetDetailEntryAccordion from './TimesheetDetailEntryAccordion';
import { getUserPreference } from '../../../redux/selectors'; 
import { useSharedData } from '../../../utils/SharedDataContext';

const TimesheetEntryForm = ({ 
    onSubmit, 
    onReset, 
    onClose, 
    staffNames, 
    initialData,
    mode // 'add', 'modify', or 'view'
  }) => {
  const MANAGER_ROLES = ['Manager', 'Team Lead'];
  const loggedInUser = useSelector((state) => state.auth.user);
  const isManager = loggedInUser && MANAGER_ROLES.includes(loggedInUser.role);
  const dispatch = useDispatch();
  const { uploadedFiles } = useSelector((state) => state.document);
  const { locationNames, participantNames, activityTypeNameIds } = useSharedData();
  const userPref = useSelector(getUserPreference);
  const loggedInStaffName = loggedInUser.first_name + ' ' + loggedInUser.last_name;
  const [selectedAdditionals, setSelectedAdditionals] = useState([]);
  const handover = useSelector(state => state.handover.handoverNote);
  const [docs, setDocs] = useState([]);
  const [listData, setListData] = useState({
    workWithStaffNamesList: !isManager  && staffNames ? staffNames.filter(n => n !== loggedInStaffName) : []
  });
  const [formData, setFormData] = useState({
    employee_name: !isManager  ? loggedInStaffName : '',
    activity_with: [],
    start_time: null,
    end_time: null,
    customer_name: userPref?.participant || '',
    location_name: userPref?.location || '',
    work_type_code: userPref?.activity_type || '',
    employee_comment: '',
    handover_id: null,
    documents: [],
    details_data: {}
  });
  const getAdditionalCategories = (documents) => {
    const categoriesMap = {
      'Incident Report': 'incident',
      'Handover Report': 'handover',
      'Progress Report': 'progress',
      'Claim': 'claim'
    };
  
    const additionalCategories = [];
  
    for (const [category, value] of Object.entries(categoriesMap)) {
      if (documents.some(document => document.filecategory === category)) {
        additionalCategories.push(value);
      }
    }
  
    return additionalCategories;
  }

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (mode === 'add') {
      setFormData({
        employee_name: !isManager ? loggedInStaffName : '',
        activity_with: [],
        start_time: null,
        end_time: null,
        customer_name: userPref?.participant || '',
        location_name: userPref?.location || '',
        work_type_code: userPref?.activity_type || '',
        employee_comment: '',
        handover_id: null,
        documents: [],
        details_data: {}
      });
      setDocs([]);
      setSelectedAdditionals([]);
    } else if (mode === 'modify' || mode === 'view') {
      setFormData({...formData, ...initialData,
        activity_with: initialData.activity_with ? initialData.activity_with.split(", "):[],
        start_time: initialData.start_time ? new Date(initialData.start_time*1000 ) : null,
        end_time: initialData.end_time ? new Date(initialData.end_time*1000 ) : null,
        documents: Array.isArray(initialData.documents) ? initialData.documents : []
      });
      if (initialData.documents && initialData.documents.length > 0) {
        const additionalCategories = getAdditionalCategories(initialData.documents);
        setSelectedAdditionals(additionalCategories);
        setDocs([ ...initialData.documents]);
      } else {
        setDocs([]);
        setSelectedAdditionals([]);
      }
      if (initialData.handover_id) {
        setSelectedAdditionals(previousState => {
          if (!previousState.includes('handover')) {
            return [...previousState, 'handover' ];
          } else {
            return previousState;
          }
        });
      } 
    }
  }, [initialData, mode]);

  useEffect(() => {
    if (staffNames) {
      setListData({...listData, workWithStaffNamesList: staffNames.filter(n => n !== loggedInStaffName)});
    }
  }, [staffNames]);

  useEffect(() => {
    if (uploadedFiles && uploadedFiles.length>0) {
      setDocs([...docs, ...uploadedFiles]);
    }
  }, [uploadedFiles]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (name === 'employee_name') {
      setListData({...listData, workWithStaffNamesList: staffNames.filter(n => n !==value)});
    }
    setErrors({ ...errors, [name]: '' });
  };

  const handleCheckBoxChange = (e) => {
    const { name, checked } = e.target;
    if (checked && !selectedAdditionals.includes(name)) {
      setSelectedAdditionals([...selectedAdditionals, name]);
    } else if (!checked && selectedAdditionals.includes(name)) {
      setSelectedAdditionals(selectedAdditionals.filter(item => item !== name));
    }
  };

  const handleDateTimeChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: '' });
  };

  const validateForm = () => {
    let tempErrors = {};
    tempErrors.employee_name = formData.employee_name ? '' : 'Staff name is required';
    tempErrors.start_time = formData.start_time ? '' : 'Shift start is required';
    tempErrors.end_time = formData.end_time ? '' : 'Shift end is required';
    tempErrors.customer_name = formData.customer_name ? '' : 'Client name is required';
    
    if (formData.start_time && formData.end_time && formData.end_time <= formData.start_time) {
      tempErrors.end_time = 'Shift end must be after shift start';
    }

    setErrors(tempErrors);
    return Object.values(tempErrors).every(x => x === '');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      onSubmit({
        ...formData,
        documents: docs,
        ...(handover && {handover_id:handover.id})
      });
    }
  };

  const handleReset = () => {
    if (mode === 'add') {
      setFormData({
        employee_name: loggedInUser.role !== 'Manager' ? loggedInStaffName : '',
        activity_with: [],
        start_time: null,
        end_time: null,
        customer_name: '',
        location_name: '',
        work_type_code: '',
        employee_comment: '',
        handover_id: null,
        documents: [],
        details_data: {}
      });
      setDocs([]);
      setSelectedAdditionals([]);
    } else if (mode === 'modify') {
      
      setFormData(initialData);
      setDocs(initialData.documents);
      setSelectedAdditionals(initialData.additionals || []);
    }
    setErrors({});
    onReset();
  };

  const handleMultiSelectChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: typeof value === 'string' ? value.split(',') : value });
  };

  const handleFileUpload = (formData) => {
    if (formData) {
      dispatch({ type: 'UPLOAD_DOC_REQUEST', payload: formData });
    }
  };

  const isViewMode = mode === 'view';

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enAU}>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth error={!!errors.employee_name} disabled={isViewMode}>
              <InputLabel>Staff Name</InputLabel>
              <Select
                name="employee_name"
                value={formData.employee_name}
                onChange={handleChange}
                label="Staff Name"
              >
                {
                  !isManager ?
                  <MenuItem key={loggedInStaffName} value={loggedInStaffName}>{loggedInStaffName}</MenuItem>
                  :
                  staffNames && staffNames.map((name) => (
                    <MenuItem key={name} value={name}>{name}</MenuItem>
                  ))
                }
              </Select>
              {errors.employee_name && <Typography color="error" variant="caption">{errors.employee_name}</Typography>}
            </FormControl>

          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth disabled={isViewMode}>
              <InputLabel id="work-with-label-id">Work with</InputLabel>
              <Select
                id="workWithStaffNames_id"
                name="activity_with"
                multiple
                value={Array.isArray(formData.activity_with) ? formData.activity_with : []}
                onChange={handleMultiSelectChange}
                labelId="work-with-label-id"
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected && selected.map((value) => (
                      <Chip key={value} label={value} size="small" />
                    ))}
                  </Box>
                )}
              >
                {listData.workWithStaffNamesList && listData.workWithStaffNamesList.map((name) => (
                  <MenuItem key={name} value={name}
                    //style={getStyles(name, personName, theme)}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
              
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth error={!!errors.customer_name} disabled={isViewMode}>
              <InputLabel>Participant</InputLabel>
              <Select
                name="customer_name"
                value={formData.customer_name}
                onChange={handleChange}
                label="Participant"
              >
                {participantNames.map((client) => (
                  <MenuItem key={client} value={client}>{client}</MenuItem>
                ))}
              </Select>
              {errors.customer_name && <Typography color="error" variant="caption">{errors.customer_name}</Typography>}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth error={!!errors.location_name} disabled={isViewMode}>
              <InputLabel>Location</InputLabel>
              <Select
                name="location_name"
                value={formData.location_name}
                onChange={handleChange}
                label="Location"
              >
                {locationNames.map((client) => (
                  <MenuItem key={client} value={client}>{client}</MenuItem>
                ))}
              </Select>
              {errors.location_name && <Typography color="error" variant="caption">{errors.location_name}</Typography>}
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={6}>
            <DateTimePicker
              disabled={isViewMode}
              label="Shift Start"
              fullWidth
              value={formData.start_time}
              onChange={(newValue) => handleDateTimeChange('start_time', newValue)}
              renderInput={(params) => <TextField {...params} fullWidth error={!!errors.start_time} helperText={errors.start_time} />}
            />
            {errors.start_time && <Typography color="error" variant="caption">{errors.start_time}</Typography>}
          </Grid>
          <Grid item xs={6} sm={6}>
            <DateTimePicker
              disabled={isViewMode}
              label="Shift End"
              fullWidth
              value={formData.end_time}
              onChange={(newValue) => handleDateTimeChange('end_time', newValue)}
              renderInput={(params) => <TextField {...params} fullWidth error={!!errors.end_time} helperText={errors.end_time} />}
            />
            {errors.end_time && <Typography color="error" variant="caption">{errors.end_time}</Typography>}
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth error={!!errors.work_type_code} disabled={isViewMode}>
              <InputLabel>Activity Type</InputLabel>
              <Select
                name="work_type_code"
                value={formData.work_type_code}
                onChange={handleChange}
                label="Activity Type"
              >
                {activityTypeNameIds.map((type) => (
                  <MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>
                ))}
              </Select>
              {errors.work_type_code && <Typography color="error" variant="caption">{errors.work_type_code}</Typography>}
            </FormControl>
            <FormControl fullWidth sx={{mt:1}} disabled={isViewMode}>
              <FormLabel component="legend">Add Additional Details</FormLabel>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox size='small' checked={selectedAdditionals.includes('progress')} onChange={handleCheckBoxChange} name="progress" />
                  }
                  label={'Progress Note'}
                />
                <FormControlLabel
                  control={
                    <Checkbox size='small'checked={selectedAdditionals.includes('handover')} onChange={handleCheckBoxChange} name="handover" />
                  }
                  label={'Handover Note'}
                />
                <FormControlLabel
                  control={
                    <Checkbox size='small' checked={ selectedAdditionals.includes('incident')} onChange={handleCheckBoxChange} name="incident" />
                  }
                  label={'Incident Report'}
                />
                <FormControlLabel
                  control={
                    <Checkbox size='small' checked={ selectedAdditionals.includes('claim')} onChange={handleCheckBoxChange} name="claim" />
                  }
                  label={'Claim'}
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              multiline
              rows={5}
              label="Comments"
              name="employee_comment"
              value={formData.employee_comment}
              onChange={handleChange}
              disabled={isViewMode}
            />
          </Grid>
          <Grid item xs={12}>
            <TimesheetDetailEntryAccordion 
              selectedAdditionals={selectedAdditionals} 
              uploadedFiles={docs} 
              formData={formData}
              setFormData={setFormData}
              handleFileUpload={handleFileUpload}
              isViewMode={isViewMode}
            />
          </Grid>  
        </Grid>

        {!isViewMode ? (
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={onClose} sx={{ mr: 1 }}>
              Close
            </Button>
            <Button onClick={handleReset} sx={{ mr: 1 }} >
              Reset
            </Button>
            <Button type="submit" variant="contained" color="primary">
              {mode === 'add' ? 'Submit' : 'Update'}
            </Button>
          </Box>
        )
        : (
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={onClose} sx={{ mr: 1 }}>
              Close
            </Button>
          </Box>
        )}
      </Box>
    </LocalizationProvider>
  );
};

export default TimesheetEntryForm;