
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Link } from '@mui/material';
import HandoverForm from './HandoverForm';
import { 
  fetchHandoverNote, updateHandoverNote, submitHandoverNote } 
from '../../../redux/actions/handoverActions';

const HandoverComponent = ({noteId, mode }) => {


  const dispatch = useDispatch();
  const handoverNote = useSelector((state) => state.handover.handoverNote);
  const [open, setOpen] = useState(false);
  const [noteData, setNoteData] = useState(null);

  const handleClickOpen = (mode, id) => {
    if (mode && mode !== 'create' && id) {
      dispatch(fetchHandoverNote(id));
    } else {
      setNoteData(null);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (mode && mode !== 'create' && handoverNote) {
      setNoteData(handoverNote);
    }
  }, [handoverNote, mode]);

  const handleUpdate = (note) => {
    dispatch(updateHandoverNote(note));
    handleClose();
  };

  const handleSubmit = (note) => {
    dispatch(submitHandoverNote(note));
    handleClose();
  };


  return (
    <div>
      {
        mode && mode === 'view' ?
        <Button  sx={{mr:2, mb:1}}  variant="contained" onClick={() => handleClickOpen('view', noteId)}>
          View
        </Button>
        :
        mode && mode === 'edit' && noteId ?
        <Button sx={{mr:2, mb:1}}  variant="contained" onClick={() => handleClickOpen('edit', noteId)}>
          Edit
        </Button>
        :
        <Button sx={{mr:2, mb:1}}  variant="contained" onClick={() => handleClickOpen('create')}>
          Create
        </Button>  
      }

      <HandoverForm
        open={open}
        handleClose={handleClose}
        mode={mode}
        noteData={noteData}
        handleUpdate={handleUpdate}
        handleAdd={handleSubmit}
      />
    </div>
  );
};

export default HandoverComponent;
