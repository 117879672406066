import { call, put, takeLatest } from 'redux-saga/effects';

import { fetchWithAuth } from '../../utils/fetchApi';
import {
  FETCH_TEMPLATES,
  CREATE_TEMPLATE,
  UPDATE_TEMPLATE,
  DELETE_TEMPLATE,
  fetchTemplatesSuccess,
  toggleDialog
} from '../actions/templateActions';

function* fetchTemplatesSaga() {
  try {
    const response = yield call(fetchWithAuth, '/api/reports/templates/list', {
      method: 'GET'});
    const data = yield response.json();
    yield put(fetchTemplatesSuccess(data));
  } catch (error) {
    console.error('Error fetching templates:', error);
  }
}

function* createTemplateSaga(action) {
  try {
    yield call(fetchWithAuth, '/api/reports/templates/add', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(action.template),
    });
    yield put(toggleDialog(false));
    yield call(fetchTemplatesSaga);
  } catch (error) {
    console.error('Error creating template:', error);
  }
}

function* updateTemplateSaga(action) {
  try {
    yield call(fetchWithAuth, `/api/reports/templates/${action.template.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(action.template),
    });
    yield put(toggleDialog(false));
    yield call(fetchTemplatesSaga);
  } catch (error) {
    console.error('Error updating template:', error);
  }
}

function* deleteTemplateSaga(action) {
  try {
    yield call(fetchWithAuth, `/api/reports/templates/${action.id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(action.payload),
    });
    yield call(fetchTemplatesSaga);
  } catch (error) {
    console.error('Error deleting template:', error);
  }
}

export default function* templateSaga() {
  yield takeLatest(FETCH_TEMPLATES, fetchTemplatesSaga);
  yield takeLatest(CREATE_TEMPLATE, createTemplateSaga);
  yield takeLatest(UPDATE_TEMPLATE, updateTemplateSaga);
  yield takeLatest(DELETE_TEMPLATE, deleteTemplateSaga);
}