// actions/authActions.js
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const SET_TOKEN = 'SET_TOKEN';
export const CLEAR_TOKEN = 'CLEAR_TOKEN';
export const UPDATE_LAST_ACTIVITY = 'UPDATE_LAST_ACTIVITY';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const resetPasswordRequest = (payload) => ({
  type: RESET_PASSWORD_REQUEST,
  payload
});

export const resetPasswordSuccess = (message) => ({
  type: RESET_PASSWORD_SUCCESS,
  message
});

export const resetPasswordFailure = (error) => ({
  type: RESET_PASSWORD_FAILURE,
  error
});



export const loginRequest = (payload) => ({
  type: LOGIN_REQUEST,
  payload,
});

export const loginSuccess = (data) => ({
  type: LOGIN_SUCCESS,
  payload: data,
});

export const loginFailure = (error) => ({
  type: LOGIN_FAILURE,
  payload: error,
});

export const forgotPasswordRequest = (payload) => ({
  type: FORGOT_PASSWORD_REQUEST,
  payload
});

export const forgotPasswordSuccess = (data) => {
  return {type: "FORGOT_PASSWORD_SUCCESS", payload: data};
}

export const forgotPasswordFailure = (data) => {
  return {type: "FORGOT_PASSWORD_FAILURE", payload: data};
}

export const refreshToken = (payload) => ({
  type: REFRESH_TOKEN,
  payload
});

export const setToken = (payload) => ({
  type: SET_TOKEN,
  payload
});

export const clearToken = () => ({
  type: CLEAR_TOKEN,
});

export const updateLastActivity = () => ({
  type: UPDATE_LAST_ACTIVITY,
});

export const logoutRequest = (payload) => ({
  type: LOGOUT_REQUEST,
  payload
});