import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Grid, Alert, Dialog, DialogContent, DialogTitle, Divider, Select, MenuItem, FormControl, InputLabel, FormHelperText } from '@mui/material';
import { useSelector } from 'react-redux';
import ContactDetailsDialog from '../contactDetail/ContactDetailsDialog';
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ContactDeatilsView from '../contactDetail/ContactDetailView';
import enAU from 'date-fns/locale/en-AU';

const AddLocation = ({handleClose, open, initialData}) => {
  const [data, setData] = useState({...initialData});
  const [errors, setErrors] = useState({});
  const { loading, error } = useSelector((state) => state.user);
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    setData({...initialData});
    setErrors({});
  }, [initialData]);

  const validateField = (name, value) => {
    let error = '';
    if (['location_name', 'location_description', 'location_type', 'contact_person'].includes(name)) {
      if (!value.trim()) {
        error = 'This field is required';
      }
    }
    return error;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
    setErrors({ ...errors, [name]: validateField(name, value) });
  };

  const handleDateChange = (name, newValue) => {
    setData({ ...data, [name]: newValue ? ((newValue.valueOf() / 1000) | 0) : null });
  };

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleSubmitContactDetails = (contactData) => {
    setData({ ...data, 'contact_detail': contactData });
    console.log('Contact details submitted:', contactData);
  };

  const handleAddLocationSubmit = () => {
    const newErrors = {};
    Object.keys(data).forEach(key => {
      const error = validateField(key, data[key]);
      if (error) newErrors[key] = error;
    });

    if (Object.keys(newErrors).length === 0) {
      console.log('Location Add submitted:', JSON.stringify(data));
      handleClose(data);
    } else {
      setErrors(newErrors);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enAU}>
      <Box>
        <Dialog
          open={open}
          onClose={() => handleClose(null)}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle sx={{background:'linear-gradient(to left, darkred, navy)', color:'white'}}>
            {initialData.id ? 'Modify Location' : 'Add Location'}
          </DialogTitle>
          <DialogContent>
            <Box sx={{ mx: 'auto', p: 2 }}>
              {error && <Alert severity="error">{error}</Alert>}
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Location Name"
                    name="location_name"
                    margin="normal"
                    value={data.location_name}
                    onChange={handleChange}
                    error={!!errors.location_name}
                    helperText={errors.location_name}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Location Description"
                    name="location_description"
                    margin="normal"
                    value={data.location_description}
                    onChange={handleChange}
                    error={!!errors.location_description}
                    helperText={errors.location_description}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth margin="normal" error={!!errors.location_type} required>
                    <InputLabel id="location-type-label">Location Type</InputLabel>
                    <Select
                      labelId="location-type-label"
                      name="location_type"
                      value={data.location_type}
                      onChange={handleChange}
                      label="Location Type"
                    >
                      <MenuItem value="Private home">Private home</MenuItem>
                      <MenuItem value="Group home">Group home</MenuItem>
                      <MenuItem value="Supported accommodation">Supported accommodation</MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
                    </Select>
                    {errors.location_type && <FormHelperText>{errors.location_type}</FormHelperText>}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Contact Person"
                    name="contact_person"
                    margin="normal"
                    value={data.contact_person}
                    onChange={handleChange}
                    error={!!errors.contact_person}
                    helperText={errors.contact_person}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel id="residence-status-label">Residence Status</InputLabel>
                    <Select
                      labelId="residence-status-label"
                      name="residence_status"
                      value={data.residence_status}
                      onChange={handleChange}
                      label="Residence Status"
                    >
                      <MenuItem value="Permanent">Permanent</MenuItem>
                      <MenuItem value="Temporary">Temporary</MenuItem>
                      <MenuItem value="Rented">Rented</MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel id="facility-details-label">Facility Details</InputLabel>
                    <Select
                      labelId="facility-details-label"
                      name="facility_details"
                      value={data.facility_details}
                      onChange={handleChange}
                      label="Facility Details"
                    >
                      <MenuItem value="Wheelchair ramps">Wheelchair ramps</MenuItem>
                      <MenuItem value="Elevators">Elevators</MenuItem>
                      <MenuItem value="Parking">Parking</MenuItem>
                      <MenuItem value="Sensory friendly">Sensory-friendly</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label="Active From"
                    value={data.active_from ? new Date(data.active_from * 1000) : null}
                    onChange={(newValue) => handleDateChange('active_from', newValue)}
                    renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label="Active To"
                    value={data.active_to ? new Date(data.active_to * 1000) : null}
                    onChange={(newValue) => handleDateChange('active_to', newValue)}
                    renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    multiline
                    label="Full Address"
                    name="full_address"
                    margin="normal"
                    value={data.full_address}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    multiline
                    label="Notes"
                    name="notes"
                    margin="normal"
                    value={data.notes}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Box sx={{mt:1, textAlign:'center'}} > 
                    Address and contacts:
                    <Button sx={{ml:2}} variant="contained" color="primary" onClick={handleOpenDialog}>
                      Add
                    </Button>
                  </Box>
                  <Box sx={{alignContent:'center'}}>
                    {data.contact_detail && 
                    <ContactDeatilsView data={data.contact_detail} />
                    }
                  </Box>
                </Grid>
                <Divider sx={{mb:2,mt:2}}/>
                <Grid item xs={12} sm={6}>
                  <Button
                    onClick={handleAddLocationSubmit}
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={loading}
                    sx={{ mt: 2 }}
                  >
                    {initialData.id ? 'Update' : 'Save'}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2}}
                    fullWidth
                    onClick={() => handleClose(null)}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <ContactDetailsDialog
              open={dialogOpen}
              onClose={handleCloseDialog}
              onSubmit={handleSubmitContactDetails}
              initialData={data.contact_detail || {}}
            />
          </DialogContent>
        </Dialog>
      </Box>
    </LocalizationProvider>
  );
};

export default AddLocation;