import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { TextField, Button, Grid, Paper, Dialog,
  MenuItem, DialogTitle, DialogContent, InputLabel, 
  DialogActions, Select ,FormControl } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import enAU from 'date-fns/locale/en-AU';

const HandoverForm = ({ open, handleClose, mode, noteData, handleUpdate, handleAdd }) => {
  const loggedInUser = useSelector((state) => state.auth.user);
  const staffs = useSelector((state) => state.user.data);

  const [listData, setListData] = useState({
    to_staffsList:  staffs ? staffs.filter(n => n.id !==loggedInUser.id) :[]
  })

  const [formData, setFormData] = useState({
    date: new Date(),
    time: new Date(),
    from_staff: loggedInUser && loggedInUser.first_name+' '+loggedInUser.last_name,
    to_staff: '',
    shift_summary: '',
    client_updates: '',
    tasks_completed: '',
    pending_tasks: '',
    issues_concerns: '',
    additional_notes: '',
  });

  useEffect( () => {
    if (staffs) {
      setListData({...listData, to_staffsList: staffs.filter(n => n.id !==loggedInUser.id)});
    }
   
  }, [staffs]);

  const getNameFromStaffId = (id) => {
    const selectedStaff = staffs && staffs.find(staff => staff.id == id );
    return selectedStaff && selectedStaff.first_name+' '+ selectedStaff.last_name;
  }

  useEffect(() => {
    if (mode && mode !== 'create' && noteData) {
      setFormData({
        ...noteData,
        to_staff: getNameFromStaffId(noteData.to_staff),
        from_staff: getNameFromStaffId(noteData.from_staff),
        date: noteData.date ? new Date(noteData.date) : new Date(),
        time: noteData.time ? new Date(`1970-01-01T${noteData.time}`) : new Date(),
      });
    } else {
      setFormData(prev => ({
        ...prev,
        date: new Date(),
        time: new Date(),
      }));
    }
  }, [mode, noteData]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleDateChange = (newDate) => {
    setFormData({ ...formData, date: newDate });
  };

  const handleTimeChange = (newTime) => {
    setFormData({ ...formData, time: newTime });
  };

  const getIdFromStaffName = (name) => {
    const selectedStaff = staffs && staffs.find(staff => staff.first_name+' '+staff.last_name === name);
    return selectedStaff && selectedStaff.id;

  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const submissionData = {
      ...formData,
      from_staff: loggedInUser.id,
      to_staff: getIdFromStaffName(formData.to_staff),
      date: formData.date.toISOString().split('T')[0],
      time: formData.time.toTimeString().split(' ')[0].slice(0, 5),
    };
    if (mode && mode === 'create') {
      handleAdd(submissionData);
    } else if (mode && mode === 'edit') {
      handleUpdate(submissionData);
    }
    handleClose();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enAU}>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle sx={{background:'linear-gradient(to left, darkred, navy)', color:'white'}}>
          {mode && mode === 'create' ? 'Create Handover Note' : mode && mode === 'edit' ? 'Update Handover Note' : 'View Handover Note' }
        </DialogTitle>
        <DialogContent>
          <Paper elevation={3} style={{ padding: '10px', margin: '10px auto' }}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label="Date"
                    value={formData.date}
                    onChange={handleDateChange}
                    renderInput={(params) => <TextField {...params} fullWidth required />}
                    disabled={mode === 'view'}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TimePicker
                    label="Time"
                    value={formData.time}
                    onChange={handleTimeChange}
                    renderInput={(params) => <TextField {...params} fullWidth required />}
                    disabled={mode === 'view'}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    name="from_staff"
                    label="Handover From"
                    value={formData.from_staff}
                    onChange={handleChange}
                    
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  {
                    mode && mode === 'view' ?
                    <TextField
                      fullWidth
                      name="to_staff"
                      label="Handover To"
                      value={formData.to_staff}
                      required
                      disabled
                    />
                    :
                    <FormControl fullWidth>
                      <InputLabel id="to_staff_label">Handover To</InputLabel>
                      <Select
                        fullWidth
                        labelId='to_staff_label'
                        id="to_staffId"
                        name="to_staff"
                        label="Handover To"
                        value={formData.to_staff}
                        onChange={handleChange}
                        required
                      >
                        {listData.to_staffsList && listData.to_staffsList.map((staff) => (
                          <MenuItem key={staff.id} value={staff.first_name+' '+ staff.last_name}
                          >
                            {staff.first_name+' '+ staff.last_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  }
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="shift_summary"
                    label="Shift Summary"
                    multiline
                    rows={4}
                    value={formData.shift_summary}
                    onChange={handleChange}
                    required
                    disabled={mode === 'view'}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="client_updates"
                    label="Client Updates"
                    multiline
                    rows={4}
                    value={formData.client_updates}
                    onChange={handleChange}
                    required
                    disabled={mode === 'view'}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="tasks_completed"
                    label="Tasks Completed"
                    multiline
                    rows={4}
                    value={formData.tasks_completed}
                    onChange={handleChange}
                    required
                    disabled={mode === 'view'}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="pending_tasks"
                    label="Pending Tasks"
                    multiline
                    rows={4}
                    value={formData.pending_tasks}
                    onChange={handleChange}
                    required
                    disabled={mode === 'view'}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="issues_concerns"
                    label="Issues and Concerns"
                    multiline
                    rows={4}
                    value={formData.issues_concerns}
                    onChange={handleChange}
                    required
                    disabled={mode === 'view'}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="additional_notes"
                    label="Additional Notes"
                    multiline
                    rows={4}
                    value={formData.additional_notes}
                    onChange={handleChange}
                    required
                    disabled={mode === 'view'}
                  />
                </Grid>
              </Grid>
            </form>
          </Paper>
        </DialogContent>
        
          <DialogActions>
            <Button onClick={handleClose} color="secondary">Cancel</Button>
            {mode !== 'view' && (
              <Button onClick={handleSubmit} color="primary">{mode === 'create' ? 'Submit' : 'Update'}</Button>
            )}
          </DialogActions>

      </Dialog>
    </LocalizationProvider>
  );
};

export default HandoverForm;