import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import ContactDetailsForm from './ContactDetailsForm';

const ContactDetailsDialog = ({
  open,
  onClose,
  onSubmit,
  initialData = {},
}) => {
  const handleSubmit = (data) => {
    onSubmit(data);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle sx={{background:'linear-gradient(to left, darkred, navy)', color:'white'}}>Contact Details</DialogTitle>
      <DialogContent>
        <ContactDetailsForm
          onSubmit={handleSubmit}
          initialData={initialData}
          onClose={onClose}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ContactDetailsDialog;