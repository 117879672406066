// src/utils/api.js
import { logoutRequest, updateLastActivity } from '../redux/actions/authActions';
import store from '../redux/store';

export const fetchWithAuth = async (url, options = {}) => {
  const state = store.getState();
  const { token, lastActivity } = state.auth;
  const currentTime = Date.now();
  const inactiveTime = currentTime - lastActivity;

  // Check for inactivity
  if (lastActivity && inactiveTime > 15 * 60 * 1000) {
    console.log("inactiveTime=", inactiveTime, " currentTime-", currentTime, "lastActivity", lastActivity, " so clearing token.");
    store.dispatch(logoutRequest());
    console.log("Session expired due to inactivity of 15 minutes. logging out at.",new Date());
    return;
  }

  if (token) {
    options.headers = {
      ...options.headers,
      'Authorization': `Bearer ${token}`,
    };
  }

  try {
    store.dispatch(updateLastActivity());
    const response = await fetch(`${url}`, options);
    
    if (response.status === 401) {
      alert('Recieved Unauthorised Error, logging out at ', new Date());
      store.dispatch(logoutRequest());
      return;
    }
    
    return response;
  } catch (error) {
    console.error('API call error:', error);
    throw error;
  }
};