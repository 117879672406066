import { DataGrid, DataGridProps, GridCellModes, GridCellModesModel, GridCellParams } from "@mui/x-data-grid"
import React from "react"

export default function SingleClickEditDataGrid(props) {
  const [cellModesModel, setCellModesModel] = React.useState({})

  const handleCellClick = React.useCallback((params) => {
    if (params.isEditable) {
      setCellModesModel(prevModel => {
        return {
          // Revert the mode of the other cells from other rows
          ...Object.keys(prevModel).reduce(
            (acc, id) => ({
              ...acc,
              [id]: Object.keys(prevModel[id]).reduce(
                (acc2, field) => ({
                  ...acc2,
                  [field]: { mode: GridCellModes.View }
                }),
                {}
              )
            }),
            {}
          ),
          [params.id]: {
            // Revert the mode of other cells in the same row
            ...Object.keys(prevModel[params.id] || {}).reduce(
              (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
              {}
            ),
            [params.field]: { mode: GridCellModes.Edit }
          }
        }
      })
    }
  }, [])

  const handleCellModesModelChange = React.useCallback(newModel => {
    setCellModesModel(newModel)
  }, [])

  return (
    <DataGrid
      cellModesModel={cellModesModel}
      onCellModesModelChange={handleCellModesModelChange}
      onCellClick={handleCellClick}
      experimentalFeatures={{ newEditingApi: true }}
      {...props}
    />
  )
}