import {
  SUBMIT_HANDOVER_NOTE,
  SUBMIT_HANDOVER_NOTE_SUCCESS,
  SUBMIT_HANDOVER_NOTE_FAILURE,
  FETCH_HANDOVER_NOTE,
  FETCH_HANDOVER_NOTE_SUCCESS,
  FETCH_HANDOVER_NOTE_FAILURE,
  UPDATE_HANDOVER_NOTE,
  UPDATE_HANDOVER_NOTE_SUCCESS,
  UPDATE_HANDOVER_NOTE_FAILURE,
} from '../actions/handoverActions';;

const initialState = {
  loading: false,
  error: null,
  handoverNote: null,
  message:null,
};

const handoverReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBMIT_HANDOVER_NOTE:
    case FETCH_HANDOVER_NOTE:
    case UPDATE_HANDOVER_NOTE:
      return { ...state, loading: true, error: null, message:null };
    case SUBMIT_HANDOVER_NOTE_SUCCESS:
    case UPDATE_HANDOVER_NOTE_SUCCESS:
      return { ...state, loading: false, handoverNote: action.payload, error: null, message:action.message };
    case FETCH_HANDOVER_NOTE_SUCCESS:
      return { ...state, loading: false, handoverNote: action.payload, error: null, message:null };
    case SUBMIT_HANDOVER_NOTE_FAILURE:
    case FETCH_HANDOVER_NOTE_FAILURE:
    case UPDATE_HANDOVER_NOTE_FAILURE:
      return { ...state, loading: false, error: action.payload, message:null };
    case 'ADD_NEW_TIMESHEET_SUCCESS':
    case 'MODIFY_TIMESHEET_SUCCESS':
      return { ...state, loading: false, error: null, handoverNote:null, message:null };
    default:
      return state;
  }
};

export default handoverReducer;
