// sagas/timesheetSaga.js
import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchWithAuth } from '../../utils/fetchApi';
import {
  SEARCH_PROCESSED_TIMESHEETS_REQUEST,
  searchProcessedTimesheetsFailure,
  searchProcessedTimesheetsSuccess,
  SAVE_HOURS_ADJUSTED_REQUEST,
  SAVE_CATEGORY_CHANGE_REQUEST,
  saveHoursAdjustedSuccess,
  saveHoursAdjustedFailure,
  saveCategoryChangeSuccess,
  saveCategoryChangeFailure,

} from '../actions/timesheetActions';

function* searchProcessedTimesheets(action) {
  try {
    const response = yield call(fetchWithAuth, '/api/shifts/search', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify(action.payload),
    });
    const data = yield response.json();
    if (response.ok) {
      yield put(searchProcessedTimesheetsSuccess(data));
    } else {
      yield put(searchProcessedTimesheetsFailure('Failed to fetch processed timesheets'));
    }
  } catch (error) {
    yield put(searchProcessedTimesheetsFailure(error.message));
  }
}

function* saveHoursAdjusted(action) {
  try {
    const response = yield call(fetchWithAuth, '/api/shifts/adjust', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify(action.payload),
    });
    const data = yield response.json();
    if (response.ok) {
      yield put(saveHoursAdjustedSuccess(data));
    } else {
      yield put(saveHoursAdjustedFailure('Failed to fetch processed timesheets'));
    }
  } catch (error) {
    yield put(saveHoursAdjustedFailure(error.message));
  }
}

function* saveCategoryChange(action) {
  try {
    const response = yield call(fetchWithAuth, '/api/shifts/sleepover', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify(action.payload),
    });
    const data = yield response.json();
    if (response.ok) {
      yield put(saveCategoryChangeSuccess(data));
    } else {
      yield put(saveCategoryChangeFailure('Failed to fetch processed timesheets'));
    }
  } catch (error) {
    yield put(saveCategoryChangeFailure(error.message));
  }
}


export default function* watchProcessedTimesheet() {
  yield takeLatest(SEARCH_PROCESSED_TIMESHEETS_REQUEST, searchProcessedTimesheets);
  yield takeLatest(SAVE_HOURS_ADJUSTED_REQUEST, saveHoursAdjusted);
  yield takeLatest(SAVE_CATEGORY_CHANGE_REQUEST, saveCategoryChange);
}
