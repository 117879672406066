import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Box, Button, Grid, TextField } from '@mui/material';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import ShiftTimeForm from './ShiftTimeForm';
import ValidationForm from './ValidationForm';
import enAU from 'date-fns/locale/en-AU';
import { useSharedData } from '../../../../utils/SharedDataContext';

const RuleForm = ({ onClose, onSubmit, initialData }) => {
  const [formData, setFormData] = useState({
    id: initialData.id || null,
    rule_name: initialData.rule_name || '',
    category: initialData.category || '',
    staff_id: initialData.staff_id || '',
    rule_object: initialData.rule_object || {}
  });
  const {staffNameIds} = useSharedData();
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setFormData(initialData);
  }, [initialData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: '' });
  };

  const handleFormDataChange = (data) => {
    setFormData({ ...formData, rule_object: data });
  };

  const validateForm = () => {
    let tempErrors = {};
    if (!formData.category) tempErrors.category = 'Category is required';
    if (!formData.rule_name) tempErrors.rule_name = 'Name is required';

    if (formData.category === 'Shift Time') {
      const { start_time, end_time, rate_type, other_rate_type } = formData.rule_object;
      if (!start_time) tempErrors.start_time = 'Start time is required';
      if (!end_time) tempErrors.end_time = 'End time is required';
      // if (!rate_type) tempErrors.rateType = 'Rate type is required';
      // if (rate_type === 'Other' && !other_rate_type) tempErrors.other_rate_type = 'Other rate type is required';
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      if (formData.category === 'Shift Time') {
        onSubmit({
          id: formData.id,
          rule_name: formData.rule_name,
          category: formData.category,
          staff_id: formData.staff_id,
          ...formData.rule_object,
          start_time: formData.rule_object.start_time ? formData.rule_object.start_time.getHours() + formData.rule_object.start_time.getMinutes() / 60 : null,
          end_time: formData.rule_object.end_time ? formData.rule_object.end_time.getHours() + formData.rule_object.end_time.getMinutes() / 60 : null
        });
      } else {
        onSubmit({
          ...formData,
          rule_object: {
            ...formData.rule_object,
          }
        });
      }
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enAU}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal" error={!!errors.category}>
              <InputLabel id="category-label">Category</InputLabel>
              <Select
                labelId="category-label"
                name="category"
                value={formData.category}
                onChange={handleChange}
                label="Category"
              >
                <MenuItem value="Shift Time">Shift Time</MenuItem>
                <MenuItem value="Validation">Validation</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Staff Name</InputLabel>
              <Select
                name="staff_id"
                value={formData.staff_id}
                onChange={handleChange}
                label="Staff Name"
              >
                {staffNameIds.map((staff) => (
                  <MenuItem key={staff.id} value={staff.id}>{staff.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal" error={!!errors.rule_name} required>
              <TextField
                label="Rule Name"
                name="rule_name"
                value={formData.rule_name}
                onChange={handleChange}
                fullWidth
                required
              />
            </FormControl>
          </Grid>
        </Grid>
        {formData.category === 'Shift Time' && <ShiftTimeForm onChange={handleFormDataChange} initialData={formData.rule_object} errors={errors} />}
        {formData.category === 'Validation' && <ValidationForm onChange={handleFormDataChange} initialData={formData.rule_object} />}
        <Box mt={2} sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <Button sx={{ ml: 2 }} onClick={onClose} variant="contained" color="secondary">
            Close
          </Button>
          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
        </Box>
      </form>
    </LocalizationProvider>
  );
};

export default RuleForm;