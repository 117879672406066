import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Grid, Alert, Dialog, DialogContent, DialogTitle, Divider, Select, MenuItem, FormControl, InputLabel, FormHelperText } from '@mui/material';
import { useSelector } from 'react-redux';
import ContactDetailsDialog from '../contactDetail/ContactDetailsDialog';
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ContactDeatilsView from '../contactDetail/ContactDetailView';
import enAU from 'date-fns/locale/en-AU';

const AddParticipant = ({ handleClose, open, initialData }) => {
  const [data, setData] = useState({ ...initialData });
  const [errors, setErrors] = useState({});
  const { loading, error, message } = useSelector((state) => state.user);
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    setData({ ...initialData });
    setErrors({});
  }, [initialData]);

  const validateField = (name, value) => {
    let error = '';
    if (!value && name !== 'id') {
      error = 'This field is required';
    }
    return error;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
    setErrors({ ...errors, [name]: validateField(name, value) });
  };

  const handleDateChange = (newValue) => {
    const dob = newValue ? ((newValue.valueOf() / 1000) | 0) : null;
    setData({ ...data, dob });
    setErrors({ ...errors, dob: validateField('dob', dob) });
  };

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleSubmitContactDetails = (contactData) => {
    setData({ ...data, contact_detail: contactData });
    setErrors({ ...errors, contact_detail: validateField('contact_detail', contactData) });
    console.log('Contact details submitted:', contactData);
  };

  const handleAddParticipantSubmit = () => {
    const newErrors = {};
    Object.keys(data).forEach(key => {
      const error = validateField(key, data[key]);
      if (error) newErrors[key] = error;
    });
    if (Object.keys(newErrors).length === 0) {
      handleClose(data);
    } else {
      setErrors(newErrors);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enAU}>
      <Box>
        <Dialog
          open={open}
          onClose={() => handleClose(null)}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle sx={{ background: 'linear-gradient(to left, darkred, navy)', color: 'white' }}>
            {initialData.id ? 'Modify Participant' : 'Add Participant'}
          </DialogTitle>
          <DialogContent>
            <Box sx={{ mx: 'auto', p: 2 }}>
              {error && <Alert severity="error">{error}</Alert>}
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="First Name"
                    name="first_name"
                    margin="normal"
                    value={data.first_name}
                    onChange={handleChange}
                    error={!!errors.first_name}
                    helperText={errors.first_name}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Last Name"
                    name="last_name"
                    margin="normal"
                    value={data.last_name}
                    onChange={handleChange}
                    error={!!errors.last_name}
                    helperText={errors.last_name}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Initials"
                    name="initials"
                    margin="normal"
                    value={data.initials}
                    onChange={handleChange}
                    error={!!errors.initials}
                    helperText={errors.initials}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="NDIS Participant Number"
                    name="participant_number"
                    type="text"
                    margin="normal"
                    value={data.participant_number}
                    onChange={handleChange}
                    error={!!errors.participant_number}
                    helperText={errors.participant_number}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                <FormControl fullWidth error={!!errors.dob} required>
                  <DatePicker
                    label="Date of Birth"
                    value={data.dob ? new Date(data.dob * 1000) : null}
                    onChange={handleDateChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        margin="normal"
                      error={!!errors.dob}
                      helperText={errors.dob}
                      required
                      />
                    )}
                  />
                  {errors.dob && <FormHelperText>{errors.dob}</FormHelperText>}
                </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth error={!!errors.gender} required>
                    <InputLabel id="gender-label">Gender</InputLabel>
                    <Select
                      labelId="gender-label"
                      name="gender"
                      value={data.gender}
                      onChange={handleChange}
                      label="Gender"
                    >
                      <MenuItem value="Male">Male</MenuItem>
                      <MenuItem value="Female">Female</MenuItem>
                      <MenuItem value="Not-Disclosed">Not-Disclosed</MenuItem>
                    </Select>
                    {errors.gender && <FormHelperText>{errors.gender}</FormHelperText>}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Box sx={{ mt: 1, textAlign: 'center' }}>
                    Address and contacts:
                    <Button sx={{ ml: 2 }} variant="contained" color="primary" onClick={handleOpenDialog}>
                      Add
                    </Button>
                  </Box>
                  <Box sx={{ alignContent: 'center' }}>
                  {errors.contact_detail && <FormHelperText sx={{ textAlign: 'center' }} error>{errors.contact_detail}</FormHelperText>}
                    {data.contact_detail &&
                      <ContactDeatilsView data={data.contact_detail} />
                    }
                  </Box>
                  
                </Grid>
                <Divider sx={{ mb: 2, mt: 2 }} />
                <Grid item xs={12} sm={6}>
                  <Button
                    onClick={handleAddParticipantSubmit}
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={loading}
                    sx={{ mt: 2 }}
                  >
                    {initialData.id ? 'Update' : 'Save'}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2 }}
                    fullWidth
                    onClick={() => handleClose(null)}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <ContactDetailsDialog
              open={dialogOpen}
              onClose={handleCloseDialog}
              onSubmit={handleSubmitContactDetails}
              initialData={data.contact_detail || {}}
            />
          </DialogContent>
        </Dialog>
      </Box>
    </LocalizationProvider>
  );
};

export default AddParticipant;