// actions/addUserActions.js
export const ADDUSER_RESET   = 'ADDUSER_RESET';
export const ADDUSER_REQUEST = 'ADDUSER_REQUEST';
export const ADDUSER_SUCCESS = 'ADDUSER_SUCCESS';
export const ADDUSER_FAILURE = 'ADDUSER_FAILURE';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';

export const addUserReset = () => ({
  type: ADDUSER_RESET,
});

export const addUserRequest = (data) => ({
  type: ADDUSER_REQUEST,
  payload: data,
});

export const addUserSuccess = (user) => ({
  type: ADDUSER_SUCCESS,
  payload: user,
});

export const addUserFailure = (error) => ({
  type: ADDUSER_FAILURE,
  payload: error,
});

export const getUsersRequest = (filter) => ({
  type: GET_USERS_REQUEST,
  payload: filter
});

export const getUsersSuccess = (data) => ({
  type: GET_USERS_SUCCESS,
  payload: data,
});

export const getUsersFailure = (error) => ({
  type: GET_USERS_FAILURE,
  payload: error,
});

export const deleteUserRequest = (data) => ({
  type: DELETE_USER_REQUEST,
  payload: data,
});

export const deleteUserSuccess = (user, id) => ({
  type: DELETE_USER_SUCCESS,
  payload: user,
  id
});

export const deleteUserFailure = (error) => ({
  type: DELETE_USER_FAILURE,
  payload: error,
});

export const modifyUserRequest = (data) => ({ type: 'MODIFY_USER_REQUEST', payload: data });
export const modifyUserSuccess = (data) => ({ type: 'MODIFY_USER_SUCCESS', payload: data });
export const modifyUserFailure = (error) => ({ type: 'MODIFY_USER_FAILURE', payload: error });