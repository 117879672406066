import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchWithAuth } from  '../../utils/fetchApi';
import { locationActions } from '../actions/locationActions';

function* addLocationSaga(action) {
  try {
    const response = yield call(fetchWithAuth, '/api/locations/add', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(action.payload),
    });
    const data = yield response.json();
    if (!response.ok) {
      throw new Error(data.error || 'Failed to add new Location');
    }

    yield put(locationActions.addLocationSuccess(data));
  } catch (error) {
    yield put(locationActions.addLocationFailure(error.message));
  }
}

function* modifyLocationSaga(action) {
  try {
    const response = yield call(fetchWithAuth, `/api/locations/${action.payload.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(action.payload),
    });
    const data = yield response.json();
    if (!response.ok) {
      throw new Error(data.error || 'Failed to modify Location');
    }

    yield put(locationActions.modifyLocationSuccess(data));
  } catch (error) {
    yield put(locationActions.modifyLocationFailure(error.message));
  }
}

function* deleteLocationSaga(action) {
  try {
    const response = yield call(fetchWithAuth, `/api/locations/${action.payload}`, {
      method: 'DELETE',
    });
    const data = yield response.json();
    if (!response.ok) {
      throw new Error(data.error || 'Failed to delete new Location');
    }

    yield put(locationActions.deleteLocationSuccess(data.msg, action.payload));
  } catch (error) {
    yield put(locationActions.deleteLocationFailure(error.message));
  }
}

function* getLocationsSaga(action) {
  try {
    let url = '/api/locations/list';
    if (action.payload) {
      const params = new URLSearchParams();
      const { type, name } = action.payload;
      if (type) {
        params.append('type', type);
      }
      if (name) {
        params.append('name', name);
      }
      if (params.toString()) {
        url += `?${params.toString()}`;
      }
    }
    const response = yield call(fetchWithAuth, url, {
      method: 'GET'
    });

    if (!response.ok) {
      throw new Error('Failed to get list of locations');
    }
    const data = yield response.json();
    yield put(locationActions.getLocationsSuccess(data));
  } catch (error) {
    yield put(locationActions.getLocationsFailure(error.message));
  }
}

export default function* locationSaga() {
  yield takeLatest('ADD_LOCATION_REQUEST', addLocationSaga);
  yield takeLatest('MODIFY_LOCATION_REQUEST', modifyLocationSaga);
  yield takeLatest('GET_LOCATIONS_REQUEST', getLocationsSaga);
  yield takeLatest('DELETE_LOCATION_REQUEST', deleteLocationSaga);
}