// reportReducer.js
import {
    FETCH_REPORTS_SUCCESS,
    FETCH_REPORT_SUCCESS,
    FETCH_REPORT_FAILURE,
    EMAIL_REPORT_FAILURE,
    EMAIL_REPORT_SUCCESS,
    EMAIL_REPORT_REQUEST
  } from '../actions/reportActions';
  
  const initialState = {
    reports: [],
    selectedReport: null,
    reportData: [],
    message: null,
    error: null
  };
  
  const reportReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_REPORTS_SUCCESS:
        return { ...state, reports: action.reports, message: null };
      case FETCH_REPORT_SUCCESS:
        return { ...state, reportData: action.reportData, message: null };
      case FETCH_REPORT_FAILURE:
          return { ...state, error: action.message, message: null };
      case EMAIL_REPORT_REQUEST:
        return { ...state, message: null, error: null };
      case EMAIL_REPORT_SUCCESS:
        return { ...state, message: action.payload, error: null  };
      case EMAIL_REPORT_FAILURE:
          return { ...state, error: action.message, message: null };
      default:
        return state;
    }
  };
  
  export default reportReducer;