import React, { useState, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppBar, Toolbar, Typography, Button, Box, IconButton, Menu, MenuItem, Avatar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import PropTypes from 'prop-types';
import DropdownMenu from './DropdownMenu';
import { logoutRequest } from '../../redux/actions/authActions';

const MenuButton = ({ to, children }) => {
  const location = useLocation();
  const isActive = useMemo(() => location.pathname === to, [location, to]);

  return (
    <Button 
      color="inherit" 
      component={Link} 
      to={to}
      aria-label={`Navigate to ${children}`}
      sx={{ 
        bgcolor: isActive ? 'primary.dark' : 'transparent',
        '&:hover': {
          bgcolor: isActive ? 'primary.dark' : 'primary.light',
        }
      }}
    >
      {children}
    </Button>
  );
};

MenuButton.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const { first_name, role } = useSelector((state) => state.auth?.user);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    dispatch(logoutRequest());

  }

  const isActive = useMemo(() => (path) => location.pathname === path, [location]);

  return (
    <AppBar position="static" sx={{background:'linear-gradient(to left, darkred, navy)'}}>
      <Toolbar>
        <Box component="img" src="logo.png" alt="Eazify Logo" sx={{ width: {xs: '3.5em'}, mr: 2, backgroundColor: 'white', borderRadius: '8px'}} />
        <Typography variant="h4" component="div" sx={{ flexGrow: 1, fontWeight: 'bold', ml: 4 }}>
          Eazify <Box sx={{fontSize:10}}>Just for Luke</Box>
        </Typography>
        
        <Typography variant="h5" component="div" sx={{ flexGrow: 1, fontWeight: 'bold', ml: 4 }}>
          Welcome {first_name || 'User'} <Box sx={{fontSize:10, }}>Logged in as {role}</Box>
        </Typography> 
        
        <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: 1 }}>
          {role === 'Manager' && <MenuButton to="/admin">Admin</MenuButton>}
          <MenuButton to="/timesheets">Timesheets</MenuButton>
          {role === 'Practitioner'&&  <MenuButton to="/add-timesheets">Add Timesheets</MenuButton>}
          {role === 'Manager' && <MenuButton to="/processed-timesheets">Processed Timesheets</MenuButton>}
          <MenuButton to="/reports">Reports</MenuButton>
          <DropdownMenu>
              <Avatar />
            </DropdownMenu>
        </Box>
        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
          <IconButton 
            edge="start" 
            color="inherit" 
            aria-label="menu" 
            onClick={handleMenuOpen}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            {role === 'Manager' && (
              <MenuItem 
                onClick={handleMenuClose} 
                component={Link} 
                to="/admin"
                selected={isActive('/admin')}
              >
                Admin
              </MenuItem>
            )}
            <MenuItem 
              onClick={handleMenuClose} 
              component={Link} 
              to="/timesheets"
              selected={isActive('/timesheets')}
            >
              Timesheets
            </MenuItem>
            {role === 'Manager' && (
              <MenuItem 
                onClick={handleMenuClose} 
                component={Link} 
                to="/processed-timesheets"
                selected={isActive('/processed-timesheets')}
              >
                Processed Timesheets
              </MenuItem>
            )}
            <MenuItem 
              onClick={handleMenuClose} 
              component={Link} 
              to="/reports"
              selected={isActive('/reports')}
            >
              Reports
            </MenuItem>
            <MenuItem 
              onClick={handleMenuClose} 
              component={Link} 
              to="/profile"
              selected={isActive('/profile')}
            >
              Profile
            </MenuItem>
            <MenuItem
              onClick={handleLogout}
              component={Link}
            >
              Logout
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default React.memo(Header);