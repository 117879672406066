import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Paper
} from '@mui/material';
import MultiStaffChat from './MultiStaffChat';

const MultiStaffChatDialog = ({open, setOpen}) => {

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="multi-staff-chat-dialog-title"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle 
          id="multi-staff-chat-dialog-title"
          sx={{background:'linear-gradient(to left, darkred, navy)', color:'white'}}
        >Multi-Staff Chat</DialogTitle>
        <DialogContent>
          <Paper elevation={3} sx={{mt:2}}>
            <MultiStaffChat />
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MultiStaffChatDialog;