import React, { useState, useEffect } from 'react';
import { Button, Typography, Box } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

const FortnightDateSelector = ({ onDateChange, initialStartDate }) => {
  const [startDate, setStartDate] = useState(() => {
    if (initialStartDate) {
      return getStartOfWeek(new Date(initialStartDate));
    }
    const today = new Date();
    const lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
    return getStartOfWeek(lastWeek);
  });

  useEffect(() => {
    const endDate = addDays(startDate, 13);
    onDateChange({ startDate, endDate });
  }, [startDate]);

  function getStartOfWeek(date) {
    const d = new Date(date);
    const day = d.getDay();
    const diff = d.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(d.setDate(diff));
  }

  function formatDate(date) {
    return date.toLocaleDateString('en-GB', { weekday: 'short', day: '2-digit', month: 'short' });
  }

  function addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  function moveFortnightBy(weeks) {
    setStartDate(prevDate => addDays(prevDate, weeks * 7));
  }

  const endDate = addDays(startDate, 13);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor:"whitesmoke" , mr:2, borderRadius:'6px'}}>
      <Button onClick={() => moveFortnightBy(-2)} sx={{ minWidth: 'auto'}}>
        <ChevronLeft />
      </Button>
      <Typography variant="body2" sx={{ mx: 1, minWidth: 170, textAlign: 'center' }}>
        {`${formatDate(startDate)} - ${formatDate(endDate)}`}
      </Typography>
      <Button onClick={() => moveFortnightBy(2)} sx={{ minWidth: 'auto' }}>
        <ChevronRight />
      </Button>
    </Box>
  );
};

export default FortnightDateSelector;