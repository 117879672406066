import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { resetPasswordRequest } from '../../redux/actions/authActions';
import { Grid, Typography, Button, Box, TextField, Container, Card, CardContent, AppBar, Toolbar } from '@mui/material';

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [clientError, setClientError] = useState('');
  const { token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, message, error } = useSelector((state) => state.auth);

  const handleResetPassword = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
        console.log(password);
        console.log('confirmPassword=',confirmPassword);
        setClientError("Passwords don't match");
      return;
    }
    setClientError('');
    dispatch(resetPasswordRequest({ token, password }));
  };

  return (
    <Box sx={{ 
      minHeight: '100vh', 
      background: 'linear-gradient(to left, darkred, navy)',
      display: 'flex', 
      flexDirection: 'column',
      padding: 0
    }}>
      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar sx={{ backgroundColor: 'black', justifyContent: 'space-between' }}>
          <img src="/logo.png" alt="Eazify Logo" style={{ width: 40, height: 40, backgroundColor: 'white', borderRadius: '8px' }} />
          <Typography variant="h6" component="div" sx={{ color: 'white' }}>
            Eazify
          </Typography>
        </Toolbar>
      </AppBar>
      
      <Container maxWidth="sm" sx={{ mt: 8 }}>
        <Card sx={{ boxShadow: 3, borderRadius: 2 }}>
          <CardContent sx={{ p: 4 }}>
            <Typography variant="h4" sx={{ mb: 3, textAlign: 'center', fontWeight: 'bold' }}>
              Reset Password
            </Typography>

            <form onSubmit={handleResetPassword}>
              <TextField 
                fullWidth
                label="New Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                sx={{ mb: 2 }}
              />
              <TextField 
                fullWidth
                label="Confirm New Password"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                sx={{ mb: 3 }}
              />
              {clientError && (
                <Typography variant="body2" sx={{ mb: 2, textAlign: 'center', color: 'error.main' }}>
                  {clientError}
                </Typography>
              )}
              {error && (
                <Typography variant="body2" sx={{ mb: 2, textAlign: 'center', color: 'error.main' }}>
                  {error}
                </Typography>
              )}

              <Button 
                variant="contained" 
                type="submit" 
                fullWidth
                disabled={loading}
                sx={{ mb: 2 }}
              >
                Reset Password
              </Button>
            </form>

            {message && (
              <Typography variant="body2" sx={{ mt: 2, textAlign: 'center', color: 'success.main' }}>
                {message}
              </Typography>
            )}

            <Button 
              variant="text" 
              onClick={() => navigate('/login')}
              fullWidth
            >
              Back to Login
            </Button>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
}

export default ResetPassword;