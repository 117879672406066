import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileUploadDownload from '../../document/FileUploadDownload';

export default function StaffDetailEntryAccordion({uploadedFiles, onUpload}) {
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const getUploadedDocs = (fileCategory) => uploadedFiles && uploadedFiles.length > 0 &&
    uploadedFiles.filter(file => file.filecategory === fileCategory);

  return (
    <div>
      <Typography sx={{mb:2, fontWeight:'bold'}}>
        Document Upload
      </Typography>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          sx={{backgroundColor:'lightgrey'}}
        >
          <Typography sx={{ width: '66%', flexShrink: 0 }}>
            Working With Children Check
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>Mandatory</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FileUploadDownload parentId={'panel1'} 
          onUpload={onUpload} 
          uploadedFiles={getUploadedDocs('Working With Children Check')} 
          fileCategory={'Working With Children Check'} />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
          sx={{backgroundColor:'whitesmoke'}}
        >
          <Typography sx={{ width: '66%', flexShrink: 0 }}>National Police Check</Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            Mandatory
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FileUploadDownload parentId={'panel2'} onUpload={onUpload} uploadedFiles={getUploadedDocs('National Police Check')} fileCategory={'National Police Check'}/>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
          sx={{backgroundColor:'lightgrey'}}
        >
          <Typography sx={{ width: '66%', flexShrink: 0 }}>
            Disability Worker Exclusion List Check
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            Mandatory
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FileUploadDownload parentId={'panel3'} onUpload={onUpload} uploadedFiles={getUploadedDocs('Disability Worker Exclusion List Check')} fileCategory={'Disability Worker Exclusion List Check'} />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
          sx={{backgroundColor:'whitesmoke'}}
        >
          <Typography sx={{ width: '66%', flexShrink: 0 }}>
            Other
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            Optional
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FileUploadDownload parentId={'panel4'} uploadedFiles={getUploadedDocs('Other')} fileCategory={'Other'} onUpload={onUpload}/>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
