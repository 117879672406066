import React,{ useState } from 'react';
import { Box, TextField } from '@mui/material';
import EditNoteIcon from '@mui/icons-material/EditNote';
import SingleClickEditDataGrid from "./SingleClickEditDataGrid";
import NotificationsPausedIcon from '@mui/icons-material/NotificationsPaused';


const DailyViewDataGrid = ({data, onHourAdjust, handleSleepoverRequest}) => {
  const [toggle, setToggle] = useState(false);
  const handleIconClick = (row) => {
    row.shift_category ="Sleepover";
    row.hours_adjusted = 0;
    row.hours_approved = 1;
    setToggle(true);
    handleSleepoverRequest(row);
  } 
  const columns = [
    {
      field: 'shift_date',
      renderHeader: () => (
        <strong>
          {'Shift Date'}
        </strong>
      ),
      flex: 1,
      editable: false,
      renderCell: ({ row }) => new Date(row.shift_date*1000).toLocaleDateString()
    },
    {
      field: 'shift_category',
      renderHeader: () => (
        <strong>
          {'Shift Category'}
        </strong>
      ),
      headerName: 'Shift Category',
      flex: 1,
      editable: false,
      renderCell: ({ row }) => {
        return (
          row.shift_category && row.shift_category.includes("Night") ?
            <Box sx={{display:'flex', justifyContent:'space-between'}} >
              <span >{row.shift_category} </span>
              <NotificationsPausedIcon sx={{mt:1.5, ...(toggle && {color:'red'})}} onClick={() => handleIconClick(row)}
              />
          </Box>
          :
          (row.shift_category === "Sleepover")?
          <Box sx={{color:'red'}} >{row.shift_category}</Box>
          :
          row.shift_category
        )
      },
    },
    {
      field: 'hours_allocated',
      renderHeader: () => (
        <strong>
          {'Hours Worked'}
        </strong>
      ),
      headerAlign: 'left',
      type: 'number',
      flex: .7,
      editable: false,
    },
    {
      field: 'hours_adjusted',
      renderHeader: () => (
        <>
          <strong >
            {'Hours Adjusted'}
          </strong>
          <EditNoteIcon/>
        </>

      ),
      headerAlign: 'left',
      flex: 1,
      editable: true,
      renderCell: ({ row }) => {
        return (
            <Box sx={{textAlign:'right'}} >
              <span>{row.hours_adjusted} </span>
              
          </Box>
        )
      },
      renderEditCell: (params) => {
        return (
          <TextField
            variant="outlined"
            size="small"
            value={params.value}
            type="number"
            onChange={(e) => {
              // Update the value in the cell
              const updatedValue = e.target.value;
              if(updatedValue && updatedValue !== params.value) {
                // Calling function to update the state and call api
                onHourAdjust( {id:params.id, hours_adjusted:updatedValue} )
              }
              params.api.setEditCellValue({
                id: params.id,
                field: params.field,
                value: updatedValue,
              });
            }}
            // onBlur={(e) => {
            //   // Update the value in the cell
            //   const updatedValue = e.target.value;
            //   if(updatedValue !== params.hours_adjusted) {
            //     // Calling function to update the state and call api
            //     onHourAdjust( {id:params.id, hours_adjusted:updatedValue} );
            //   }

            // }}
          />
        );
      },
    },
    {
      field: 'hours_approved',
      renderHeader: () => (
        <strong>
          {'Hours Approved'}
        </strong>
      ),
      headerAlign: 'left',
      type: 'number',
      flex: 1,
      renderCell: ({ row }) => {
        return (
          row.hours_approved >10?
          <Box sx={{color:'red'}}>
            {Math.round(row.hours_approved * 100) / 100}
          </Box>
          : Math.round(row.hours_approved * 100) / 100
        )
      },
    },
    {
      field: 'Status',
  
      renderHeader: () => (
        <strong>
          {'Status'}
        </strong>
      ),
      headerAlign: 'left',
      flex: 1,
    },
  
  ];

  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  // const handleEditCellChange = (id, value) => {
  //   console.log(id, "<= id, value =>",value);
  // }

  return (
    <Box p={2}>
      {/* <Typography variant="h6">Daily View</Typography> */}
      
      <Box display="flex" gap={1} mb={2}>
        
        Column marked with <EditNoteIcon/> is editable, click the cell to edit.
        
      </Box>
      <Box sx={{ height: 500, width: '100%' }}>
        <SingleClickEditDataGrid autoHeight getRowId={(row) => row.id}
        sx={{ "& .MuiDataGrid-withBorderColor": { backgroundColor: '#f7f7f7'}}}
          rows={data}
          columns={columns}
          //isRowSelectable={(params) => params.row.Status == 'Pending'}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 7,
              },
            },
          }}
          pageSizeOptions={[7]}
          // checkboxSelection
          disableRowSelectionOnClick
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
          // processRowUpdate={handleProcessRowUpdate}
          // onProcessRowUpdateError={handleProcessRowUpdateError}
        />
      </Box>
    </Box>
  );
};

export default DailyViewDataGrid;
